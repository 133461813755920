<template>
  <input 
    v-model="inputValue" 
    dense 
    outlined 
    type="text"
    class="mik_input"
    style="width: 95%;"
    @keypress.enter.prevent="search">
</template>

<script setup lang="ts">
// @ts-ignore

export interface Props {
  modelValue: string | number | null | undefined
  validation?: (() => string | null | Promise<string | null>) | null
  searchFunc?: (() => void) | null
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  validation: null,
  searchFunc: null
})

const inputValue: WritableComputedRef<string | number | null | undefined> = computed({
  get() {
    return props.modelValue
  },
  set(val: string | number | null | undefined) {
    emit('update:modelValue', val)
  }
})

const isValid: Ref<boolean | undefined> = ref(undefined)
const message: Ref<string | null> = ref(null)

const doValidation = async () => {
  if (props.validation && typeof props.modelValue === 'function') {
    message.value = await props.validation()
    isValid.value = !message.value
  }
}

const search = () => {
  if (props.searchFunc && typeof props.searchFunc === 'function') {
    props.searchFunc()
  }
}

watch(
  () => props.modelValue,
  () => {
    throttle(doValidation, 500)
  }
)

const emit = defineEmits<{ (e: 'update:modelValue', value: string | number | null | undefined): void }>()

const throttle = <T extends any[]>(callback: (...params: T) => void, time: number) => {
  const timer = ref<ReturnType<typeof setTimeout> | null>(null)

  return (...params: T) => {
    if (!timer.value) {
      timer.value = setTimeout(() => {
        callback(...params)
        timer.value = null
      }, time)
    }
  }
}
</script>
