<template>
  <li class="lnb_item" :class="computedItemClassObject" :data-menuid="item.id">
    <a v-if="showTitle" href="javascript:;" :class="`lnb_anchor depth${depth}`" @click.prevent="handleMenuClick">
      <span v-if="computedHasChild" class="mark_lower_menu">
        <span class="ico_lnb" :class="computedArrowClass"> </span>
      </span>
      <img v-if="item.hasMenuIcon" :src="item.menuIconUrl ?? ''" class="menu-icon" @error="handleImageError" />
      <span class="txt">{{ item.name }}</span>
    </a>
    <!-- 게시판 폴더 영역 -->
    <div :id="`${item.id}`"></div>
    <slot></slot>
  </li>
</template>

<script setup lang="ts">
import { LocalMenuModel } from 'mik-common/src/api-autogen/globalmenu'
import DefaultImage from '@gnb/../public/images/default-80x80.png'

interface Props {
  depth?: number
  item: LocalMenuModel
  expandedItems?: LocalMenuModel[]
  showTitle: boolean
}

const props = withDefaults(defineProps<Props>(), {
  depth: 0,
  showTitle: true,
  expandedItems: undefined
})

const isExpanded = ref(props.item?.isSelected)

const handleMenuClick = () => {
  //console.log(`handleMenuClick: ${computedHasChild.value} ${JSON.stringify(props.item)}`);
  if (computedHasChild.value) {
    handleMenuExpand()
  } else {
    emits('on-menu-click', props.item)
  }
}

const handleMenuExpand = () => {
  isExpanded.value = !isExpanded.value
  emits('on-menu-expand', props.item, isExpanded.value)
  //console.log(`handleMenuExpand: ${isExpanded.value} ${JSON.stringify(props.item)}`);
}

const handleImageError = (e: any) => {
  e.target.src = DefaultImage
}

const computedItemClassObject = computed((): any => {
  // if (props.item?.isSelected) {
  //   useLocalMenuStore().updateSelectedItem(props.item)
  // }
  return ['depth' + props.depth, { active: props.item?.isSelected }]
})

const computedHasChild = computed((): boolean => {
  return (props.item?.children?.length ?? 0) > 0
})

const computedArrowClass = computed(() => {
  // if (props.expandedItems.findIndex((value) => !value.isSelected || value === props.item) < 0) {
  //   isExpanded.value = false
  // }
  return isExpanded.value ? 'ico_lnb_up' : 'ico_lnb_down'
})

const emits = defineEmits<{
  (e: 'on-menu-click', value: LocalMenuModel): void
  (e: 'on-menu-expand', item: LocalMenuModel, isExpanded: boolean): void
}>()
</script>
