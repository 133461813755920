<template>
  <div v-if="isReady" id="lay_header" ref="layHeader" :class="rootClass">
    <!--
                nav-panel은 LNB/GNB의 하위 컴포넌트로 만들어야 합니다만,
                현재 HTML 구조상에선 하위 컴포넌트로 만들 수 없습니다.
            -->
    <nav-panel
      v-if="isGnbRender"
      :panel-type="navPanelType"
      :items="useGlobalMenu.menuData"
      :active-item-id="navPanelActiveId"
      :show="navPanelShow"
      @on-visible-change="(visible) => (navPanelVisibleState = visible)"
      @on-click-menu="handleMenuClick"
      @mouseleave="handleSiteMapMouseLeave" />
    <lnb
      v-if="isLnbRender"
      :items="useGlobalMenu.menuData"
      @on-item-mouse-enter="handleLnbItemMouseEnter"
      @on-item-mouse-leave="handleLnbItemMouseLeave"
      @on-click-menu="handleMenuClick">
    </lnb>
    <gnb
      v-if="isGnbRender"
      :name="userStore.getDisplayName"
      :company="userStore.userInfo.companyName ?? ''"
      :profile-image-url="userStore.userInfo.profileImageUrl ?? ''"
      :lang="lang"
      :items="useGlobalMenu.menuData"
      @on-logout-click="handleLogoutClick"
      @on-site-map-mouse-enter="handleSiteMapMouseEnter"
      @on-language-change="handleLanguageChange"
      @on-click-menu="handleMenuClick"
      @on-site-map-mouse-leave-notran="handleSiteMapMouseLeaveNoTran"
      @on-right-menu-click="handleSiteMapMouseLeave">
    </gnb>
  </div>
</template>
<script setup lang="ts">
// LIB
import { useI18n } from 'vue-i18n'
// import { Cookies } from 'quasar'
import VueCookies from 'vue-cookies'

// 컴포넌트
import Gnb from './Gnb.vue'
import Lnb from './Lnb.vue'
import NavPanel from './common/NavPanel.vue'

// API
import { FormsCookieApiApi } from 'mik-common/src/api-autogen/auth/api/forms-cookie-api-api'
import { UserApi } from 'mik-common/src/api-autogen/globalmenu/api/user-api'

// Model
import { MenuItemModel } from 'mik-common/src/api-autogen/globalmenu'

// Pinia
import { useGlobalMenuStore } from '@gnb/store/modules/global-menu/global-menu'
import { useUserInfoStore } from '@gnb/store/modules/user-info/user-info'

// Util
import useGwpGlobalMenu from '@gnb/utils/gnb'
import { installOptions } from './main'

export interface Props {
  isLnbRender: boolean
  isGnbRender: boolean
  useNavigationBar: boolean
  url: string
}
const props = withDefaults(defineProps<Props>(), {
  isLnbRender: true,
  isGnbRender: true,
  useNavigationBar: true,
  url: ''
})

const i18n = useI18n()
const lang = ref(i18n.locale.value)

const apiUser = new UserApi()
const apiAuth = new FormsCookieApiApi()

// Store
const useGlobalMenu = useGlobalMenuStore()
const userStore = useUserInfoStore()

const isReady = ref(false)

// get gwpGlobalMenu Instance
const { globalMenuClick } = useGwpGlobalMenu()

const rootClass = reactive<string[]>([])

//nav-panel 제어용 스테이트입니다.
const navPanelType = ref('02')
const navPanelActiveId = ref('')
const navPanelShow = ref(false)

//nav-panel이 위로 올라오게 하려면 z-index 조정이 필요합니다.
//z-index를 그냥 올리면 다른 화면이 아래로 내려가서 클릭등이 동작하지 않습니다.
//nav-panel이 보여질때만 z-index를 올립니다.
const navPanelVisibleState = ref(false)

// HtmlElement
const layHeader = ref()

// Provide
provide('layHeader', layHeader)

onBeforeMount(async () => {
  // GlobalMenu API를 호출하여 데이터를 가져옵니다.
  //if (props.url != undefined && props.url != '') {
  await useGlobalMenu.fetchItems(lang.value, true, props.url, 'menuSystem')
  // } else {
  //   await useGlobalMenu.fetchItems(lang.value, false, props.url, 'menuSystem')
  // }

  const isForce = false
  // UserInfo API를 호출하여 데이터를 가져옵니다
  await userStore.userInfoInit(isForce)

  // Gnb 를 그릴 때 lay_header 태그에 class 세팅
  if (props.isGnbRender) {
    reRenderHeader()
  }
  if (props.useNavigationBar) {
    rootClass.push('gnb_use_navigation')
  }

  isReady.value = true

  updateTitle(1)
})

const reRenderHeader = () => {
  let headerStyleIdClass = 'gnb_theme_type_medium_01'
  let widthStyleIdClass = 'gnb_theme_type_screen_1680'
  const userStoreCookie = userStore.getGnbStyle()

  if (userStoreCookie) {
    switch (userStoreCookie.headerStyleId) {
      case 'small_01':
        headerStyleIdClass = 'gnb_theme_type_small_01'
        break
      case 'medium_01':
        headerStyleIdClass = 'gnb_theme_type_medium_01'
        break
    }
    switch (userStoreCookie.widthStyleId) {
      case 'screen_1280':
        widthStyleIdClass = 'gnb_theme_type_screen_1280'
        break
      case 'screen_1680':
        widthStyleIdClass = 'gnb_theme_type_screen_1680'
        break
    }
  }

  rootClass.splice(0, rootClass.length) //초기화 하고
  rootClass.push('gwp_header', 'gwp_default', headerStyleIdClass, widthStyleIdClass) //insert
}

//LNB의 메뉴 아이콘이 포커싱 되었을 때 호출됩니다.
//nav-panel을 제어합니다.
const handleLnbItemMouseEnter = (id: string): void => {
  navPanelType.value = '02'
  navPanelActiveId.value = id
  navPanelShow.value = true
}

///LNB의 메뉴 아이콘이 포커싱을 잃었을 때 호출됩니다.
//nav-panel을 제어합니다.
const handleLnbItemMouseLeave = (id: string): void => {
  navPanelShow.value = false
}

//GNB의 SiteMap이 포커싱 되었을 때 호출됩니다.
//nav-panel을 제어합니다.
const handleSiteMapMouseEnter = (): void => {
  navPanelType.value = '01'
  navPanelActiveId.value = '*'
  navPanelShow.value = true
}
//GNB의 SiteMap이 포커싱을 잃었을 때 호출됩니다.
//nav-panel을 제어합니다.
const handleSiteMapMouseLeave = (): void => {
  navPanelShow.value = false
  //noTran.value = false
}
//const noTran = ref(false)
/* LNB의 fadeout을 하지 않고 창을 끈다. */
const handleSiteMapMouseLeaveNoTran = (): void => {
  //  noTran.value = true
  navPanelShow.value = false
}

const handleMenuClick = (url: string, menuId: string) => {
  navPanelShow.value = false
  globalMenuClick(url, menuId)
}

const handleLanguageChange = async (code: string): Promise<void> => {
  if (code !== lang.value) {
    // const gnbProfileResponse = await apiUser.v1UserProfileGet(code)
    // GlobalMenu API를 호출하여 데이터를 가져옵니다.
    const useGlobalMenu = useGlobalMenuStore()
    await useGlobalMenu.fetchItems(code, true, '', 'menuSystem')

    i18n.locale.value = code
    lang.value = code

    //cookie를 셋팅하고
    // @ts-ignore
    VueCookies.set('GWP_LANGUAGE_CODE', code, null, '/', import.meta.env.V_COOKIE_DOMAIN)

    //리다이렉션 한다.
    //userLangCode에 반영
    const response = await apiUser.v1UserSetLangCodeGet(code)
    if (response.data.success && installOptions.router) {
      //Language 변경시 새로 데이터를 가져옵니다.
      // GlobalMenu의 UserInfo에 보여줄 데이터를 가져옵니다.
      await userStore.userInfoInit(true)

      installOptions.router.go(0)
    }
  }
}

// GNB의 UserInfo에서 로그아웃이 요청되었을 때 호출됩니다.
const handleLogoutClick = async (): Promise<void> => {
  const logoutResponse = await apiAuth.apiV1FormsLogoutPost({ withCredentials: true, baseURL: installOptions.baseUrl })
  if (logoutResponse.status == 200 && logoutResponse.data.success) {
    // @ts-ignore
    VueCookies.remove('GWP_LANGUAGE_CODE', { path: '/' })
    userStore.logOut()

    if (installOptions.baseUrl) {
      //전자결재에서 호출시
      window.location.replace(installOptions.baseUrl + '/Auth')
    } else {
      window.location.replace('/Auth')
    }
  }
}

// Url이 변하는 것을 인지
// installOptions.router?.afterEach(() => {
//   updateTitle(1)
// })

watch(useGlobalMenu, () => {
  updateTitle(1)
})

const updateTitle = (retryCount: number) => {
  setTimeout(() => {
    const titleArea: HTMLElement | null = document.getElementById('SP_Page_Title')
    if (titleArea) {
      document.title = useGlobalMenu.locTitle
      titleArea.innerText = useGlobalMenu.locTitle
    } else {
      if (retryCount <= 500) {
        retryCount++
        updateTitle(retryCount)
      }
    }
  }, 10)
}

watch(userStore, () => {
  reRenderHeader()
})
</script>

<style>
/* Gnb css */
@import url('@gnb/../public/css/gnb.css');
</style>
