<template>
  <button class="gwp_btn_fav" :class="computedFavoriteClassObject" @click.prevent="handleButtonClick">
    <span class="ico_header ico_header_path_fav"></span>
  </button>
</template>

<script setup lang="ts">
interface Props {
  isActive?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isActive: false
})
const handleButtonClick = () => {
  emits('click')
}

const computedFavoriteClassObject = computed(() => {
  return props.isActive === true ? 'active' : ''
})

const emits = defineEmits<{ (e: 'click'): void }>()
</script>
