<template>
  <!-- 페이징 -->
  <div class="paginate">
    <div class="left_area">
      <!-- NOTE: 페이지네이션 왼쪽에 표시하고 싶은게 있을 때 사용 -->
      <span v-if="props.isShowTotalPageCount" class="paginate_txt">
        Page : <b>{{ currentPage }}</b
        >/{{ Math.ceil(props.totalItemCount / pageSize) }}
      </span>
      <slot name="left" />
    </div>
    <div class="paginate_list">
      <a class="paginate_btn paginate_btn_fst" @click="moveToFirstPage">
        <!-- <q-tooltip transition-show="flip-right" transition-hide="flip-left">{{ $t('MoveFirstPage') }}</q-tooltip> -->
      </a>
      <a class="paginate_btn paginate_btn_prev" @click="moveToPrevBlock">
        <!-- <q-tooltip transition-show="flip-right" transition-hide="flip-left">{{
          $t('MovePage', [GetPrevBlock()])
        }}</q-tooltip> -->
      </a>
      <a v-for="page in pageRange" :key="page" class="paginate_num" :class="pageClass(page)" @click="moveTo(page)">{{
        page
      }}</a>
      <a class="paginate_btn paginate_btn_next" @click="moveToNextBlock">
        <!-- <q-tooltip transition-show="flip-right" transition-hide="flip-left">{{
          $t('MovePage', [GetNextBlock()])
        }}</q-tooltip> -->
      </a>
      <a class="paginate_btn paginate_btn_lst" @click="moveToLastPage">
        <!-- <q-tooltip transition-show="flip-right" transition-hide="flip-left">{{ $t('MoveLastPage') }}</q-tooltip> -->
      </a>
    </div>
    <div class="right_area">
      <!-- NOTE: 페이지네이션 오른쪽에 표시하고 싶은게 있을 때 사용 -->
      <span v-if="props.isShowTotalItemCount" class="paginate_txt">
        Total: <b>{{ props.totalItemCount }}</b>
      </span>
      <slot name="right" />
    </div>
  </div>
</template>

<script setup lang="ts">
// @ts-nocheck
export interface Props {
  // 전체 아이템 개수
  totalItemCount?: number
  // 페이지당 표시하는 아이템 목록 수
  pageSize?: number
  // 현재 페이지
  currentPage?: number
  // 기본으로 10페이지가 한 그룹
  pageStepLevel?: number
  // 좌측 전체 페이지 수 표시 여부
  isShowTotalPageCount?: boolean
  // 우측 전체 아이템 개수 표시 여부
  isShowTotalItemCount?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  // 전체 아이템 개수
  totalItemCount: 0,
  // 페이지당 표시하는 아이템 목록 수
  pageSize: 10,
  // 현재 페이지
  currentPage: 1,
  // 기본으로 10페이지가 한 그룹
  pageStepLevel: 10,
  // 전체 페이지 수 보이지 않는 것을 기본으로 함
  isShowTotalPageCount: false,
  // 전체 아이템 개수 보이지 않는 것을 기본으로 함
  isShowTotalItemCount: false
})

let startPage = 1
let endPage = 1
let totalPageCount = 0
const pageRange = reactive([] as number[])

const setPage = () => {
  if (props.currentPage < 1) {
    moveTo(1)
    return
  }

  // NOTE: 포탈에서 사용하는 쿼리가 데이터 row에 totalCount를 넣어서 반환하도록 짜여있는게 많아서
  // 페이지를 잘못 주면 실제로 데이터가 있어도, totalCount알 수 없게된다.
  // 이를 보완하기 위해 totalCount가 0엔데 현재 페이지가 1페이지보다 크다면 1페이지로 다시 조회해보도록 한다.
  if (props.totalItemCount == 0 && props.currentPage > 1) {
    moveTo(1)
    return
  }

  // 전체 페이지 개수
  totalPageCount = Math.ceil(props.totalItemCount / props.pageSize)

  // 넘어온 현재 페이지 값이 마지막 페이지보다 크면, 마지막 페이지로 설정
  if (totalPageCount > 0 && props.currentPage > totalPageCount) {
    moveTo(totalPageCount)
    return
  }

  startPage = Math.floor((props.currentPage - 1) / props.pageStepLevel) * props.pageStepLevel + 1
  endPage = Math.min(startPage + props.pageStepLevel - 1, totalPageCount)

  pageRange.splice(0)
  for (let page = startPage; page <= endPage; page++) {
    pageRange.push(page)
  }
}

const pageClass = (page: number): string => {
  if (page == props.currentPage) {
    return 'active'
  }
  return ''
}

const moveTo = (page: number): void => {
  if (page == props.currentPage) {
    return
  }

  emit('update:currentPage', page)
  emit('move', page)
}

const moveToPrevBlock = () => {
  moveTo(GetPrevBlock())
}

const GetPrevBlock = () => {
  const prevBlockFirstPage = startPage - props.pageStepLevel
  return Math.max(1, prevBlockFirstPage)
}

const moveToNextBlock = () => {
  moveTo(GetNextBlock())
}

const GetNextBlock = () => {
  const nextBlockFirstPage = endPage + 1
  return Math.min(totalPageCount, nextBlockFirstPage)
}

const moveToFirstPage = () => {
  moveTo(1)
}

const moveToLastPage = () => {
  moveTo(totalPageCount)
}

onMounted(() => {
  setPage()
})

watch(
  () => props.totalItemCount,
  () => {
    setPage()
  },
  { deep: true, immediate: true }
)

watch(
  () => props.pageSize,
  () => {
    setPage()
  },
  { deep: true, immediate: true }
)

watch(
  () => props.currentPage,
  () => {
    setPage()
  },
  { deep: true, immediate: true }
)

watch(
  () => props.pageStepLevel,
  () => {
    setPage()
  },
  { deep: true, immediate: true }
)

const emit = defineEmits<{ (e: 'update:currentPage', page: number): void; (e: 'move', page: number): void }>()
</script>
<style scoped lang="scss">
.paginate_btn,
.paginate_num {
  cursor: pointer;
}

.paginate {
  text-align: center;
  position: relative;
  margin-top: 15px;
  min-height: 30px;
}

.paginate .left_area {
  position: absolute;
  left: 0;
  display: inline-block;
}

.paginate .right_area {
  position: absolute;
  right: 0;
  text-align: right;
  display: inline-block;
}

.paginate .paginate_list {
  display: -moz-flex;
  display: inline-flex;
  -moz-justify-content: center;
  justify-content: center;
  text-align: center;
  font-size: 0;
}

.paginate .paginate_list .paginate_btn,
.paginate .paginate_list .paginate_num {
  border: 1px solid transparent;
}

.paginate .paginate_list .paginate_num {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 32px;
  height: 30px;
  padding: 0 3px;
  font-size: 14px;
  line-height: 30px;
  color: #666;
}

.paginate .paginate_list .paginate_num:hover {
  z-index: 1;
  border-color: #f66c51;
}

.paginate .paginate_list .paginate_num.active {
  color: #f66c51;
  border-color: #f66c51;
  z-index: 2;
}

.paginate .paginate_list .paginate_num_input {
  float: left;
  margin-left: 15px;
  width: 55px;
  height: 30px;
  vertical-align: middle;
}

.paginate .paginate_list .paginate_num + .paginate_num,
.paginate .paginate_list .paginate_num + .paginate_btn {
  margin-left: -1px;
}

.paginate .paginate_list .paginate_btn {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 32px;
  height: 30px;
  padding: 0 8px;
  background: url(../../assets/images/common/img_paginate.png) no-repeat;
  border: 1px solid transparent;
  background-size: 200px 90px;
  background-color: #fff;
}

.paginate .paginate_list .paginate_btn + .paginate_num,
.paginate .paginate_list .paginate_btn + .paginate_btn {
  margin-left: -1px;
}

.paginate .paginate_list .paginate_btn:hover {
  border-color: #d8d8d8;
  z-index: 1;
}

.paginate .paginate_list .paginate_btn_fst {
  background-position: left top;
}

.paginate .paginate_list .paginate_btn_prev {
  background-position: -32px top;
}

.paginate .paginate_list .paginate_btn_next {
  background-position: -64px top;
}

.paginate .paginate_list .paginate_btn_lst {
  background-position: -96px top;
}

.paginate .paginate_list .paginate_total {
  font-size: 14px;
  line-height: 1.4;
  color: #222;
  float: left;
  display: inline-block;
  margin: 6px 15px 0 5px;
  vertical-align: middle;
}

.paginate .paginate_txt {
  font-size: 14px;
  line-height: 27px;
  color: #aaa;
}

.paginate .paginate_txt b {
  color: #f66c51;
}
</style>
