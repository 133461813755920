<template>
  <div class="menu_all_scroll_wrap" :class="props.keyword === '' ? 'no_search' : ''">
    <div class="nano has-scrollbar">
      <div ref="parentScroll" class="nano-content" tabindex="0" style="">
        <ul>
          <li class="menu_all_item_wrap">
            <ul id="menu_all_full_area">
              <template v-for="item in props.items" :key="item.id">
                <li
                  :id="item.id ?? ''"
                  ref="menuContents"
                  class="menu_active_item menu_all_item depth2"
                  :class="`${
                    props.keyword != '' ? (item.name && item.name.indexOf(props.keyword) > -1 ? 'search_word' : '') : ''
                  } ${toggleActive(item)}`">
                  <a
                    class="menu_all_tit depth2 cursor_pointer"
                    @click.prevent="handleDetailMenuItemClick(item.url ?? '', item.id ?? '')"
                    >{{ item.name }}
                  </a>
                  <span class="cursor_pointer" @click="handleExpendMenu(item)"></span>
                  <site-full-menu-contents-middle
                    ref="menuContentsMiddle"
                    :depth="3"
                    :item="item.children"
                    :p-id="item.id ?? ''"
                    :keyword="props.keyword"
                    @on-click-menu="handleDetailMenuItemClick">
                  </site-full-menu-contents-middle>
                </li>
              </template>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { MenuItemModel } from 'mik-common/src/api-autogen/globalmenu'
import SiteFullMenuContentsMiddle from './SiteFullMenuContentsMiddle.vue'
/** 정보공유, 게시판, 협업 등을 2depth로 보고 시작*/
const emit = defineEmits(['on-click-menu'])
const parentScroll = ref() //ref를 선언한 위치에서 부터 스크롤 시작
interface Props {
  items: MenuItemModel[]
  moveItem: MenuItemModel
  keyword?: string
  menuHistoryList?: string[]
}
const props = withDefaults(defineProps<Props>(), {
  items: () => [],
  keyword: ''
})

const menuContentsMiddle = ref<InstanceType<typeof SiteFullMenuContentsMiddle>[]>() //외부함수 호출위해 선언

/** top Nav 펼치기 숨기기 시작 ------------------------*/

/** top Nav Expends 메뉴 펼치기 닫기 숨김/보이는메뉴 계산 */
const handleExpendMenu = (menu: MenuItemModel) => {
  const hasItem = expandedItems.some((item: string) => item === menu.id) //기존에 펼친 메뉴인지
  if (!hasItem) {
    expandedItems.push(menu.id ?? '') //expandedItems 값을 넣어 펼친다.
  } else {
    //삭제 하고 닫는다.
    const index = expandedItems.findIndex((x) => x == menu.id)
    if (index > -1) {
      expandedItems.splice(index, 1)
    }
  }
}

/** top Nav click */
const handleDetailMenuItemClick = (url: string, menuId: string) => {
  emit('on-click-menu', url, menuId)
}
const expandedItems: string[] = reactive([])
/** top Nav 펼치기 숨기기 이미지 변경*/
const toggleActive = (menu: MenuItemModel) => {
  const hasItem = expandedItems.some((item: string) => item === menu.id)
  //hasItem true이면 숨기고, false이면 열어준다.
  return hasItem ? 'deactivate' : 'active'
}
/** top Nav 펼치기 숨기기 끝 ------------------------*/

/** 메뉴 위치 찾기 시작 ------------------------------*/
//메뉴는 2레벨 부터 시작입니다
const level2Index = ref(0) //2레벨의 index
const level2Id = ref('') //2레벨의 id값 메뉴를 찾기 위해서 위->아래로 찾아야 하는데 위치를 매번 찾기에는 중복되어서 변수로 저장
const level3Id = ref('') //3레벨의 id값
const level4Id = ref('') //4레벨의 id값
const level5Id = ref('') //5레벨의 id값
/** 메뉴 위치 찾기 함수(watch로 감지) */
const handleMoveToScroll = () => {
  const moveItems: MenuItemModel[] = []
  Object.assign(moveItems, props.items)

  const roofOut = false //재귀 함수를 실행할지 여부

  const offSetTop = GetOffsetC(level2Id.value ?? '') //2레벨의 item위치 (정보공유, 메일, 커뮤니티, 협업등 css에서는 2레벨이고, 랜더링상 1레벨이지만 2레벨로 한다.)
  parentScroll.value.scrollTo({ top: offSetTop, behavior: 'smooth' }) //위치로 이동

  if (moveItems && menuContentsMiddle.value) {
    for (let i = 0; i < moveItems.length; i++) {
      level2Index.value = i //최상위 부모index
      if (moveItems[i].id == level2Id.value) {
        break
      }
    }

    const offSetTop2Loc = GetOffsetC(level2Id.value ?? '') //2레벨의 item위치
    const offSetTop3Loc = menuContentsMiddle.value[level2Index.value].GetOffsetM(level3Id.value ?? '', '', '', 3) //3레벨 위치 찾기
    const offSetTop4Loc = menuContentsMiddle.value[level2Index.value].GetOffsetM(level4Id.value, level3Id.value, '', 4) //4레벨 위치
    const offSetTop5Loc = menuContentsMiddle.value[level2Index.value].GetOffsetM(
      level5Id.value,
      level4Id.value,
      level3Id.value,
      5
    )

    let offsetModify = 0 //offset보정
    if (offSetTop5Loc > 0) offsetModify = 25

    const sum = offSetTop2Loc + offSetTop3Loc + offSetTop4Loc + offSetTop5Loc + offsetModify
    parentScroll.value.scrollTo({
      top: sum,
      behavior: 'smooth'
    })
  }
}

const menuContents = ref<HTMLElement[]>() //ref로 매핑한 htmlElement를 가져옴
/** 2레벨의 엘리먼트에서 위치 찾기 */
const GetOffsetC = (menuId: string) => {
  const index = props.items.findIndex((menu) => menu.id == menuId)
  if (menuContents.value && index > -1) return menuContents.value[index].offsetTop
  else return 0
}
/** 부모 li 열기-레벨2가 닫혀 있다면 열어야 한다.*/
const handleParentLiOpen = () => {
  const history: string[] = reactive([])
  if (props.menuHistoryList) {
    Object.assign(history, props.menuHistoryList)
    const index = expandedItems.findIndex((element) => element == history[0])
    if (index > -1) expandedItems.splice(index, 1) //2레벨의 위치가 닫혔다면 연다

    //3레벨은 열고닫기 기능이 없고 4레벨을 찾아서 열어준다.
    if (menuContentsMiddle.value) {
      const index2 = props.items.findIndex((element) => element.id == history[0])
      if (index2 > -1) menuContentsMiddle.value[index2].handleParentExpand(history)
    }

    //스크롤이동 소스를 줄이기 위해서 history 사용
    level2Id.value = history.length > 0 ? history[0] : ''
    level3Id.value = history.length > 1 ? history[1] : ''
    level4Id.value = history.length > 2 ? history[2] : ''
    level5Id.value = history.length > 3 ? history[3] : ''
  }
}
watch(props.moveItem, () => {
  handleParentLiOpen() //부모의위치를 먼저 연다.
  setTimeout(() => {
    handleMoveToScroll() //스크롤 이동
  }, 10)
})

/** 메뉴 위치 찾기 끝 ------------------------------*/

onMounted(() => {
  /** scroll overflow 오동작을 방지 */
  const elHtml = document.getElementsByTagName('html')[0]
  elHtml.setAttribute('style', 'overflow-y:hidden')
})

onUnmounted(() => {
  const elHtml = document.getElementsByTagName('html')[0]
  elHtml.setAttribute('style', 'overflow-y:auto')
})
</script>
<style>
/* 
.menu_all_full_type02 .menu_all_full_wrap .menu_all_item.depth2 {
  width: 100%;
  position: relative;
  padding: 26px 30px;
  border: 1 + px solid #d8d8d8 !important;
  box-sizing: border-box;
}

.menu_all_full_type02 .menu_all_full_wrap .menu_all_item.depth2 + .menu_all_item.depth2 {
  margin-top: 30px;
} */

/* .menu_all_full_type02 .menu_all_full_wrap .menu_all_item.depth2 .menu_all_tit.depth2 {
  width: 100%;
  font-weight: 700;
  font-size: 32px;
  color: #222;
  /* padding-bottom: 20px; 20240102* /
} */

/* 메뉴검색 */
/* .search_section .search_title {
  font-size: 1rem;
  color: #222;
  line-height: 22.4px;
  font-weight: 700;
  padding: 0px 0 5px;
} */

.menu_all_anchor depth3:after {
  font-size: 16px;
  /* 20240105 css v1을 덮어쓰기 하니 폰트 사이즈가 너무 커서 수정 2rem */
}
</style>
