<template>
  <VueFinalModal 
    :class="props.class" 
    :content-class="props.contentClass" 
    overlay-transition="vfm-fade"
    content-transition="vfm-fade"
    :reserve-scroll-bar-gap="false"
    :click-to-close="false"
    @update:model-value="close">
    <div class="pop-container">
      <div class="dialog">
        <div class="pop-header">
          <div class="row">
            <div class="col title">
              {{ props.title }}
            </div>
            <div class="cursor-pointer">
              <i class="material-icons i-close" @click="handleCancelClick">close</i>
            </div>
          </div>
        </div>
        <div class="pop-body">
          <slot name="body"/>
        </div>
        <div v-if="useButton" class="pop-footer" style="text-align: center;">
          <mik-button-ext 
            button-type="btn_type_a btn_color_04" 
            style="width: 95px" 
            :button-txt="$t('OK')"
            @click.prevent="handleOkClick" />
          <mik-button-ext 
            button-type="btn_type_a btn_color_03" 
            style="width: 95px" 
            :button-txt="$t('Cancel')"
            @click.prevent="handleCancelClick" />
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal'
import MikButtonExt from './MikButtonExt.vue'

export interface Props {
  title?: string
  class?: string
  contentClass?: string
  size?: 's' | 'm' | 'l' | 'xl' | 'xxl' | 'fullscreen'
  useButton?: boolean
  okText?: string
  cancelText?: string
  useDelButton?: boolean
  onOk?: ((close: () => void) => void) | null
  onCancel?: (() => void) | null
  onDel?: ((close: () => void) => void) | null
}

const props = withDefaults(defineProps<Props>(), {
  title: 'Smart Portal',
  class: 'common-popup-modal',
  contentClass: 'common-popup-modal-content',
  size: 'm',
  useButton: false,
  okText: '확인',
  cancelText: '취소',
  useDelButton: false,
  onOk: null,
  onCancel: null,
  onDel: null
})

const handleOkClick = () => {
  if (props.onOk) {
    props.onOk(close)
  } else {
    close()
  }
}

const handleCancelClick = () => {
  if (props.onCancel) {
    props.onCancel()
  }
  close()
}

const handleDeleteClick = () => {
  if (props.onDel) {
    props.onDel(close)
  }
  close()
}

const close = () => {
  emit('update:modelValue', false)
}

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
  (e: 'update:use-alert', value: boolean): void
  (e: 'update:use-info', value: boolean): void
  (e: 'update:use-confirm', value: boolean): void
}>()
</script>

<style>
.common-popup-modal, .common-input-popup-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.common-popup-modal .pop-container {
  width: 712px;
  height: 582px;

  .pop-header {
    background-color: #272727;
    color: #fff;
    font-size: 1rem !important;
    padding: 14px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}

.common-input-popup-modal .pop-container {
  width: 720px;
  height: 180px;

  .pop-header {
    background-color: #272727;
    color: #fff;
    font-size: 1rem !important;
    padding: 10px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}

.common-popup-modal .pop-body {
  width: 680px;
  margin: 0px;
  padding: 16px;
  position: relative;
}

.common-input-popup-modal .pop-body {
  width: 680px;
  padding: 16px;
  position: relative;
}

.common-popup-modal .pop-footer {
  height: 40px;
  width: 680px;
  margin: 0px;
  padding: 16px;
  position: relative;
}

.common-popup-modal-content {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 0.5rem;
}

.common-popup-modal-content>*+* {
  margin: 0.5rem 0;
}

.common-popup-modal-content h1 {
  font-size: 1.375rem;
}

.common-popup-modal-content button {
  margin: 0.25rem 0 0 auto;
  padding: 0 8px;
  border-radius: 0.5rem;
}

.dark .common-popup-modal-content {
  background: #000;
}

.common-popup-modal-content .material-icons.i-close, .common-input-popup-modal .material-icons.i-close {
  font-size: 35px;
  color: #fff !important;
}
</style>