<template>
  <div ref="root" class="gwp_header_shrt_menu brder-left gwp_header_fav">
    <div class="gwp_button_wrap menu_box">
      <button
        :title="$t('Favorite')"
        class="menu_box_img gwp_btn_header_fav btn_slidedown"
        @click.prevent="handleBookmarkClick">
        <span class="menu_fav ico_header ico_header_fav"></span>
        <span class="menu_txt">{{ $t('Favorite') }}</span>
      </button>
    </div>

    <div class="gwp_box_slidedown_wrap">
      <slide-down>
        <div v-if="isBookmarkVisible" class="gwp_fav_wrap box_slidedown" style="display: block">
          <div class="title_area">
            <span class="fav_title">My Favorites</span>
            <button class="btn_fav_setting" @click.prevent="handleBookmarkSettingClick">
              <span class="ico_header ico_header_setting"></span>
            </button>
          </div>
          <div class="fav_area">
            <div class="nano has-scrollbar">
              <div class="nano-content" tabindex="0">
                <ul id="gwp_fav_list" class="gwp_fav_list">
                  <li v-for="item in bookmarkList" :key="item.id" class="gwp_fav_item">
                    <a class="gwp_fav_anchor" @click.prevent="handleBookmarkMenuClick(item.url ?? '', $event)">{{
                      item.name
                    }}</a>
                  </li>
                </ul>
              </div>
              <!-- <div class="nano-pane"
                   style="display: none; opacity: 1; visibility: visible">
                <div class="nano-slider"
                     style="transform: translate(0px, 0px); height: 240px"></div>
              </div> -->
            </div>
          </div>
        </div>
      </slide-down>
    </div>
  </div>
  <!-- Popup -->
  <!-- <mik-layer-popup ref="MikCommonPopup">
    <template #mikLayerPopupBody>
      <favorite-setting :item-list="bookmarkList"
                        @update:item-list="handleItemListUpdate"
                        @on-hide-popup="handleBookmarkSettingClose">
      </favorite-setting>
    </template>
  </mik-layer-popup> -->
  <!-- -->
  <!-- <common-popup
    :model-value="isBookmarkSettingVisible"
    :title="$t('BookmarkSettings')"
    size="m"
    :on-cancel="handleBookmarkSettingClose">
    <div>
      <favorite-setting
        :item-list="bookmarkList"
        @update:item-list="handleItemListUpdate"
        @on-hide-popup="handleBookmarkSettingClose">
      </favorite-setting>
    </div>
  </common-popup> -->
  <mik-common-popup
    v-model="isBookmarkSettingVisible"
    :title="$t('BookmarkSettings')"
    size="m"
    :on-cancel="handleBookmarkSettingClose">
    <template #body>
      <favorite-setting
        :item-list="bookmarkList"
        @update:item-list="handleItemListUpdate"
        @on-hide-popup="handleBookmarkSettingClose">
      </favorite-setting>
    </template>
  </mik-common-popup>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { BookmarkApi } from 'mik-common/src/api-autogen/globalmenu/api/bookmark-api'
import { BookmarkModel } from 'mik-common/src/api-autogen/globalmenu'
import SlideDown from '@gnb/components/animations/SlideDown.vue'
import FavoriteSetting from '@gnb/modules/menus/gnb-items/FavoriteSetting.vue'
// import { useToast } from '@gnb/common/toast'
import { useGlobalMenuStore } from '@gnb/store/modules/global-menu/global-menu'
import MikCommonPopup from '../common/MikCommonPopup.vue'
const api = new BookmarkApi()
/** root El */
const root = ref(null)
/** 최근 사용 메뉴 DropDown Visible */
const isBookmarkVisible = ref(false)
/** 즐겨찾기 데이터 */
const bookmarkList = ref<BookmarkModel[]>([])

/** LifeCycle Hook */
/** handle document click */
onMounted(() => {
  document.addEventListener('click', handleDocumentClick)
})
onBeforeUnmount(() => {
  document.removeEventListener('click', handleDocumentClick)
})

/** 북마크 조회 */
const getBookmarkList = () => {
  api.v1BookmarkGet().then((response) => {
    if (response.data.success && response.data.data) bookmarkList.value = response.data.data
  })
}
/** handle Events */
/** 최근 사용 메뉴 SlideDown 클릭 */
const handleBookmarkClick = () => {
  if (!isBookmarkVisible.value) getBookmarkList()
  isBookmarkVisible.value = !isBookmarkVisible.value
}
/** 북마크 메뉴 클릭 */
const handleBookmarkMenuClick = (url: string, event: MouseEvent) => {
  if (event.ctrlKey) {
    handleOpenTab(url)
  } else {
    window.location.href = url
  }
}
/** tab open url */
const handleOpenTab = (url: string) => {
  window.open(url, '_blank', '')
}
/** 북마크 설정 popup*/
const isBookmarkSettingVisible = ref(false)

/** 북마크 설정 팝업 클릭 */
const handleBookmarkSettingClick = () => {
  isBookmarkVisible.value = false
  isBookmarkSettingVisible.value = true

  const i = document.body
  i.classList.add('body-prevent-scroll')
}
/** 북마크 설정 팝업 close */
const handleBookmarkSettingClose = async () => {
  isBookmarkSettingVisible.value = false
  //설정 팝업을 닫으면서 navigation 즐겨찾기 업데이트
  await useGlobalMenuStore().fetchItems(undefined, true, document.location.href, 'favorite')

  const i = document.body
  i.classList.remove('body-prevent-scroll')
}
/** List Update */
const handleItemListUpdate = (newItemList: BookmarkModel[]) => {
  bookmarkList.value = newItemList
}

const handleDocumentClick = (e: Event) => {
  if (e == undefined || e == null) {
    return
  }
  /**
   * 해당 컴포넌트 외부에서 클릭이 되었을 경우, drop down을 닫기위한 기능 연결
   */
  // @ts-ignore
  if (!root.value.contains(e.target)) {
    isBookmarkVisible.value = false
  }
}
</script>

<style scoped>
.gwp_fav_list .gwp_fav_item {
  cursor: pointer;
}
</style>
