<template>
  <button :class="props.buttonType" style="margin: 5px; height: 30px;" @click="clickBtn">
    <span class="btn_txt">{{ props.buttonTxt }}</span>
  </button>
</template>

<script setup lang="ts">
export interface Props {
  buttonTxt?: string
  buttonType?: string
  clickFunction?: (() => {}) | Function | null
}

const props = withDefaults(defineProps<Props>(), {
  buttonTxt: '',
  buttonType: '',
  clickFunction: null,
})

const clickBtn = () => {
  if (props.clickFunction && typeof props.clickFunction === 'function') {
    props.clickFunction()
  }
}
</script>
<style scoped></style>