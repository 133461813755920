<template>
  <li
    class="gwp_left_nav nav_con"
    @mouseenter="handleMouseEnter(item.id ?? '')"
    @mouseleave="handleMouseLeave(item.id ?? '')"
    @click.prevent="handleMenuClick(item.url ?? '', item.id ?? '')">
    <img :class="`${item.id ?? ''}`" />
    <p v-if="props.isNameDisplay" class="line_break_revision">{{ props.item.name ?? '' }}</p>
    <p v-else v-show="isDynamicNameDisplay" class="line_break_revision">{{ props.item.name ?? '' }}</p>
  </li>
</template>

<script setup lang="ts">
import { MenuItemModel } from 'mik-common/src/api-autogen/globalmenu'

interface Props {
  item: MenuItemModel
  isNameDisplay: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isNameDisplay: true
})

const emit = defineEmits(['on-mouse-enter', 'on-mouse-leave', 'on-click-menu'])

const handleMouseEnter = (id: string) => {
  isDynamicNameDisplay.value = true
  emit('on-mouse-enter', id)
}

const handleMouseLeave = (id: string) => {
  isDynamicNameDisplay.value = false
  emit('on-mouse-leave', id)
}

const handleMenuClick = (url: string, menuId: string) => {
  emit('on-click-menu', url, menuId)
}

// Left_Nav Mouse Enter or Leave => Show or Hide
const isDynamicNameDisplay = ref(false)
</script>
