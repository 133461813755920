<template>
  <div id="gwpNavigationBar" class="gwp_default gwp_path">
    <ul class="gwp_path_list">
      <home-button @click="handleHomeClick" />
      <template v-for="(item, index) in useNavigationBar.navGetItems" :key="index">
        <navigation-bar-item :item="item" @click="handleMenuClick(item)" />
      </template>
    </ul>
    &nbsp;
    <favorite-button :is-active="isFavorite" @click="handleFavoriteClick" />
  </div>
  <div>
    <div>
      <!-- <common-popup :model-value="isFavoriteVisible" :on-cancel="handleFavoriteClose" title="Smart Portal" size="m">
        <div>
          <div>
            <table
              style="table-layout: fixed; border-top: 1px solid #e0e0e0; border-bottom: 1px solid #e0e0e0; width: 100%">
              <colgroup>
                <col width="145" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th style="background-color: #f4f4f6">{{ $t('Favorite') }}</th>
                  <td>
                    <mik-input id="input_favorite" v-model="input_favorite" autofocus type="text" style="width: 100%" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style="padding-top: 10px; text-align: center">
            <mik-button :click-function="handleFavoriteSave" :button-txt="$t('Save')" />
          </div>
        </div>
      </common-popup> -->
      <mik-common-popup
        :model-value="isFavoriteVisible"
        :on-cancel="handleFavoriteClose"
        title="Smart Portal"
        class="common-input-popup-modal"
        content-class=""
        size="m">
        <template #body>
          <div>
            <div>
              <table
                style="
                  table-layout: fixed;
                  border-top: 1px solid #e0e0e0;
                  border-bottom: 1px solid #e0e0e0;
                  width: 100%;
                ">
                <colgroup>
                  <col width="145" />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th style="background-color: #f4f4f6">{{ $t('Favorite') }}</th>
                    <td>
                      <mik-input-ext
                        id="input_favorite"
                        v-model="input_favorite"
                        autofocus
                        type="text"
                        style="width: 100%" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="sect_button" style="padding-top: 10px; text-align: center">
              <mik-button-ext
                :click-function="handleFavoriteSave"
                :button-txt="$t('Save')"
                button-type="btn_type_a btn_color_04" />
            </div>
          </div>
        </template>
      </mik-common-popup>
    </div>
  </div>
</template>

<script setup lang="ts">
import HomeButton from './navigation-items/HomeButton.vue'
import NavigationBarItem from './navigation-items/NavigationBarItem.vue'
import FavoriteButton from './navigation-items/FavoriteButton.vue'
// import { useNavigationBarStore } from '@gnb/store/modules/navigation-bar/navigation-bar'
import { useGlobalMenuStore } from '@gnb/store/modules/global-menu/global-menu'

import useGwpGlobalMenu from '@gnb/utils/gnb'
import { BookmarkApi } from 'mik-common/src/api-autogen/globalmenu/api/bookmark-api'

import { NavigationBarModel } from 'mik-common/src/api-autogen/globalmenu/models/navigation-bar-model'
import { mikCustomPopup } from '@gnb/common/common'
import MikCommonPopup from './common/MikCommonPopup.vue'
import MikButtonExt from './common/MikButtonExt.vue'
import MikInputExt from './common/MikInputExt.vue'
// import { useToast } from '@gnb/common/toast'
import { installOptions } from './main'

import { useModeStore } from 'mik-common/src/store/mode'

// const toast = useToast()

const apiBook = new BookmarkApi()
const useGlobalMenu = useGlobalMenuStore()
const modeStore = useModeStore()

interface Props {
  url: string
  langCode: string
  showHiddenMenu: boolean
}

const props = withDefaults(defineProps<Props>(), {
  url: '',
  langCode: 'ko',
  showHiddenMenu: true
})

const { globalMenuClick } = useGwpGlobalMenu()

let url = props.url
if (url == '') {
  url = modeStore.alternativeUrl
  if (url == '') {
    url = location.href
  }
}
const useNavigationBar = useGlobalMenuStore()
const isFavorite = ref(false)
onMounted(async (): Promise<void> => {
  await useNavigationBar.fetchItems(undefined, true, url, 'navigation') //이미 fetchItems가 실행중이라면 호출이 되지 않을 수 있다.
  handleFavoriteActive()
})

const handleFavoriteActive = () => {
  if (computedCurrentItem.value?.isFavorite != undefined) {
    isFavorite.value = computedCurrentItem.value?.isFavorite
  }
}

const handleMenuClick = (item: NavigationBarModel) => {
  //context.emit("on-menu-click", item);
  globalMenuClick(item.link)
}
const handleHomeClick = () => {
  const frontUrl = installOptions.baseUrl ? installOptions.baseUrl + '/Front' : '/Front'
  location.href = frontUrl
}
const { t } = useI18n()

/* 즐겨찾기 추가/삭제 */
const handleFavoriteClick = async () => {
  //emit('on-favorite-click', computedCurrentItem.value)
  if (computedCurrentItem.value?.bookmarkId) {
    mikCustomPopup.confirm(
      t('CommonFavoriteDel', '즐겨찾기를 해제 하시겠습니까?'),
      handleFavoriteDel,
      '',
      computedCurrentItem.value?.bookmarkId
    )
  } else {
    isFavoriteVisible.value = true
    input_favorite.value =
      computedCurrentItem.value?.name == null || computedCurrentItem.value?.name == undefined
        ? ''
        : computedCurrentItem.value?.name
  }
}

/* null체크를 위해서 computed를 사용 */
const computedCurrentItem = computed((): NavigationBarModel | null => {
  const length = useNavigationBar.navGetItems.length

  if (length > 0) {
    return useNavigationBar.navGetItems[length - 1] as NavigationBarModel
  } else {
    return null
  }
})

const input_favorite = ref('') //favorite 저장 이름
const isFavoriteVisible = ref(false) //즐겨찾기 저장 창
/** favorite 팝업 끄기 */
const handleFavoriteClose = () => {
  // toast.info(`${t('CancelButton')}`)
  isFavoriteVisible.value = false
}
interface menuRequest {
  name: string
  url: string
  menuId: string
}
/** 즐겨 찾기 저장 */
const handleFavoriteSave = async () => {
  const menu = computedCurrentItem.value
  if (menu) {
    //const menuLink = menu?.link == undefined ? '' : menu.link
    const menuUrl = menu?.url == undefined ? '' : menu.url //fullUrl을 가져옴
    console.log('handleFavoriteSave fullUrl:', menu.url)
    const menuRequest = ref<menuRequest>({
      name: input_favorite.value,
      url: menuUrl,
      menuId: menu?.menuId == undefined ? '' : menu.menuId
    })

    await apiBook
      .v1BookmarkPost(menuRequest.value)
      .then((response) => {
        menu.bookmarkId = response.data.data

        // toast.info(`${t('SaveSuccess')}`)
        isFavorite.value = true
        isFavoriteVisible.value = false
      })
      .catch((err) => console.log(err))
  }
}
/** 즐겨 찾기 삭제 */
const handleFavoriteDel = async (bookmarkId: string) => {
  await apiBook
    .v1BookmarkIdDelete(bookmarkId)
    .then((response) => {
      if (useNavigationBar.navGetItems.length > 0)
        (useNavigationBar.navGetItems[useNavigationBar.navGetItems.length - 1] as NavigationBarModel).bookmarkId = ''
      isFavorite.value = false
      // toast.info(`${t('DeleteSuccess')}`)
    })
    .catch((err) => console.log(err))
}

watch(
  computedCurrentItem,
  (newVal, oldVal) => {
    handleFavoriteActive()
  },
  { immediate: true }
)

watch(isFavoriteVisible, () => {
  if (isFavoriteVisible.value) {
    const i = document.body
    i.classList.add('body-prevent-scroll')
  } else {
    const i = document.body
    i.classList.remove('body-prevent-scroll')
  }
})

const emit = defineEmits<{ (e: 'on-favorite-click', navigationInfo: NavigationBarModel | null): void }>()
</script>
