<template>
  <li class="gwp_path_item">
    <a href="javascript:;" class="gwp_path_anchor" @click.prevent="handleHomeClick">
      <span class="ico_header ico_header_path_home"></span>
    </a>
  </li>
</template>

<script setup lang="ts">
const handleHomeClick = () => {
  emits('click')
}

const emits = defineEmits<{ (e: 'click'): void }>()
</script>
