import axios from 'axios'
import { ref, reactive, readonly, onUnmounted } from 'vue'
import { useNotifyGlobalSettingsStore } from '@gnb/store/modules/notification-center/notifyGlobalSettingsStore'
import { NotifyPrivateObj } from './notifyPrivateObj'
import { usePrivateObjectsStore } from '@gnb/store/modules/notification-center/notifyPrivateObjStore'

// 전역 스토어 객체

export const useNotifyHelpers = (connectNotificationServer: () => void) => {
  // privateObjects 인스턴스 사용
  const notifyGlobalSettingsStore = useNotifyGlobalSettingsStore()
  const privateObjectsStore = usePrivateObjectsStore()
  //const notifyManager = useNotificationCenterClientManager()

  // 유틸리티 함수들
  const generateId = () => {
    let text = ''
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const now = new Date()
    const ticks = now.getTime() / now.getMilliseconds()
    const rnd = ticks - Math.floor(ticks)
    for (let i = 0; i < 20; i++) {
      text += possible.charAt(Math.floor(rnd * possible.length))
    }
    return text
  }

  const random = (max: number, min: number = 1) => {
    min = typeof min === 'number' && min >= 0 ? min : 1
    max = typeof max === 'number' && max > min ? max : min * 2
    // 랜덤 소숫점을 만든다
    const now = new Date()
    const ticks = now.getTime() / now.getMilliseconds()
    const rnd = ticks - Math.floor(ticks)
    return Math.max(Math.floor((rnd * 100) % max) + min, min)
  }

  const appendMessageLog = (msg: String = 'EMPTY Message', data: String = 'NULL Data') => {
    console.log({ Message: msg, Data: data })
  }

  const sendSocketMessage = (socket: WebSocket | null, data = {}) => {
    if (socket && socket.send) {
      socket.send(JSON.stringify(data))
    }
  }

  const sendNop = (socket: WebSocket) => {
    appendMessageLog('send nop')
    sendSocketMessage(socket, { action: 'nop' })
  }

  const keepalive = (socket: WebSocket, interval: number | null = null) => {
    interval = typeof interval === 'number' ? interval : notifyGlobalSettingsStore.getKeepalive * 1000
    if (socket && socket.readyState === 1) {
      if (privateObjectsStore.connection.keepaliveWorker) {
        clearTimeout(privateObjectsStore.connection.keepaliveWorker)
      }
      sendNop(socket)
      if (interval > 0) {
        privateObjectsStore.connection.keepaliveWorker = setTimeout(
          () => keepalive(socket, interval),
          interval
        ) as unknown as number
      }
    } else if (socket.readyState === 2 || socket.readyState === 3) {
      // !!!!! Manager 개체 함수 호출
      //notifyManager.api.connectNotificationServer()
      if (typeof connectNotificationServer === 'function') {
        connectNotificationServer()
      }
    }
  }

  const convertToJsonFromSocket = (data: string | null) => {
    try {
      return typeof data === 'string' ? JSON.parse(data) : data || {}
    } catch (error) {
      console.error('Error parsing JSON from socket:', error)
      return {}
    }
  }

  const playAlramSound = (type = '') => {
    setTimeout(() => {
      const audio = new Audio(
        notifyGlobalSettingsStore.getApiUrl('/Resources/wav/alram' + type + '.wav') + '?_=' + Date.now()
      )
      audio.play().catch((e) => console.error('Failed to play alarm sound:', e))
    }, 500)
  }

  const standby = async (notifyPrivateObj: NotifyPrivateObj, fnConnectInnerLogic: Function | null = null) => {
    // 접속 및 재시도 딜레이 설정
    let delay = notifyGlobalSettingsStore.getDelaySeconds
    if (typeof delay !== 'number') {
      delay = 5
    }

    // 알림센터 접속을 위한 인증키 발급.. 시스템이 현 로그인 사용자 정보를 내려주도록 한다. 사용자 조작 접근 방지..
    const standbyUri = notifyGlobalSettingsStore.getApiUrl('/API/Connections/StandBySystem')

    try {
      const response = await axios.post(standbyUri, undefined)

      const json = await response.data.data
      if (json.success) {
        // !!!!! manager에서 사용될 값.. 적당한 변환이 필요함
        //window.MikNotificationCenter.socketuri = json.socket
        notifyGlobalSettingsStore.updateSocketUrl(json.socketUrl)
        privateObjectsStore.updateAcceptKey(json.acceptkey ?? '') // acceptkey가 null 또는 undefined이면 빈 문자열로 대체
        // 인증키 및 옵션 처리
        notifyPrivateObj.connection.acceptkey = json.acceptkey
        notifyPrivateObj.environment.mute = json.mute
        notifyPrivateObj.environment.inactivatedAlarms = json.inactivatedAlarms
        notifyPrivateObj.environment.playone = json.playone

        // 소켓 접속 (optional)
        if (typeof fnConnectInnerLogic === 'function') {
          // MIK.Geun :: 20200303 // 설정값이 과도하게 커지더라도 최초접속시에는 3초 이상 넘어가지 않도록 보정한다.
          setTimeout(() => fnConnectInnerLogic(json.acceptkey), Math.min(3, delay) * 1000)
        }
      } else {
        appendMessageLog('failed', json)
        const refinedDelay = Math.max(2, delay) // 1초보단 커야한다. 재시도 딜레이가 1초배수이면  항상 1초임..
        setTimeout(
          () => {
            // 재시도 함수 호출
            // !!!!! manager 함수 사용 gMikNotificationCenterClientManager.api.connectNotificationServer
            //notifyManager.api.connectNotificationServer()
            if (typeof connectNotificationServer === 'function') {
              connectNotificationServer()
            }
          },
          Math.min(++notifyPrivateObj.connection.retryCount, 10) * refinedDelay * 1000 + Math.random() * 5000
        )
      }
    } catch (e) {
      // 네트워크 에러 또는 기타 예외 처리
      const errorMessage = e instanceof Error ? e.message : 'Unknown error'
      appendMessageLog('error', errorMessage)
      const refinedDelay = Math.max(2, delay) // 1초보단 커야한다. 재시도 딜레이가 1초배수이면  항상 1초임..
      setTimeout(
        () => {
          // 재시도 함수 호출
          // !!!!! manager 함수 사용 gMikNotificationCenterClientManager.api.connectNotificationServer
          //notifyManager.api.connectNotificationServer()
          if (typeof connectNotificationServer === 'function') {
            connectNotificationServer()
          }
        },
        Math.min(++notifyPrivateObj.connection.retryCount, 10) * refinedDelay * 1000 + Math.random() * 5000
      )
    }
  }

  // 모든 함수를 객체로 묶어 반환합니다.
  return {
    generateId,
    random,
    appendMessageLog,
    sendSocketMessage,
    sendNop,
    keepalive,
    convertToJsonFromSocket,
    playAlramSound,
    standby
  }
}
