<template>
  <ul :class="`lnb_list depth${depth}`">
    <local-menu-item
      v-for="item in items"
      :key="item.id ?? ''"
      :item="item"
      :depth="depth"
      :expanded-items="expandedItems"
      @on-menu-click="handleMenuClick"
      @on-menu-expand="handleMenuExpand">
      <transition name="slidedown">
        <local-menu-list
          v-if="computedIsExpanded(item) && item.children != null && item.children.length > 0"
          :items="item.children"
          :depth="depth + 1"
          :is-all-expanded="isAllExpanded"
          :expanded-items="expandedItems"
          @on-menu-click="handleMenuClick"
          @on-menu-expand="handleMenuExpand" />
      </transition>
    </local-menu-item>
  </ul>
</template>

<script setup lang="ts">
import LocalMenuItem from './LocalMenuItem.vue'
import LocalMenuList from './LocalMenuList.vue'

import { LocalMenuModel } from 'mik-common/src/api-autogen/globalmenu'

interface Props {
  isAllExpanded?: boolean
  depth?: number
  items?: LocalMenuModel[]
  expandedItems?: LocalMenuModel[]
  isShow?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isAllExpanded: false,
  depth: 1,
  items: () => [],
  expandedItems: () => [],
  isShow: true
})

const handleMenuClick = (item: LocalMenuModel): void => {
  item.isSelected = true
  emits('on-menu-click', item)
}

const handleMenuExpand = (item: LocalMenuModel, isExpand: boolean): void => {
  emits('on-menu-expand', item, isExpand)
}

const computedIsExpanded = (item: LocalMenuModel): boolean | undefined => {
  if (item == null) {
    return false
  }
  if (props.expandedItems == null || props.expandedItems.length == 0) {
    return false
  }
  return props.expandedItems?.some((expanedItem: LocalMenuModel) => (expanedItem as LocalMenuModel).id === item.id)
}

const emits = defineEmits<{
  (e: 'on-menu-click', value: LocalMenuModel): void
  (e: 'on-menu-expand', item: LocalMenuModel, isExpanded: boolean): void
}>()
</script>
<style scoped>
/* .slidedown-enter-active,
.slidedown-leave-active {
  transition: max-height 0.1s ease-in-out;
} */

.slidedown-enter-active {
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slidedown-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slidedown-enter-to,
.slidedown-leave-from {
  overflow: hidden;
  max-height: 500px;
}

.slidedown-enter-from,
.slidedown-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
