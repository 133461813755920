// Pinia 스토어
import { defineStore } from 'pinia'
import { useNotificationCenterClientManager } from '@gnb/modules/notification-center/notifyManager'

export const useManagerStore = defineStore({
  id: 'manager',
  state: () => ({
    manager: useNotificationCenterClientManager()
  })
  // getters와 actions 추가 가능
})
