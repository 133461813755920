<template>
  <ul class="menu_active_sub_list" :class="depth > 4 ? 'depth5' : ''">
    <li
      v-for="i in item"
      :id="`${i.id}`"
      :key="i.id ?? ''"
      ref="menuContentsItem"
      class="menu_active_item menu_all_item"
      :class="depthClass(i)">
      <a
        :class="`menu_all_anchor depth${depth} `"
        @click.prevent="handleDetailSubMenuItemClick(i.url ?? '', i.id ?? '')">
        {{ i.name }}
      </a>
      <span v-show="renderPointer(depth)" class="cursor_pointer" @click="handleExpendMenu(i)"></span>

      <site-full-menu-contents-item
        ref="menuContentsItemSub"
        :depth="depth + 1"
        :item="i.children"
        :keyword="props.keyword"
        @on-click-menu="handleDetailSubMenuItemClick" />
    </li>
  </ul>
</template>

<script setup lang="ts">
import SiteFullMenuContentsItem from './SiteFullMenuContentsItem.vue'
import { MenuItemModel } from 'mik-common/src/api-autogen/globalmenu'
const emit = defineEmits(['on-click-menu'])
interface Props {
  item: MenuItemModel[] | null
  depth: number
  keyword: string
}
const props = withDefaults(defineProps<Props>(), {
  item: undefined,
  depth: 0
})

const menuContentsItem = ref<HTMLElement[]>()
const menuContentsItemSub = ref<InstanceType<typeof SiteFullMenuContentsItem>[]>() //siteFullMenuContentsItem 안에서 재귀로 부를 때 외부함수 호출용 선언

/** sub nav click */
const handleDetailSubMenuItemClick = (url: string, menuId: string) => {
  emit('on-click-menu', url, menuId)
}

/** toggle 변수 */
const expandedItems: string[] = reactive([]) //있으면 펼치고, 없으면 닫는다.
/** +, - toggle menu가 2개 이상있을 경우 + 로 표기*/
const toggleActive = (len: number, menu: MenuItemModel) => {
  const hasItem = expandedItems.some((item: string) => item === menu.id)
  //console.log('hasItem', hasItem, menu.name) //없어야 false일때 펼친다.
  return len == 0 ? '' : hasItem ? 'active' : 'deactivate'
}
/** depth가 5이상이면 +, - 표현을 하지 않는다. */
const renderPointer = (depth: number) => {
  return depth < 5 ? true : false
}
/** item의 offset을 찾고 return,  */
const GetOffsetI = (menuId: string, depth: number | 'etc') => {
  if (depth == 5) {
    let i_loc = 0
    if (menuContentsItemSub.value != undefined) {
      for (let j = 0; j < menuContentsItemSub.value.length; j++) {
        const level5Loc = menuContentsItemSub.value[j].GetOffsetI(menuId, 'etc') //5레벨의 위치를 찾기 위해서 재귀 호출 etc에는 5 아닌값이면 상관없음
        if (i_loc < level5Loc) {
          i_loc = level5Loc
          break
        }
      }
    }
    return i_loc
  } else {
    if (menuContentsItem.value != undefined) {
      const index = menuContentsItem.value.findIndex((menu) => {
        return menu.id == menuId
      })
      if (menuContentsItem.value && index > -1) {
        //부모 li를 열지 않고 offsetTop을 찾으면 0값 리턴됩니다
        return menuContentsItem.value[index].offsetTop
      }
    }
  }
  return 0
}

/** 메뉴 닫기/열기 */
const handleExpendMenu = (menu: MenuItemModel) => {
  const hasItem = expandedItems.some((item: string) => item === menu.id)
  if (!hasItem) {
    expandedItems.push(menu.id ?? '') //열고
  } else {
    //삭제 하고 닫는다.
    const index = expandedItems.findIndex((x) => x == menu.id)
    if (index > -1) {
      expandedItems.splice(index, 1)
    }
  }
}
/** 부모 li를 연다 */
const handleParentExpand = (menuHistory: string[]) => {
  menuHistory.forEach((item) => {
    const index = expandedItems.findIndex((x) => x == item)
    if (index == -1) {
      expandedItems.push(item) //배열에 넣고 연다
    }
  })
}

const depthClass = (i: MenuItemModel) => {
  return `depth${props.depth} ${toggleActive(i.children?.length ?? 0, i)} ${
    props.keyword != '' ? (i.name && i.name.indexOf(props.keyword) > -1 ? 'search_word' : '') : ''
  }`
}

defineExpose({
  GetOffsetI,
  handleParentExpand
})
</script>

<style scoped>
.depth5 {
  background-color: #f4f4f6; /** depth5이상 배경색 주기 현재gnb의 gnb.css와 v1의 최신 gnb.css가 달라서 추가 */
}
</style>
