<template>
  <div class="gwp_default">
    <ul id="bookmarkbox" ref="sortableEl" class="bookmarkbox ui-sortable">
      <li v-for="item in state.bookmarkList" :key="item.id" class="bookmarkitem ui-sortable-handle">
        <span v-if="!item.isEditMode" class="bookmarkname" @dblclick="handleBookmarkNameDoubleClick(item.id)">
          {{ item.name }}
        </span>
        <input
          v-else
          type="text"
          class="bookmarkname-input"
          :value="item.name"
          style="height: 21px"
          spellcheck="false"
          @keypress.enter.prevent="handleEnterKeydown"
          @blur="handleBookmarkNameBlur(item.id)" />
        <a href="javascript:void(0);" role="button" unselectable="on" @click.prevent="handleDeleteBookmark(item.id)">
          <span class="ico_popup ico_popup_display_close">&nbsp;</span>
        </a>
      </li>
    </ul>

    <div class="bookmarkdescription">
      <ul>
        <li>- {{ $t('FavoriteDescription1') }}</li>
        <li>- {{ $t('FavoriteDescription2') }}</li>
        <li>- {{ $t('FavoriteDescription3') }}</li>
        <li>
          <b class="point_text">- {{ $t('FavoriteDescription4') }}</b>
        </li>
      </ul>
    </div>
  </div>

  <!--popup 버튼-->
  <div class="sect_button">
    <div class="left_area"></div>
    <div class="center_area">
      <button class="btn_type_a btn_color_04" @click.prevent="handleSaveClick">
        <span class="btn_txt">{{ $t('Save') }}</span>
      </button>
      <button class="btn_type_a btn_color_03" @click.prevent="handleCancelClick">
        <span class="btn_txt">{{ $t('Cancel') }}</span>
      </button>
    </div>
    <div class="right_area"></div>
  </div>
  <!--//popup버튼-->
</template>

<script setup lang="ts">
import { ref, reactive, onMounted, watch } from 'vue'
import Sortable, { SortableEvent } from 'sortablejs'
import { BookmarkModel } from 'mik-common/src/api-autogen/globalmenu'
import { BookmarkApi } from 'mik-common/src/api-autogen/globalmenu'
// import { useToast } from '@gnb/common/toast'
// const toast = useToast()
const api = new BookmarkApi()
interface Props {
  itemList?: BookmarkModel[]
}

const props = withDefaults(defineProps<Props>(), {
  itemList: undefined
})

const sortableEl = ref<HTMLUListElement | null>(null)
/** Bookmark List */
const state = reactive({
  bookmarkList: props.itemList as Bookmark[]
})
/** Deleted BookmarkList */
const deletedList = [] as Bookmark[]

const { t } = useI18n()

/** Lifecycle Hook */
/** onMounted */
onMounted(() => {
  /** Sortable */
  if (sortableEl.value) {
    Sortable.create(sortableEl.value, {
      ghostClass: 'ghost-dragging',
      onEnd: (event: SortableEvent) => {
        if (event.oldIndex != null && event.newIndex != null && state.bookmarkList) {
          const newItemList = state.bookmarkList
          const item = newItemList.splice(event.oldIndex, 1)[0]
          newItemList.splice(event.newIndex, 0, item)
          // props.itemList = newItemList;
          emits('update:item-list', newItemList)
        }
      }
    })
  }
})
/** watch */
watch(
  () => props.itemList,
  (newVal, oldVal) => {
    if (newVal) state.bookmarkList = newVal
  }
)
/** 제목 변경을 위한 DblClick */
const handleBookmarkNameDoubleClick = (id: string | undefined | null) => {
  if (state.bookmarkList) {
    state.bookmarkList.forEach((item: Bookmark) => {
      if (item.id === id) {
        item.isEditMode = !item.isEditMode
      }
    })

    setTimeout(() => {
      if (sortableEl.value) {
        const input = sortableEl.value.querySelectorAll('.bookmarkname-input')[0] as HTMLElement
        input.focus()
      }
    }, 10)
  }
}
/** 제목 변경 후 blur */
const handleBookmarkNameBlur = (id: string | undefined | null) => {
  if (sortableEl.value && state.bookmarkList) {
    const input = sortableEl.value.querySelectorAll('.bookmarkname-input')[0] as HTMLInputElement

    state.bookmarkList.forEach((item) => {
      if (item.id === id) {
        item.name = input.value
      }
      item.isEditMode = false
    })
  }
}
/** blur when enter pressed */
const handleEnterKeydown = () => {
  if (sortableEl.value) {
    const input = sortableEl.value.querySelectorAll('.bookmarkname-input')[0] as HTMLElement
    input.blur()
  }
}
/** Delete */
const handleDeleteBookmark = (id: string | undefined | null) => {
  if (state.bookmarkList) {
    const newItemList = state.bookmarkList
    const index = newItemList.findIndex((item) => item.id === id)
    deletedList.push(newItemList[index])
    if (index !== -1) {
      newItemList.splice(index, 1)
    }
    // bookmarkList = newItemList;
    emits('update:item-list', newItemList)
  }
}
/** Confirm */
const handleSaveClick = async () => {
  /** Delete */
  let delCall = 0
  let updateCall = 0
  let isEnd = false
  if (deletedList.length == 0) {
    delCall = 1
    if (delCall + updateCall == 2 && !isEnd) {
      isEnd = true
      // toast.info(`${t('SaveSuccess')}`)
      emits('on-hide-popup')
    }
  } else {
    const delList = deletedList.map((a) => a.id ?? '')
    await api
      .v1BookmarkDeleteListPost(delList)
      .then((responses) => {
        delCall = 1
        if (delCall + updateCall == 2 && !isEnd) {
          isEnd = true
          // toast.info(`${t('SaveSuccess')}`)
          emits('on-hide-popup')
        }

        console.log(responses)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  /** Sort Update */
  /** 북마크 목록 조회 시 Desc 정렬 조회기 때문에 reverse 처리 */
  if (props.itemList) {
    const updatedList = props.itemList
      .filter((item: Bookmark) => {
        return !deletedList.some((deleteItem) => {
          item.id === deleteItem.id
        })
      })
      .reverse()
    if (updatedList.length == 0) {
      updateCall = 1
      if (delCall + updateCall == 2 && !isEnd) {
        isEnd = true
        // toast.info(`${t('SaveSuccess')}`)
        emits('on-hide-popup')
      }
    } else {
      updatedList.map((item: Bookmark, index: number) => {
        item.sortOrder = index
      })

      await api
        .v1BookmarkUpdateListPost(updatedList)
        .then((responses) => {
          updateCall = 1
          if (delCall + updateCall == 2 && !isEnd) {
            isEnd = true
            // toast.info(`${t('SaveSuccess')}`)
            emits('on-hide-popup')
          }
          console.log(responses)
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          emits('on-hide-popup')
        })
    }
  }
}

/** Cancel */
const handleCancelClick = () => {
  // toast.info(`${t('CancelButton')}`)
  emits('on-hide-popup')
}

const emits = defineEmits<{ (e: 'update:item-list', value: Bookmark[]): void; (e: 'on-hide-popup'): void }>()

interface Bookmark extends BookmarkModel {
  isEditMode?: Boolean
}
</script>

<style scoped>
.ghost-dragging {
  background-color: rgb(192, 192, 192);
}

.ghost-dragging span {
  color: rgb(255, 255, 255);
}

.bookmarkbox {
  list-style-type: none;
  margin: 0;
  padding: 7px;
  height: 334px;
  overflow-y: auto;
  overflow-x: hidden;
  border: #d3d3d3 1px solid;
  position: relative;
  z-index: 99999;
}

.bookmarkbox li {
  /*
margin: 0 3px 3px 3px;
*/
  margin-bottom: 3px;
  padding: 3px;
  /*
padding-left: 3px;
padding-right: 3px;
font-size: 13px;
  height: 34px;*/
  text-align: left;
  padding-left: 10px;
}

.bookmarkbox li span {
  padding-top: 2px;
  padding-bottom: 2px;
  /* cursor: hand; */
}

.bookmarkitem {
  position: relative;
  border: #d3d3d3 1px solid;
  cursor: pointer;
}

.bookmarkname {
  font-weight: bold;
  color: #555555;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 317px;
}

.bookmarkitem form {
  margin: 0;
  padding: 0;
}

.bookmarkitem input {
  border: #c8c8d2 1px solid;
  background-color: #ffffff;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  height: 16px;
  margin-top: -2px;
  font-size: 13px;
}

.bookmarkitem a {
  height: 15px;
  width: 16px;
  margin: -10px 0 0;
  padding: 1px;
  position: absolute;
  right: 0.3em;
  top: 13px;
  /*color: #ffffff;*/
}

/*.bookmarkitem a span {
                background-image: url('../images/btn_delete.gif');
                background-repeat: no-repeat;
                background-position: 0px 0px;
                display: block;
                margin: 1px;
                height: 14px;
                width: 14px;
            }*/

.bookmarkitemplaceholder {
  /* border: 1px solid #fcefa1;
background: #fbf9ee;
color: #363636; */
  border: 1px solid #dee1e9;
  background-color: #f2f3f6;
}

.bookmarkdescription {
  padding: 5px;
  /* color: #A0A0AA; */
  color: #5a5a64;
}

.bookmarkdescription ul {
  overflow: visible;
  position: relative;
  padding: 0px;
  text-align: left;
}

.bookmarkdescription li {
  /* background: url("../images/li.gif") no-repeat scroll 5px 5px transparent; */
  list-style: none outside none;
  margin-left: -6px !important;
  padding-left: 14px;
  vertical-align: top;
  line-height: 17px;
  position: relative;
}

.bookmarkdescription li .point_text {
  color: #df4523;
}

.bookmarkinfo {
  padding-top: 3px;
  padding-left: 5px;
  padding-bottom: 9px;
  line-height: 18px;
}

.bookmarkcontent {
  padding-top: 12px;
  border-top: 1px solid #d2d2dc;
}

.bookmarkcontent .td1 {
  width: 129px;
  border-top: 1px solid #cbcbd4;
  border-bottom: 1px solid #d2d2dc;
  padding-top: 7px;
  padding-bottom: 5px;
  background: #f0f0f6;
  padding-left: 15px;
}

.bookmarkcontent .td2 {
  border-top: 1px solid #d2d2dc;
  border-bottom: 1px solid #d2d2dc;
  padding-top: 7px;
  padding-bottom: 5px;
  padding-left: 9px;
}

.center_area button {
  margin-right: 10px !important;
}
</style>
