<template>
  <div class="gwp_header_shrt_menu gwp_header_lang slidedown brder-left">
    <button
      :title="$t('Language')"
      class="gwp_btn_lang btn_slidedown gwp_lang_anchor"
      @click.prevent="handleLanguageButtonClick">
      <span style="margin-top: 0px">{{ computedLangDisplay }}</span>
      <div class="menu_txt">{{ $t('Language') }}</div>
    </button>
    <div class="gwp_box_slidedown_wrap">
      <slide-down>
        <ul v-show="isLanguageListVisible" class="gwp_lang_list box_slidedown" style="display: block">
          <li key="ko" :class="`gwp_lang_item ${lang === 'ko' ? 'active' : ''}`">
            <a href="" class="gwp_lang_anchor" @click.prevent="handleLanguageChange('ko')">KOR</a>
          </li>
          <li key="en" :class="`gwp_lang_item ${lang === 'en' ? 'active' : ''}`">
            <a href="" class="gwp_lang_anchor" @click.prevent="handleLanguageChange('en')">ENG</a>
          </li>
          <li key="zh" :class="`gwp_lang_item ${lang === 'zh' ? 'active' : ''}`">
            <a href="" class="gwp_lang_anchor" @click.prevent="handleLanguageChange('zh')">CHN</a>
          </li>
        </ul>
      </slide-down>
    </div>
  </div>
</template>

<script lang="ts" setup>
import SlideDown from '@gnb/components/animations/SlideDown.vue'
interface Props {
  lang: string
}
const props = withDefaults(defineProps<Props>(), {
  lang: 'ko'
})

const isLanguageListVisible = ref(false)
const langTitle = ref('')
langTitle.value = langTitle.value ? langTitle.value : props.lang

const computedLangDisplay = computed(() => {
  switch (langTitle.value) {
    case 'ko': {
      return 'KOR'
    }
    case 'en': {
      return 'ENG'
    }
    case 'zh': {
      return 'CHN'
    }
    default: {
      return 'ERR'
    }
  }
})
const emit = defineEmits(['on-language-change'])
const handleDocumentClick = (e: Event): void => {
  if (e == undefined || e == null) {
    return
  }
  /**
   * 해당 컴포넌트 외부에서 클릭이 되었을 경우, drop down을 닫기위한 기능 연결
   */
  // if (!this.$el.contains(e.target as HTMLElement)) {
  //   isLanguageListVisible.value = false
  // }
}
const handleLanguageButtonClick = (): void => {
  isLanguageListVisible.value = !isLanguageListVisible.value
}

const handleLanguageChange = (code: string): void => {
  emit('on-language-change', code)
  isLanguageListVisible.value = false
  langTitle.value = code
}
onMounted(() => {
  document.addEventListener('click', handleDocumentClick)
})

onBeforeMount(() => {
  document.removeEventListener('click', handleDocumentClick)
})
</script>
