/* tslint:disable */
/* eslint-disable */
/**
 * Mik.SmartPortal.NotificationCenter.App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import type { RequestArgs } from '../base';
// @ts-ignore
import type { ClearMessageRequestEntity } from '../models';
// @ts-ignore
import type { ClearMessageResponseEntity } from '../models';
// @ts-ignore
import type { ItemListModelApiResult } from '../models';
// @ts-ignore
import type { MessageReceiverInfoEntity } from '../models';
// @ts-ignore
import type { PushMessagePacketForApiEntity } from '../models';
// @ts-ignore
import type { PushMessageRequestModel } from '../models';
// @ts-ignore
import type { PushMessageResponseEntity } from '../models';
// @ts-ignore
import type { RelayMessagePacketForApiEntity } from '../models';
// @ts-ignore
import type { SimpleMessageRequestEntity } from '../models';
// @ts-ignore
import type { SimpleMessageResponseEntity } from '../models';
/**
 * MessagesApi - axios parameter creator
 * @export
 */
export const MessagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [userAccpetkey] 
         * @param {string} [acceptkey] 
         * @param {boolean} [isDelete] 
         * @param {boolean} [_delete] 
         * @param {number} [idx] 
         * @param {number} [index] 
         * @param {string} [pushTimestamp] 
         * @param {string} [timestamp] 
         * @param {string} [jsonData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesGetClearMessageGet: async (userAccpetkey?: string, acceptkey?: string, isDelete?: boolean, _delete?: boolean, idx?: number, index?: number, pushTimestamp?: string, timestamp?: string, jsonData?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Messages/GetClearMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userAccpetkey !== undefined) {
                localVarQueryParameter['UserAccpetkey'] = userAccpetkey;
            }

            if (acceptkey !== undefined) {
                localVarQueryParameter['acceptkey'] = acceptkey;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }

            if (_delete !== undefined) {
                localVarQueryParameter['delete'] = _delete;
            }

            if (idx !== undefined) {
                localVarQueryParameter['Idx'] = idx;
            }

            if (index !== undefined) {
                localVarQueryParameter['index'] = index;
            }

            if (pushTimestamp !== undefined) {
                localVarQueryParameter['PushTimestamp'] = (pushTimestamp as any instanceof Date) ?
                    (pushTimestamp as any).toISOString() :
                    pushTimestamp;
            }

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = (timestamp as any instanceof Date) ?
                    (timestamp as any).toISOString() :
                    timestamp;
            }

            if (jsonData !== undefined) {
                localVarQueryParameter['JsonData'] = jsonData;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [availableMessage] 
         * @param {boolean} [isRead] 
         * @param {number} [idx] 
         * @param {number} [index] 
         * @param {{ [key: string]: number; }} [indexByReceiverMap] 
         * @param {{ [key: string]: number; }} [indexmap] 
         * @param {string} [systemAcceptKey] 
         * @param {string} [acceptkey] 
         * @param {string} [systemId] 
         * @param {string} [system] 
         * @param {Array<MessageReceiverInfoEntity>} [receiverList] 
         * @param {Array<MessageReceiverInfoEntity>} [receivers] 
         * @param {{ [key: string]: string; }} [messageTitleTextMap] 
         * @param {{ [key: string]: string; }} [messageTitleText] 
         * @param {string} [messageTitleJsonData] 
         * @param {{ [key: string]: string; }} [title] 
         * @param {{ [key: string]: string; }} [messageMemoTextMap] 
         * @param {{ [key: string]: string; }} [messageMemoText] 
         * @param {string} [messageMemoJsonData] 
         * @param {{ [key: string]: string; }} [memo] 
         * @param {{ [key: string]: string; }} [referenceUrlTextMap] 
         * @param {{ [key: string]: string; }} [referenceUrlText] 
         * @param {string} [referenceUrlJsonData] 
         * @param {{ [key: string]: string; }} [url] 
         * @param {{ [key: string]: string; }} [linkOptionMap] 
         * @param {string} [linkOptionJson] 
         * @param {{ [key: string]: string; }} [linkoption] 
         * @param {string} [firedTime] 
         * @param {string} [fired] 
         * @param {string} [observedTime] 
         * @param {string} [observed] 
         * @param {{ [key: string]: string; }} [extendedData] 
         * @param {string} [extendedDataJson] 
         * @param {{ [key: string]: string; }} [exdata] 
         * @param {string} [currentTitle] 
         * @param {string} [currentMemo] 
         * @param {string} [currentUrl] 
         * @param {string} [currentFired] 
         * @param {string} [currentObserved] 
         * @param {string} [pushTimestamp] 
         * @param {string} [timestamp] 
         * @param {string} [jsonData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesGetPushMessageGet: async (availableMessage?: boolean, isRead?: boolean, idx?: number, index?: number, indexByReceiverMap?: { [key: string]: number; }, indexmap?: { [key: string]: number; }, systemAcceptKey?: string, acceptkey?: string, systemId?: string, system?: string, receiverList?: Array<MessageReceiverInfoEntity>, receivers?: Array<MessageReceiverInfoEntity>, messageTitleTextMap?: { [key: string]: string; }, messageTitleText?: { [key: string]: string; }, messageTitleJsonData?: string, title?: { [key: string]: string; }, messageMemoTextMap?: { [key: string]: string; }, messageMemoText?: { [key: string]: string; }, messageMemoJsonData?: string, memo?: { [key: string]: string; }, referenceUrlTextMap?: { [key: string]: string; }, referenceUrlText?: { [key: string]: string; }, referenceUrlJsonData?: string, url?: { [key: string]: string; }, linkOptionMap?: { [key: string]: string; }, linkOptionJson?: string, linkoption?: { [key: string]: string; }, firedTime?: string, fired?: string, observedTime?: string, observed?: string, extendedData?: { [key: string]: string; }, extendedDataJson?: string, exdata?: { [key: string]: string; }, currentTitle?: string, currentMemo?: string, currentUrl?: string, currentFired?: string, currentObserved?: string, pushTimestamp?: string, timestamp?: string, jsonData?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/messages/GetPushMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (availableMessage !== undefined) {
                localVarQueryParameter['AvailableMessage'] = availableMessage;
            }

            if (isRead !== undefined) {
                localVarQueryParameter['IsRead'] = isRead;
            }

            if (idx !== undefined) {
                localVarQueryParameter['Idx'] = idx;
            }

            if (index !== undefined) {
                localVarQueryParameter['index'] = index;
            }

            if (indexByReceiverMap !== undefined) {
                localVarQueryParameter['IndexByReceiverMap'] = indexByReceiverMap;
            }

            if (indexmap !== undefined) {
                localVarQueryParameter['indexmap'] = indexmap;
            }

            if (systemAcceptKey !== undefined) {
                localVarQueryParameter['SystemAcceptKey'] = systemAcceptKey;
            }

            if (acceptkey !== undefined) {
                localVarQueryParameter['acceptkey'] = acceptkey;
            }

            if (systemId !== undefined) {
                localVarQueryParameter['SystemId'] = systemId;
            }

            if (system !== undefined) {
                localVarQueryParameter['system'] = system;
            }

            if (receiverList) {
                localVarQueryParameter['ReceiverList'] = receiverList;
            }

            if (receivers) {
                localVarQueryParameter['receivers'] = receivers;
            }

            if (messageTitleTextMap !== undefined) {
                localVarQueryParameter['MessageTitle.TextMap'] = messageTitleTextMap;
            }

            if (messageTitleText !== undefined) {
                localVarQueryParameter['MessageTitle.text'] = messageTitleText;
            }

            if (messageTitleJsonData !== undefined) {
                localVarQueryParameter['MessageTitle.JsonData'] = messageTitleJsonData;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (messageMemoTextMap !== undefined) {
                localVarQueryParameter['MessageMemo.TextMap'] = messageMemoTextMap;
            }

            if (messageMemoText !== undefined) {
                localVarQueryParameter['MessageMemo.text'] = messageMemoText;
            }

            if (messageMemoJsonData !== undefined) {
                localVarQueryParameter['MessageMemo.JsonData'] = messageMemoJsonData;
            }

            if (memo !== undefined) {
                localVarQueryParameter['memo'] = memo;
            }

            if (referenceUrlTextMap !== undefined) {
                localVarQueryParameter['ReferenceUrl.TextMap'] = referenceUrlTextMap;
            }

            if (referenceUrlText !== undefined) {
                localVarQueryParameter['ReferenceUrl.text'] = referenceUrlText;
            }

            if (referenceUrlJsonData !== undefined) {
                localVarQueryParameter['ReferenceUrl.JsonData'] = referenceUrlJsonData;
            }

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }

            if (linkOptionMap !== undefined) {
                localVarQueryParameter['LinkOptionMap'] = linkOptionMap;
            }

            if (linkOptionJson !== undefined) {
                localVarQueryParameter['LinkOptionJson'] = linkOptionJson;
            }

            if (linkoption !== undefined) {
                localVarQueryParameter['linkoption'] = linkoption;
            }

            if (firedTime !== undefined) {
                localVarQueryParameter['FiredTime'] = (firedTime as any instanceof Date) ?
                    (firedTime as any).toISOString() :
                    firedTime;
            }

            if (fired !== undefined) {
                localVarQueryParameter['fired'] = (fired as any instanceof Date) ?
                    (fired as any).toISOString() :
                    fired;
            }

            if (observedTime !== undefined) {
                localVarQueryParameter['ObservedTime'] = (observedTime as any instanceof Date) ?
                    (observedTime as any).toISOString() :
                    observedTime;
            }

            if (observed !== undefined) {
                localVarQueryParameter['observed'] = (observed as any instanceof Date) ?
                    (observed as any).toISOString() :
                    observed;
            }

            if (extendedData !== undefined) {
                localVarQueryParameter['ExtendedData'] = extendedData;
            }

            if (extendedDataJson !== undefined) {
                localVarQueryParameter['ExtendedDataJson'] = extendedDataJson;
            }

            if (exdata !== undefined) {
                localVarQueryParameter['exdata'] = exdata;
            }

            if (currentTitle !== undefined) {
                localVarQueryParameter['currentTitle'] = currentTitle;
            }

            if (currentMemo !== undefined) {
                localVarQueryParameter['currentMemo'] = currentMemo;
            }

            if (currentUrl !== undefined) {
                localVarQueryParameter['currentUrl'] = currentUrl;
            }

            if (currentFired !== undefined) {
                localVarQueryParameter['currentFired'] = currentFired;
            }

            if (currentObserved !== undefined) {
                localVarQueryParameter['currentObserved'] = currentObserved;
            }

            if (pushTimestamp !== undefined) {
                localVarQueryParameter['PushTimestamp'] = (pushTimestamp as any instanceof Date) ?
                    (pushTimestamp as any).toISOString() :
                    pushTimestamp;
            }

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = (timestamp as any instanceof Date) ?
                    (timestamp as any).toISOString() :
                    timestamp;
            }

            if (jsonData !== undefined) {
                localVarQueryParameter['JsonData'] = jsonData;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userAccpetkey] 
         * @param {string} [acceptkey] 
         * @param {string} [jsonData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesGetRefreshMessageGet: async (userAccpetkey?: string, acceptkey?: string, jsonData?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/messages/GetRefreshMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userAccpetkey !== undefined) {
                localVarQueryParameter['UserAccpetkey'] = userAccpetkey;
            }

            if (acceptkey !== undefined) {
                localVarQueryParameter['acceptkey'] = acceptkey;
            }

            if (jsonData !== undefined) {
                localVarQueryParameter['JsonData'] = jsonData;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [availableMessage] 
         * @param {boolean} [isRead] 
         * @param {number} [idx] 
         * @param {number} [index] 
         * @param {{ [key: string]: number; }} [indexByReceiverMap] 
         * @param {{ [key: string]: number; }} [indexmap] 
         * @param {string} [systemAcceptKey] 
         * @param {string} [acceptkey] 
         * @param {string} [systemId] 
         * @param {string} [system] 
         * @param {Array<MessageReceiverInfoEntity>} [receiverList] 
         * @param {Array<MessageReceiverInfoEntity>} [receivers] 
         * @param {{ [key: string]: string; }} [messageTitleTextMap] 
         * @param {{ [key: string]: string; }} [messageTitleText] 
         * @param {string} [messageTitleJsonData] 
         * @param {{ [key: string]: string; }} [title] 
         * @param {{ [key: string]: string; }} [messageMemoTextMap] 
         * @param {{ [key: string]: string; }} [messageMemoText] 
         * @param {string} [messageMemoJsonData] 
         * @param {{ [key: string]: string; }} [memo] 
         * @param {{ [key: string]: string; }} [referenceUrlTextMap] 
         * @param {{ [key: string]: string; }} [referenceUrlText] 
         * @param {string} [referenceUrlJsonData] 
         * @param {{ [key: string]: string; }} [url] 
         * @param {{ [key: string]: string; }} [linkOptionMap] 
         * @param {string} [linkOptionJson] 
         * @param {{ [key: string]: string; }} [linkoption] 
         * @param {string} [firedTime] 
         * @param {string} [fired] 
         * @param {string} [observedTime] 
         * @param {string} [observed] 
         * @param {{ [key: string]: string; }} [extendedData] 
         * @param {string} [extendedDataJson] 
         * @param {{ [key: string]: string; }} [exdata] 
         * @param {string} [currentTitle] 
         * @param {string} [currentMemo] 
         * @param {string} [currentUrl] 
         * @param {string} [currentFired] 
         * @param {string} [currentObserved] 
         * @param {string} [pushTimestamp] 
         * @param {string} [timestamp] 
         * @param {string} [jsonData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesGetRelayMessageGet: async (availableMessage?: boolean, isRead?: boolean, idx?: number, index?: number, indexByReceiverMap?: { [key: string]: number; }, indexmap?: { [key: string]: number; }, systemAcceptKey?: string, acceptkey?: string, systemId?: string, system?: string, receiverList?: Array<MessageReceiverInfoEntity>, receivers?: Array<MessageReceiverInfoEntity>, messageTitleTextMap?: { [key: string]: string; }, messageTitleText?: { [key: string]: string; }, messageTitleJsonData?: string, title?: { [key: string]: string; }, messageMemoTextMap?: { [key: string]: string; }, messageMemoText?: { [key: string]: string; }, messageMemoJsonData?: string, memo?: { [key: string]: string; }, referenceUrlTextMap?: { [key: string]: string; }, referenceUrlText?: { [key: string]: string; }, referenceUrlJsonData?: string, url?: { [key: string]: string; }, linkOptionMap?: { [key: string]: string; }, linkOptionJson?: string, linkoption?: { [key: string]: string; }, firedTime?: string, fired?: string, observedTime?: string, observed?: string, extendedData?: { [key: string]: string; }, extendedDataJson?: string, exdata?: { [key: string]: string; }, currentTitle?: string, currentMemo?: string, currentUrl?: string, currentFired?: string, currentObserved?: string, pushTimestamp?: string, timestamp?: string, jsonData?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/messages/GetRelayMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (availableMessage !== undefined) {
                localVarQueryParameter['AvailableMessage'] = availableMessage;
            }

            if (isRead !== undefined) {
                localVarQueryParameter['IsRead'] = isRead;
            }

            if (idx !== undefined) {
                localVarQueryParameter['Idx'] = idx;
            }

            if (index !== undefined) {
                localVarQueryParameter['index'] = index;
            }

            if (indexByReceiverMap !== undefined) {
                localVarQueryParameter['IndexByReceiverMap'] = indexByReceiverMap;
            }

            if (indexmap !== undefined) {
                localVarQueryParameter['indexmap'] = indexmap;
            }

            if (systemAcceptKey !== undefined) {
                localVarQueryParameter['SystemAcceptKey'] = systemAcceptKey;
            }

            if (acceptkey !== undefined) {
                localVarQueryParameter['acceptkey'] = acceptkey;
            }

            if (systemId !== undefined) {
                localVarQueryParameter['SystemId'] = systemId;
            }

            if (system !== undefined) {
                localVarQueryParameter['system'] = system;
            }

            if (receiverList) {
                localVarQueryParameter['ReceiverList'] = receiverList;
            }

            if (receivers) {
                localVarQueryParameter['receivers'] = receivers;
            }

            if (messageTitleTextMap !== undefined) {
                localVarQueryParameter['MessageTitle.TextMap'] = messageTitleTextMap;
            }

            if (messageTitleText !== undefined) {
                localVarQueryParameter['MessageTitle.text'] = messageTitleText;
            }

            if (messageTitleJsonData !== undefined) {
                localVarQueryParameter['MessageTitle.JsonData'] = messageTitleJsonData;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (messageMemoTextMap !== undefined) {
                localVarQueryParameter['MessageMemo.TextMap'] = messageMemoTextMap;
            }

            if (messageMemoText !== undefined) {
                localVarQueryParameter['MessageMemo.text'] = messageMemoText;
            }

            if (messageMemoJsonData !== undefined) {
                localVarQueryParameter['MessageMemo.JsonData'] = messageMemoJsonData;
            }

            if (memo !== undefined) {
                localVarQueryParameter['memo'] = memo;
            }

            if (referenceUrlTextMap !== undefined) {
                localVarQueryParameter['ReferenceUrl.TextMap'] = referenceUrlTextMap;
            }

            if (referenceUrlText !== undefined) {
                localVarQueryParameter['ReferenceUrl.text'] = referenceUrlText;
            }

            if (referenceUrlJsonData !== undefined) {
                localVarQueryParameter['ReferenceUrl.JsonData'] = referenceUrlJsonData;
            }

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }

            if (linkOptionMap !== undefined) {
                localVarQueryParameter['LinkOptionMap'] = linkOptionMap;
            }

            if (linkOptionJson !== undefined) {
                localVarQueryParameter['LinkOptionJson'] = linkOptionJson;
            }

            if (linkoption !== undefined) {
                localVarQueryParameter['linkoption'] = linkoption;
            }

            if (firedTime !== undefined) {
                localVarQueryParameter['FiredTime'] = (firedTime as any instanceof Date) ?
                    (firedTime as any).toISOString() :
                    firedTime;
            }

            if (fired !== undefined) {
                localVarQueryParameter['fired'] = (fired as any instanceof Date) ?
                    (fired as any).toISOString() :
                    fired;
            }

            if (observedTime !== undefined) {
                localVarQueryParameter['ObservedTime'] = (observedTime as any instanceof Date) ?
                    (observedTime as any).toISOString() :
                    observedTime;
            }

            if (observed !== undefined) {
                localVarQueryParameter['observed'] = (observed as any instanceof Date) ?
                    (observed as any).toISOString() :
                    observed;
            }

            if (extendedData !== undefined) {
                localVarQueryParameter['ExtendedData'] = extendedData;
            }

            if (extendedDataJson !== undefined) {
                localVarQueryParameter['ExtendedDataJson'] = extendedDataJson;
            }

            if (exdata !== undefined) {
                localVarQueryParameter['exdata'] = exdata;
            }

            if (currentTitle !== undefined) {
                localVarQueryParameter['currentTitle'] = currentTitle;
            }

            if (currentMemo !== undefined) {
                localVarQueryParameter['currentMemo'] = currentMemo;
            }

            if (currentUrl !== undefined) {
                localVarQueryParameter['currentUrl'] = currentUrl;
            }

            if (currentFired !== undefined) {
                localVarQueryParameter['currentFired'] = currentFired;
            }

            if (currentObserved !== undefined) {
                localVarQueryParameter['currentObserved'] = currentObserved;
            }

            if (pushTimestamp !== undefined) {
                localVarQueryParameter['PushTimestamp'] = (pushTimestamp as any instanceof Date) ?
                    (pushTimestamp as any).toISOString() :
                    pushTimestamp;
            }

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = (timestamp as any instanceof Date) ?
                    (timestamp as any).toISOString() :
                    timestamp;
            }

            if (jsonData !== undefined) {
                localVarQueryParameter['JsonData'] = jsonData;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 사용자ID 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesGetUserIDGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/messages/GetUserID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 알림목록 조회
         * @param {string} [keyword] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesItemListGet: async (keyword?: string, pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/messages/ItemList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ClearMessageRequestEntity} [clearMessageRequestEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesPostClearMessagePost: async (clearMessageRequestEntity?: ClearMessageRequestEntity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Messages/PostClearMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clearMessageRequestEntity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PushMessagePacketForApiEntity} [pushMessagePacketForApiEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesPostPushMessagePost: async (pushMessagePacketForApiEntity?: PushMessagePacketForApiEntity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/messages/PostPushMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pushMessagePacketForApiEntity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SimpleMessageRequestEntity} [simpleMessageRequestEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesPostRefreshMessagePost: async (simpleMessageRequestEntity?: SimpleMessageRequestEntity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/messages/PostRefreshMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(simpleMessageRequestEntity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RelayMessagePacketForApiEntity} [relayMessagePacketForApiEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesPostRelayMessagePost: async (relayMessagePacketForApiEntity?: RelayMessagePacketForApiEntity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/messages/PostRelayMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(relayMessagePacketForApiEntity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PushMessageRequestModel} [pushMessageRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesPushMessagePost: async (pushMessageRequestModel?: PushMessageRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/messages/PushMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pushMessageRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessagesApi - functional programming interface
 * @export
 */
export const MessagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [userAccpetkey] 
         * @param {string} [acceptkey] 
         * @param {boolean} [isDelete] 
         * @param {boolean} [_delete] 
         * @param {number} [idx] 
         * @param {number} [index] 
         * @param {string} [pushTimestamp] 
         * @param {string} [timestamp] 
         * @param {string} [jsonData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagesGetClearMessageGet(userAccpetkey?: string, acceptkey?: string, isDelete?: boolean, _delete?: boolean, idx?: number, index?: number, pushTimestamp?: string, timestamp?: string, jsonData?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClearMessageResponseEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagesGetClearMessageGet(userAccpetkey, acceptkey, isDelete, _delete, idx, index, pushTimestamp, timestamp, jsonData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [availableMessage] 
         * @param {boolean} [isRead] 
         * @param {number} [idx] 
         * @param {number} [index] 
         * @param {{ [key: string]: number; }} [indexByReceiverMap] 
         * @param {{ [key: string]: number; }} [indexmap] 
         * @param {string} [systemAcceptKey] 
         * @param {string} [acceptkey] 
         * @param {string} [systemId] 
         * @param {string} [system] 
         * @param {Array<MessageReceiverInfoEntity>} [receiverList] 
         * @param {Array<MessageReceiverInfoEntity>} [receivers] 
         * @param {{ [key: string]: string; }} [messageTitleTextMap] 
         * @param {{ [key: string]: string; }} [messageTitleText] 
         * @param {string} [messageTitleJsonData] 
         * @param {{ [key: string]: string; }} [title] 
         * @param {{ [key: string]: string; }} [messageMemoTextMap] 
         * @param {{ [key: string]: string; }} [messageMemoText] 
         * @param {string} [messageMemoJsonData] 
         * @param {{ [key: string]: string; }} [memo] 
         * @param {{ [key: string]: string; }} [referenceUrlTextMap] 
         * @param {{ [key: string]: string; }} [referenceUrlText] 
         * @param {string} [referenceUrlJsonData] 
         * @param {{ [key: string]: string; }} [url] 
         * @param {{ [key: string]: string; }} [linkOptionMap] 
         * @param {string} [linkOptionJson] 
         * @param {{ [key: string]: string; }} [linkoption] 
         * @param {string} [firedTime] 
         * @param {string} [fired] 
         * @param {string} [observedTime] 
         * @param {string} [observed] 
         * @param {{ [key: string]: string; }} [extendedData] 
         * @param {string} [extendedDataJson] 
         * @param {{ [key: string]: string; }} [exdata] 
         * @param {string} [currentTitle] 
         * @param {string} [currentMemo] 
         * @param {string} [currentUrl] 
         * @param {string} [currentFired] 
         * @param {string} [currentObserved] 
         * @param {string} [pushTimestamp] 
         * @param {string} [timestamp] 
         * @param {string} [jsonData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagesGetPushMessageGet(availableMessage?: boolean, isRead?: boolean, idx?: number, index?: number, indexByReceiverMap?: { [key: string]: number; }, indexmap?: { [key: string]: number; }, systemAcceptKey?: string, acceptkey?: string, systemId?: string, system?: string, receiverList?: Array<MessageReceiverInfoEntity>, receivers?: Array<MessageReceiverInfoEntity>, messageTitleTextMap?: { [key: string]: string; }, messageTitleText?: { [key: string]: string; }, messageTitleJsonData?: string, title?: { [key: string]: string; }, messageMemoTextMap?: { [key: string]: string; }, messageMemoText?: { [key: string]: string; }, messageMemoJsonData?: string, memo?: { [key: string]: string; }, referenceUrlTextMap?: { [key: string]: string; }, referenceUrlText?: { [key: string]: string; }, referenceUrlJsonData?: string, url?: { [key: string]: string; }, linkOptionMap?: { [key: string]: string; }, linkOptionJson?: string, linkoption?: { [key: string]: string; }, firedTime?: string, fired?: string, observedTime?: string, observed?: string, extendedData?: { [key: string]: string; }, extendedDataJson?: string, exdata?: { [key: string]: string; }, currentTitle?: string, currentMemo?: string, currentUrl?: string, currentFired?: string, currentObserved?: string, pushTimestamp?: string, timestamp?: string, jsonData?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PushMessageResponseEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagesGetPushMessageGet(availableMessage, isRead, idx, index, indexByReceiverMap, indexmap, systemAcceptKey, acceptkey, systemId, system, receiverList, receivers, messageTitleTextMap, messageTitleText, messageTitleJsonData, title, messageMemoTextMap, messageMemoText, messageMemoJsonData, memo, referenceUrlTextMap, referenceUrlText, referenceUrlJsonData, url, linkOptionMap, linkOptionJson, linkoption, firedTime, fired, observedTime, observed, extendedData, extendedDataJson, exdata, currentTitle, currentMemo, currentUrl, currentFired, currentObserved, pushTimestamp, timestamp, jsonData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [userAccpetkey] 
         * @param {string} [acceptkey] 
         * @param {string} [jsonData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagesGetRefreshMessageGet(userAccpetkey?: string, acceptkey?: string, jsonData?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleMessageResponseEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagesGetRefreshMessageGet(userAccpetkey, acceptkey, jsonData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [availableMessage] 
         * @param {boolean} [isRead] 
         * @param {number} [idx] 
         * @param {number} [index] 
         * @param {{ [key: string]: number; }} [indexByReceiverMap] 
         * @param {{ [key: string]: number; }} [indexmap] 
         * @param {string} [systemAcceptKey] 
         * @param {string} [acceptkey] 
         * @param {string} [systemId] 
         * @param {string} [system] 
         * @param {Array<MessageReceiverInfoEntity>} [receiverList] 
         * @param {Array<MessageReceiverInfoEntity>} [receivers] 
         * @param {{ [key: string]: string; }} [messageTitleTextMap] 
         * @param {{ [key: string]: string; }} [messageTitleText] 
         * @param {string} [messageTitleJsonData] 
         * @param {{ [key: string]: string; }} [title] 
         * @param {{ [key: string]: string; }} [messageMemoTextMap] 
         * @param {{ [key: string]: string; }} [messageMemoText] 
         * @param {string} [messageMemoJsonData] 
         * @param {{ [key: string]: string; }} [memo] 
         * @param {{ [key: string]: string; }} [referenceUrlTextMap] 
         * @param {{ [key: string]: string; }} [referenceUrlText] 
         * @param {string} [referenceUrlJsonData] 
         * @param {{ [key: string]: string; }} [url] 
         * @param {{ [key: string]: string; }} [linkOptionMap] 
         * @param {string} [linkOptionJson] 
         * @param {{ [key: string]: string; }} [linkoption] 
         * @param {string} [firedTime] 
         * @param {string} [fired] 
         * @param {string} [observedTime] 
         * @param {string} [observed] 
         * @param {{ [key: string]: string; }} [extendedData] 
         * @param {string} [extendedDataJson] 
         * @param {{ [key: string]: string; }} [exdata] 
         * @param {string} [currentTitle] 
         * @param {string} [currentMemo] 
         * @param {string} [currentUrl] 
         * @param {string} [currentFired] 
         * @param {string} [currentObserved] 
         * @param {string} [pushTimestamp] 
         * @param {string} [timestamp] 
         * @param {string} [jsonData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagesGetRelayMessageGet(availableMessage?: boolean, isRead?: boolean, idx?: number, index?: number, indexByReceiverMap?: { [key: string]: number; }, indexmap?: { [key: string]: number; }, systemAcceptKey?: string, acceptkey?: string, systemId?: string, system?: string, receiverList?: Array<MessageReceiverInfoEntity>, receivers?: Array<MessageReceiverInfoEntity>, messageTitleTextMap?: { [key: string]: string; }, messageTitleText?: { [key: string]: string; }, messageTitleJsonData?: string, title?: { [key: string]: string; }, messageMemoTextMap?: { [key: string]: string; }, messageMemoText?: { [key: string]: string; }, messageMemoJsonData?: string, memo?: { [key: string]: string; }, referenceUrlTextMap?: { [key: string]: string; }, referenceUrlText?: { [key: string]: string; }, referenceUrlJsonData?: string, url?: { [key: string]: string; }, linkOptionMap?: { [key: string]: string; }, linkOptionJson?: string, linkoption?: { [key: string]: string; }, firedTime?: string, fired?: string, observedTime?: string, observed?: string, extendedData?: { [key: string]: string; }, extendedDataJson?: string, exdata?: { [key: string]: string; }, currentTitle?: string, currentMemo?: string, currentUrl?: string, currentFired?: string, currentObserved?: string, pushTimestamp?: string, timestamp?: string, jsonData?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleMessageResponseEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagesGetRelayMessageGet(availableMessage, isRead, idx, index, indexByReceiverMap, indexmap, systemAcceptKey, acceptkey, systemId, system, receiverList, receivers, messageTitleTextMap, messageTitleText, messageTitleJsonData, title, messageMemoTextMap, messageMemoText, messageMemoJsonData, memo, referenceUrlTextMap, referenceUrlText, referenceUrlJsonData, url, linkOptionMap, linkOptionJson, linkoption, firedTime, fired, observedTime, observed, extendedData, extendedDataJson, exdata, currentTitle, currentMemo, currentUrl, currentFired, currentObserved, pushTimestamp, timestamp, jsonData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 사용자ID 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagesGetUserIDGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagesGetUserIDGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 알림목록 조회
         * @param {string} [keyword] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagesItemListGet(keyword?: string, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemListModelApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagesItemListGet(keyword, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ClearMessageRequestEntity} [clearMessageRequestEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagesPostClearMessagePost(clearMessageRequestEntity?: ClearMessageRequestEntity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClearMessageResponseEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagesPostClearMessagePost(clearMessageRequestEntity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PushMessagePacketForApiEntity} [pushMessagePacketForApiEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagesPostPushMessagePost(pushMessagePacketForApiEntity?: PushMessagePacketForApiEntity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PushMessageResponseEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagesPostPushMessagePost(pushMessagePacketForApiEntity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SimpleMessageRequestEntity} [simpleMessageRequestEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagesPostRefreshMessagePost(simpleMessageRequestEntity?: SimpleMessageRequestEntity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleMessageResponseEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagesPostRefreshMessagePost(simpleMessageRequestEntity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RelayMessagePacketForApiEntity} [relayMessagePacketForApiEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagesPostRelayMessagePost(relayMessagePacketForApiEntity?: RelayMessagePacketForApiEntity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleMessageResponseEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagesPostRelayMessagePost(relayMessagePacketForApiEntity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PushMessageRequestModel} [pushMessageRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMessagesPushMessagePost(pushMessageRequestModel?: PushMessageRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PushMessageResponseEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMessagesPushMessagePost(pushMessageRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessagesApi - factory interface
 * @export
 */
export const MessagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessagesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [userAccpetkey] 
         * @param {string} [acceptkey] 
         * @param {boolean} [isDelete] 
         * @param {boolean} [_delete] 
         * @param {number} [idx] 
         * @param {number} [index] 
         * @param {string} [pushTimestamp] 
         * @param {string} [timestamp] 
         * @param {string} [jsonData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesGetClearMessageGet(userAccpetkey?: string, acceptkey?: string, isDelete?: boolean, _delete?: boolean, idx?: number, index?: number, pushTimestamp?: string, timestamp?: string, jsonData?: string, options?: any): AxiosPromise<ClearMessageResponseEntity> {
            return localVarFp.apiMessagesGetClearMessageGet(userAccpetkey, acceptkey, isDelete, _delete, idx, index, pushTimestamp, timestamp, jsonData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [availableMessage] 
         * @param {boolean} [isRead] 
         * @param {number} [idx] 
         * @param {number} [index] 
         * @param {{ [key: string]: number; }} [indexByReceiverMap] 
         * @param {{ [key: string]: number; }} [indexmap] 
         * @param {string} [systemAcceptKey] 
         * @param {string} [acceptkey] 
         * @param {string} [systemId] 
         * @param {string} [system] 
         * @param {Array<MessageReceiverInfoEntity>} [receiverList] 
         * @param {Array<MessageReceiverInfoEntity>} [receivers] 
         * @param {{ [key: string]: string; }} [messageTitleTextMap] 
         * @param {{ [key: string]: string; }} [messageTitleText] 
         * @param {string} [messageTitleJsonData] 
         * @param {{ [key: string]: string; }} [title] 
         * @param {{ [key: string]: string; }} [messageMemoTextMap] 
         * @param {{ [key: string]: string; }} [messageMemoText] 
         * @param {string} [messageMemoJsonData] 
         * @param {{ [key: string]: string; }} [memo] 
         * @param {{ [key: string]: string; }} [referenceUrlTextMap] 
         * @param {{ [key: string]: string; }} [referenceUrlText] 
         * @param {string} [referenceUrlJsonData] 
         * @param {{ [key: string]: string; }} [url] 
         * @param {{ [key: string]: string; }} [linkOptionMap] 
         * @param {string} [linkOptionJson] 
         * @param {{ [key: string]: string; }} [linkoption] 
         * @param {string} [firedTime] 
         * @param {string} [fired] 
         * @param {string} [observedTime] 
         * @param {string} [observed] 
         * @param {{ [key: string]: string; }} [extendedData] 
         * @param {string} [extendedDataJson] 
         * @param {{ [key: string]: string; }} [exdata] 
         * @param {string} [currentTitle] 
         * @param {string} [currentMemo] 
         * @param {string} [currentUrl] 
         * @param {string} [currentFired] 
         * @param {string} [currentObserved] 
         * @param {string} [pushTimestamp] 
         * @param {string} [timestamp] 
         * @param {string} [jsonData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesGetPushMessageGet(availableMessage?: boolean, isRead?: boolean, idx?: number, index?: number, indexByReceiverMap?: { [key: string]: number; }, indexmap?: { [key: string]: number; }, systemAcceptKey?: string, acceptkey?: string, systemId?: string, system?: string, receiverList?: Array<MessageReceiverInfoEntity>, receivers?: Array<MessageReceiverInfoEntity>, messageTitleTextMap?: { [key: string]: string; }, messageTitleText?: { [key: string]: string; }, messageTitleJsonData?: string, title?: { [key: string]: string; }, messageMemoTextMap?: { [key: string]: string; }, messageMemoText?: { [key: string]: string; }, messageMemoJsonData?: string, memo?: { [key: string]: string; }, referenceUrlTextMap?: { [key: string]: string; }, referenceUrlText?: { [key: string]: string; }, referenceUrlJsonData?: string, url?: { [key: string]: string; }, linkOptionMap?: { [key: string]: string; }, linkOptionJson?: string, linkoption?: { [key: string]: string; }, firedTime?: string, fired?: string, observedTime?: string, observed?: string, extendedData?: { [key: string]: string; }, extendedDataJson?: string, exdata?: { [key: string]: string; }, currentTitle?: string, currentMemo?: string, currentUrl?: string, currentFired?: string, currentObserved?: string, pushTimestamp?: string, timestamp?: string, jsonData?: string, options?: any): AxiosPromise<PushMessageResponseEntity> {
            return localVarFp.apiMessagesGetPushMessageGet(availableMessage, isRead, idx, index, indexByReceiverMap, indexmap, systemAcceptKey, acceptkey, systemId, system, receiverList, receivers, messageTitleTextMap, messageTitleText, messageTitleJsonData, title, messageMemoTextMap, messageMemoText, messageMemoJsonData, memo, referenceUrlTextMap, referenceUrlText, referenceUrlJsonData, url, linkOptionMap, linkOptionJson, linkoption, firedTime, fired, observedTime, observed, extendedData, extendedDataJson, exdata, currentTitle, currentMemo, currentUrl, currentFired, currentObserved, pushTimestamp, timestamp, jsonData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userAccpetkey] 
         * @param {string} [acceptkey] 
         * @param {string} [jsonData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesGetRefreshMessageGet(userAccpetkey?: string, acceptkey?: string, jsonData?: string, options?: any): AxiosPromise<SimpleMessageResponseEntity> {
            return localVarFp.apiMessagesGetRefreshMessageGet(userAccpetkey, acceptkey, jsonData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [availableMessage] 
         * @param {boolean} [isRead] 
         * @param {number} [idx] 
         * @param {number} [index] 
         * @param {{ [key: string]: number; }} [indexByReceiverMap] 
         * @param {{ [key: string]: number; }} [indexmap] 
         * @param {string} [systemAcceptKey] 
         * @param {string} [acceptkey] 
         * @param {string} [systemId] 
         * @param {string} [system] 
         * @param {Array<MessageReceiverInfoEntity>} [receiverList] 
         * @param {Array<MessageReceiverInfoEntity>} [receivers] 
         * @param {{ [key: string]: string; }} [messageTitleTextMap] 
         * @param {{ [key: string]: string; }} [messageTitleText] 
         * @param {string} [messageTitleJsonData] 
         * @param {{ [key: string]: string; }} [title] 
         * @param {{ [key: string]: string; }} [messageMemoTextMap] 
         * @param {{ [key: string]: string; }} [messageMemoText] 
         * @param {string} [messageMemoJsonData] 
         * @param {{ [key: string]: string; }} [memo] 
         * @param {{ [key: string]: string; }} [referenceUrlTextMap] 
         * @param {{ [key: string]: string; }} [referenceUrlText] 
         * @param {string} [referenceUrlJsonData] 
         * @param {{ [key: string]: string; }} [url] 
         * @param {{ [key: string]: string; }} [linkOptionMap] 
         * @param {string} [linkOptionJson] 
         * @param {{ [key: string]: string; }} [linkoption] 
         * @param {string} [firedTime] 
         * @param {string} [fired] 
         * @param {string} [observedTime] 
         * @param {string} [observed] 
         * @param {{ [key: string]: string; }} [extendedData] 
         * @param {string} [extendedDataJson] 
         * @param {{ [key: string]: string; }} [exdata] 
         * @param {string} [currentTitle] 
         * @param {string} [currentMemo] 
         * @param {string} [currentUrl] 
         * @param {string} [currentFired] 
         * @param {string} [currentObserved] 
         * @param {string} [pushTimestamp] 
         * @param {string} [timestamp] 
         * @param {string} [jsonData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesGetRelayMessageGet(availableMessage?: boolean, isRead?: boolean, idx?: number, index?: number, indexByReceiverMap?: { [key: string]: number; }, indexmap?: { [key: string]: number; }, systemAcceptKey?: string, acceptkey?: string, systemId?: string, system?: string, receiverList?: Array<MessageReceiverInfoEntity>, receivers?: Array<MessageReceiverInfoEntity>, messageTitleTextMap?: { [key: string]: string; }, messageTitleText?: { [key: string]: string; }, messageTitleJsonData?: string, title?: { [key: string]: string; }, messageMemoTextMap?: { [key: string]: string; }, messageMemoText?: { [key: string]: string; }, messageMemoJsonData?: string, memo?: { [key: string]: string; }, referenceUrlTextMap?: { [key: string]: string; }, referenceUrlText?: { [key: string]: string; }, referenceUrlJsonData?: string, url?: { [key: string]: string; }, linkOptionMap?: { [key: string]: string; }, linkOptionJson?: string, linkoption?: { [key: string]: string; }, firedTime?: string, fired?: string, observedTime?: string, observed?: string, extendedData?: { [key: string]: string; }, extendedDataJson?: string, exdata?: { [key: string]: string; }, currentTitle?: string, currentMemo?: string, currentUrl?: string, currentFired?: string, currentObserved?: string, pushTimestamp?: string, timestamp?: string, jsonData?: string, options?: any): AxiosPromise<SimpleMessageResponseEntity> {
            return localVarFp.apiMessagesGetRelayMessageGet(availableMessage, isRead, idx, index, indexByReceiverMap, indexmap, systemAcceptKey, acceptkey, systemId, system, receiverList, receivers, messageTitleTextMap, messageTitleText, messageTitleJsonData, title, messageMemoTextMap, messageMemoText, messageMemoJsonData, memo, referenceUrlTextMap, referenceUrlText, referenceUrlJsonData, url, linkOptionMap, linkOptionJson, linkoption, firedTime, fired, observedTime, observed, extendedData, extendedDataJson, exdata, currentTitle, currentMemo, currentUrl, currentFired, currentObserved, pushTimestamp, timestamp, jsonData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 사용자ID 조회
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesGetUserIDGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiMessagesGetUserIDGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 알림목록 조회
         * @param {string} [keyword] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesItemListGet(keyword?: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<ItemListModelApiResult> {
            return localVarFp.apiMessagesItemListGet(keyword, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClearMessageRequestEntity} [clearMessageRequestEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesPostClearMessagePost(clearMessageRequestEntity?: ClearMessageRequestEntity, options?: any): AxiosPromise<ClearMessageResponseEntity> {
            return localVarFp.apiMessagesPostClearMessagePost(clearMessageRequestEntity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PushMessagePacketForApiEntity} [pushMessagePacketForApiEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesPostPushMessagePost(pushMessagePacketForApiEntity?: PushMessagePacketForApiEntity, options?: any): AxiosPromise<PushMessageResponseEntity> {
            return localVarFp.apiMessagesPostPushMessagePost(pushMessagePacketForApiEntity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SimpleMessageRequestEntity} [simpleMessageRequestEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesPostRefreshMessagePost(simpleMessageRequestEntity?: SimpleMessageRequestEntity, options?: any): AxiosPromise<SimpleMessageResponseEntity> {
            return localVarFp.apiMessagesPostRefreshMessagePost(simpleMessageRequestEntity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RelayMessagePacketForApiEntity} [relayMessagePacketForApiEntity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesPostRelayMessagePost(relayMessagePacketForApiEntity?: RelayMessagePacketForApiEntity, options?: any): AxiosPromise<SimpleMessageResponseEntity> {
            return localVarFp.apiMessagesPostRelayMessagePost(relayMessagePacketForApiEntity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PushMessageRequestModel} [pushMessageRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMessagesPushMessagePost(pushMessageRequestModel?: PushMessageRequestModel, options?: any): AxiosPromise<PushMessageResponseEntity> {
            return localVarFp.apiMessagesPushMessagePost(pushMessageRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessagesApi - object-oriented interface
 * @export
 * @class MessagesApi
 * @extends {BaseAPI}
 */
export class MessagesApi extends BaseAPI {
    /**
     * 
     * @param {string} [userAccpetkey] 
     * @param {string} [acceptkey] 
     * @param {boolean} [isDelete] 
     * @param {boolean} [_delete] 
     * @param {number} [idx] 
     * @param {number} [index] 
     * @param {string} [pushTimestamp] 
     * @param {string} [timestamp] 
     * @param {string} [jsonData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public apiMessagesGetClearMessageGet(userAccpetkey?: string, acceptkey?: string, isDelete?: boolean, _delete?: boolean, idx?: number, index?: number, pushTimestamp?: string, timestamp?: string, jsonData?: string, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).apiMessagesGetClearMessageGet(userAccpetkey, acceptkey, isDelete, _delete, idx, index, pushTimestamp, timestamp, jsonData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [availableMessage] 
     * @param {boolean} [isRead] 
     * @param {number} [idx] 
     * @param {number} [index] 
     * @param {{ [key: string]: number; }} [indexByReceiverMap] 
     * @param {{ [key: string]: number; }} [indexmap] 
     * @param {string} [systemAcceptKey] 
     * @param {string} [acceptkey] 
     * @param {string} [systemId] 
     * @param {string} [system] 
     * @param {Array<MessageReceiverInfoEntity>} [receiverList] 
     * @param {Array<MessageReceiverInfoEntity>} [receivers] 
     * @param {{ [key: string]: string; }} [messageTitleTextMap] 
     * @param {{ [key: string]: string; }} [messageTitleText] 
     * @param {string} [messageTitleJsonData] 
     * @param {{ [key: string]: string; }} [title] 
     * @param {{ [key: string]: string; }} [messageMemoTextMap] 
     * @param {{ [key: string]: string; }} [messageMemoText] 
     * @param {string} [messageMemoJsonData] 
     * @param {{ [key: string]: string; }} [memo] 
     * @param {{ [key: string]: string; }} [referenceUrlTextMap] 
     * @param {{ [key: string]: string; }} [referenceUrlText] 
     * @param {string} [referenceUrlJsonData] 
     * @param {{ [key: string]: string; }} [url] 
     * @param {{ [key: string]: string; }} [linkOptionMap] 
     * @param {string} [linkOptionJson] 
     * @param {{ [key: string]: string; }} [linkoption] 
     * @param {string} [firedTime] 
     * @param {string} [fired] 
     * @param {string} [observedTime] 
     * @param {string} [observed] 
     * @param {{ [key: string]: string; }} [extendedData] 
     * @param {string} [extendedDataJson] 
     * @param {{ [key: string]: string; }} [exdata] 
     * @param {string} [currentTitle] 
     * @param {string} [currentMemo] 
     * @param {string} [currentUrl] 
     * @param {string} [currentFired] 
     * @param {string} [currentObserved] 
     * @param {string} [pushTimestamp] 
     * @param {string} [timestamp] 
     * @param {string} [jsonData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public apiMessagesGetPushMessageGet(availableMessage?: boolean, isRead?: boolean, idx?: number, index?: number, indexByReceiverMap?: { [key: string]: number; }, indexmap?: { [key: string]: number; }, systemAcceptKey?: string, acceptkey?: string, systemId?: string, system?: string, receiverList?: Array<MessageReceiverInfoEntity>, receivers?: Array<MessageReceiverInfoEntity>, messageTitleTextMap?: { [key: string]: string; }, messageTitleText?: { [key: string]: string; }, messageTitleJsonData?: string, title?: { [key: string]: string; }, messageMemoTextMap?: { [key: string]: string; }, messageMemoText?: { [key: string]: string; }, messageMemoJsonData?: string, memo?: { [key: string]: string; }, referenceUrlTextMap?: { [key: string]: string; }, referenceUrlText?: { [key: string]: string; }, referenceUrlJsonData?: string, url?: { [key: string]: string; }, linkOptionMap?: { [key: string]: string; }, linkOptionJson?: string, linkoption?: { [key: string]: string; }, firedTime?: string, fired?: string, observedTime?: string, observed?: string, extendedData?: { [key: string]: string; }, extendedDataJson?: string, exdata?: { [key: string]: string; }, currentTitle?: string, currentMemo?: string, currentUrl?: string, currentFired?: string, currentObserved?: string, pushTimestamp?: string, timestamp?: string, jsonData?: string, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).apiMessagesGetPushMessageGet(availableMessage, isRead, idx, index, indexByReceiverMap, indexmap, systemAcceptKey, acceptkey, systemId, system, receiverList, receivers, messageTitleTextMap, messageTitleText, messageTitleJsonData, title, messageMemoTextMap, messageMemoText, messageMemoJsonData, memo, referenceUrlTextMap, referenceUrlText, referenceUrlJsonData, url, linkOptionMap, linkOptionJson, linkoption, firedTime, fired, observedTime, observed, extendedData, extendedDataJson, exdata, currentTitle, currentMemo, currentUrl, currentFired, currentObserved, pushTimestamp, timestamp, jsonData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userAccpetkey] 
     * @param {string} [acceptkey] 
     * @param {string} [jsonData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public apiMessagesGetRefreshMessageGet(userAccpetkey?: string, acceptkey?: string, jsonData?: string, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).apiMessagesGetRefreshMessageGet(userAccpetkey, acceptkey, jsonData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [availableMessage] 
     * @param {boolean} [isRead] 
     * @param {number} [idx] 
     * @param {number} [index] 
     * @param {{ [key: string]: number; }} [indexByReceiverMap] 
     * @param {{ [key: string]: number; }} [indexmap] 
     * @param {string} [systemAcceptKey] 
     * @param {string} [acceptkey] 
     * @param {string} [systemId] 
     * @param {string} [system] 
     * @param {Array<MessageReceiverInfoEntity>} [receiverList] 
     * @param {Array<MessageReceiverInfoEntity>} [receivers] 
     * @param {{ [key: string]: string; }} [messageTitleTextMap] 
     * @param {{ [key: string]: string; }} [messageTitleText] 
     * @param {string} [messageTitleJsonData] 
     * @param {{ [key: string]: string; }} [title] 
     * @param {{ [key: string]: string; }} [messageMemoTextMap] 
     * @param {{ [key: string]: string; }} [messageMemoText] 
     * @param {string} [messageMemoJsonData] 
     * @param {{ [key: string]: string; }} [memo] 
     * @param {{ [key: string]: string; }} [referenceUrlTextMap] 
     * @param {{ [key: string]: string; }} [referenceUrlText] 
     * @param {string} [referenceUrlJsonData] 
     * @param {{ [key: string]: string; }} [url] 
     * @param {{ [key: string]: string; }} [linkOptionMap] 
     * @param {string} [linkOptionJson] 
     * @param {{ [key: string]: string; }} [linkoption] 
     * @param {string} [firedTime] 
     * @param {string} [fired] 
     * @param {string} [observedTime] 
     * @param {string} [observed] 
     * @param {{ [key: string]: string; }} [extendedData] 
     * @param {string} [extendedDataJson] 
     * @param {{ [key: string]: string; }} [exdata] 
     * @param {string} [currentTitle] 
     * @param {string} [currentMemo] 
     * @param {string} [currentUrl] 
     * @param {string} [currentFired] 
     * @param {string} [currentObserved] 
     * @param {string} [pushTimestamp] 
     * @param {string} [timestamp] 
     * @param {string} [jsonData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public apiMessagesGetRelayMessageGet(availableMessage?: boolean, isRead?: boolean, idx?: number, index?: number, indexByReceiverMap?: { [key: string]: number; }, indexmap?: { [key: string]: number; }, systemAcceptKey?: string, acceptkey?: string, systemId?: string, system?: string, receiverList?: Array<MessageReceiverInfoEntity>, receivers?: Array<MessageReceiverInfoEntity>, messageTitleTextMap?: { [key: string]: string; }, messageTitleText?: { [key: string]: string; }, messageTitleJsonData?: string, title?: { [key: string]: string; }, messageMemoTextMap?: { [key: string]: string; }, messageMemoText?: { [key: string]: string; }, messageMemoJsonData?: string, memo?: { [key: string]: string; }, referenceUrlTextMap?: { [key: string]: string; }, referenceUrlText?: { [key: string]: string; }, referenceUrlJsonData?: string, url?: { [key: string]: string; }, linkOptionMap?: { [key: string]: string; }, linkOptionJson?: string, linkoption?: { [key: string]: string; }, firedTime?: string, fired?: string, observedTime?: string, observed?: string, extendedData?: { [key: string]: string; }, extendedDataJson?: string, exdata?: { [key: string]: string; }, currentTitle?: string, currentMemo?: string, currentUrl?: string, currentFired?: string, currentObserved?: string, pushTimestamp?: string, timestamp?: string, jsonData?: string, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).apiMessagesGetRelayMessageGet(availableMessage, isRead, idx, index, indexByReceiverMap, indexmap, systemAcceptKey, acceptkey, systemId, system, receiverList, receivers, messageTitleTextMap, messageTitleText, messageTitleJsonData, title, messageMemoTextMap, messageMemoText, messageMemoJsonData, memo, referenceUrlTextMap, referenceUrlText, referenceUrlJsonData, url, linkOptionMap, linkOptionJson, linkoption, firedTime, fired, observedTime, observed, extendedData, extendedDataJson, exdata, currentTitle, currentMemo, currentUrl, currentFired, currentObserved, pushTimestamp, timestamp, jsonData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 사용자ID 조회
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public apiMessagesGetUserIDGet(options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).apiMessagesGetUserIDGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 알림목록 조회
     * @param {string} [keyword] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public apiMessagesItemListGet(keyword?: string, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).apiMessagesItemListGet(keyword, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClearMessageRequestEntity} [clearMessageRequestEntity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public apiMessagesPostClearMessagePost(clearMessageRequestEntity?: ClearMessageRequestEntity, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).apiMessagesPostClearMessagePost(clearMessageRequestEntity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PushMessagePacketForApiEntity} [pushMessagePacketForApiEntity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public apiMessagesPostPushMessagePost(pushMessagePacketForApiEntity?: PushMessagePacketForApiEntity, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).apiMessagesPostPushMessagePost(pushMessagePacketForApiEntity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SimpleMessageRequestEntity} [simpleMessageRequestEntity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public apiMessagesPostRefreshMessagePost(simpleMessageRequestEntity?: SimpleMessageRequestEntity, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).apiMessagesPostRefreshMessagePost(simpleMessageRequestEntity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RelayMessagePacketForApiEntity} [relayMessagePacketForApiEntity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public apiMessagesPostRelayMessagePost(relayMessagePacketForApiEntity?: RelayMessagePacketForApiEntity, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).apiMessagesPostRelayMessagePost(relayMessagePacketForApiEntity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PushMessageRequestModel} [pushMessageRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public apiMessagesPushMessagePost(pushMessageRequestModel?: PushMessageRequestModel, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).apiMessagesPushMessagePost(pushMessageRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}
