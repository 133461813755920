<template>
  <ul v-for="ulRoof in 3" :id="pId" :key="ulRoof" :class="ulRoof == 1 ? 'menu_active_sub_list' : ''">
    <li
      v-for="i in computedMenuItem.slice((ulRoof - 1) * 5, ulRoof * 5)"
      :id="`${i.id}`"
      :key="i.id"
      ref="menuContentsItemM"
      class="menu_active_item menu_all_item"
      :class="`depth${depth} active ${i.overflow} ${
        props.keyword != '' ? (i.overflow != '' ? '' : i.name.indexOf(props.keyword) > -1 ? 'search_word' : '') : ''
      }`">
      <a :class="`menu_all_anchor depth${depth}`" @click.prevent="handleDetailSubMenuItemClick(i.url, i.id)">
        {{ i.name }}</a
      >
      <span class="cursor_pointer"> </span>

      <site-full-menu-contents-item
        ref="menuContentsItemI"
        :depth="depth + 1"
        :item="i.items"
        :keyword="props.keyword"
        @on-click-menu="handleDetailSubMenuItemClick" />
    </li>
  </ul>
</template>

<script setup lang="ts">
import SiteFullMenuContentsItem from './SiteFullMenuContentsItem.vue'
import { MenuItemModel } from 'mik-common/src/api-autogen/globalmenu'
interface Props {
  item: MenuItemModel[] | null
  depth: number
  pId: string
  keyword: string
}
const props = withDefaults(defineProps<Props>(), {
  item: undefined,
  depth: 0,
  pId: ''
  // visible: true
})
interface extItems {
  id: string
  items: MenuItemModel[]
}
const extItems = ref<extItems[]>()
/* 랜더링에 필요한 속성값을 셋팅 하기 위해서 사용 */
interface GlobalMenus {
  name: string
  id: string
  url: string
  key: string
  items: MenuItemModel[]
  overflow: string
  next: boolean
}

/** 메뉴 li를 3개씩 묶어서 표현 하기 위해 배열로 자름 */
const gItem: MenuItemModel[] = [] //3개씩 li을
const gItems: GlobalMenus[] = [] //n개 ul을 표현
const maxItemCnt = ref(3)

const computedMenuItem = computed(() => {
  const beforeName = ref('')
  const nextLine = ref(0)
  const propsItem: MenuItemModel[] = []
  Object.assign(propsItem, props.item)
  propsItem.forEach(function (item1, idx1) {
    if (item1.children && item1.children.length > 0) {
      /** 정보공유(2레벨)의 하위메뉴 랜더링시, 3레벨(item1)인 수정요청게시판 등 children이 있는 경우 gItem 3개를 모아 gItems에 insert*/
      let keyIndex = 0
      const cnt = ref(0)
      item1.children.forEach(function (item2, idx2) {
        cnt.value = idx2 + 1
        let last = false
        if (
          item1.children &&
          cnt.value + maxItemCnt.value > item1.children.length &&
          cnt.value == item1.children.length
        ) {
          last = true //3개씩 li를 자르고 마지막은 3개가 안되어도 gItems에 insert
        }
        gItem.push(item2) //4레벨에 해당하는 li maxItemCnt(3)개씩 묶일 메뉴 데이터를 gItem에 추가
        if (cnt.value % maxItemCnt.value == 0 || last) {
          //3개씩 묶어서 gItems에 insert
          keyIndex++
          const index = gItems.findIndex((x) => {
            return x.key == item1.id + '-' + keyIndex
          })
          if (index == -1) {
            gItems.push({
              key: item1.id + '-' + keyIndex,
              items: Object.assign([], gItem),
              overflow: beforeName.value != item1.name ? '' : 'overflow' /* 같은 부모를 가진 li들 체크 */,
              id: item1.id ?? '',
              name: item1.name ?? '',
              url: item1.url ?? '',
              next: nextLine.value != 0 && nextLine.value % 5 == 0 ? true : false /* 5줄로 보여 주기 위한 체크 */
            })
            nextLine.value++
            gItem.splice(0, gItem.length) //배열초기화
            beforeName.value = item1.name ?? ''
          }
        }
      })
    } //end children
    else {
      /** 예) 정보공유(2레벨)의 하위메뉴 랜더링시, 3레벨(item1)수정요청 게시판, 옐로우페이지... children 없는 경우 insert*/
      const index = gItems.findIndex((x) => {
        return x.id == item1.id
      })

      if (index == -1) {
        gItems.push({
          key: item1.id + '-0',
          items: Object.assign([], item1),
          overflow: '',
          id: item1.id ?? '',
          name: item1.name ?? '',
          url: item1.url ?? '',
          next: nextLine.value != 0 && nextLine.value % 5 == 0 ? true : false
        })
        nextLine.value++
      }
    }
  }) //end foreach
  return gItems
})

const emit = defineEmits(['on-click-menu'])
/** sub nav click */
const handleDetailSubMenuItemClick = (url: string, menuId: string) => {
  emit('on-click-menu', url, menuId)
}

/** 메뉴검색결과 위치 찾기 시작-------------------------- */
const menuContentsItemM = ref<HTMLElement[]>() //li에 해당하는
const menuContentsItemI = ref<InstanceType<typeof SiteFullMenuContentsItem>[]>() //외부함수 호출을 위해 선언
/** 3레벨 위치 찾기 parentMenuId:부모 id, pParentMenuId:부모의 부모id */
const GetOffsetM = (menuId: string, parentMenuId: string, pParentMenuId: string, depth: number) => {
  switch (depth) {
    case 3:
      {
        //3레벨의 사이즈를 구한다.
        const index =
          menuContentsItemM.value?.findIndex((menu) => {
            return menu.id == menuId
          }) ?? -1
        if (menuContentsItemM.value && index > -1) {
          return menuContentsItemM.value[index].offsetTop
        }
        return 0
      }
      break
    case 4:
    case 5:
      {
        if (menuContentsItemI.value && menuId != '') {
          let i_loc = 0
          for (let j = 0; j < menuContentsItemI.value.length; j++) {
            //어디에 li가 있는지 알수 없으므로 전부 돌린다.
            const level4Loc = menuContentsItemI.value[j].GetOffsetI(menuId, depth) //4레벨의 위치를 찾음
            if (i_loc < level4Loc) {
              i_loc = level4Loc
              break
            }
          }
          return i_loc
        }
        return 0
      }
      break
  } //end switch
  return 0
}

/** 부모 li를 연다 */
const handleParentExpand = (menuHistory: string[]) => {
  if (menuContentsItemI.value) {
    //어디에 li가 있는지 알수 없으므로 전부 돌린다.
    for (let j = 0; j < menuContentsItemI.value.length; j++) {
      menuContentsItemI.value[j].handleParentExpand(menuHistory)
    }
  }
}

/** 외부함수로 선언 */
defineExpose({
  GetOffsetM,
  handleParentExpand
})

/** 메뉴검색결과 위치 찾기 끝-------------------------- */
</script>
