<template>
  <div class="gwp_header_srch brder-left">
    <span :title="$t('SearchMember')" class="gwp_input_srch ico_header cursor_pointer"></span>
    <input
      v-model="keyword"
      type="text"
      :placeholder="$t('SearchMember')"
      autocomplete="off"
      class="gnb_inp_srch gnb_inp_srch_mem"
      @keypress.enter.prevent="handleSearchClick" />
    <button :title="$t('SearchMember')" class="gwp_btn_srch" @click.prevent="handleSearchClick">
      <span class="ico_header ico_header_srch cursor_pointer"></span>
    </button>
  </div>
</template>

<script lang="ts" setup>
import useGwpGlobalMenu from '@gnb/utils/gnb'
import { ref } from 'vue'

const keyword = ref('')
// get gwpGlobalMenu Instance
const { openYellowPage } = useGwpGlobalMenu()

const handleSearchClick = () => {
  openYellowPage(keyword.value)
}
</script>
