<template>
  <div id="menu_search_body" class="search_section">
    <!-- -->

    <div class="contents_body" style="min-height: 100px">
      <!-- [D] .search_section 에 .active 클래스 붙었을때 recent_list_layer 활성화됨 -->
      <div class="search_section_sub">
        <div class="search_header">
          <div class="search_title">{{ $t('SearchMenu') }}</div>
        </div>

        <div class="search_wrap">
          <input
            id="input_searchmenukeyword"
            v-model="input_keyword"
            type="text"
            class="srch_input"
            :placeholder="$t('SearchMenuDescript')"
            @focus="handleFocusRecentlySearchMenu"
            @blur="handleBlurRecentlySearchMenu"
            @keypress.enter.prevent="handleEnterSearchMenu"
            @keypress.esc.prevent="isSearchVisible = false" />
          <button class="btn_srch" @click.prevent="handleEnterSearchMenu">
            <span class="ico_search ico_search_srch"></span>
          </button>
        </div>
        <slide-down>
          <div v-if="isActiveSearchbar" id="pop_search_body" class="search_section" style="display: block; margin: 0px">
            <!-- [D] .search_section 에 .active 클래스 붙었을때 recent_list_layer 활성화됨 -->
            <div :class="isActiveSearchbar ? 'search_section_sub active' : 'search_section_sub'">
              <!-- 최근검색어 -->
              <div class="recent_list_layer">
                <ul id="ul_recentlysearchmenu" class="recent_list">
                  <li v-for="item in recentlySearchMenuList" :key="item.keyword ?? ''" class="recent_item">
                    <a class="recent_anchor" @mousedown.prevent="handleRecentlySearchMenu(item.keyword ?? '')">{{
                      item.keyword
                    }}</a>
                    <button
                      class="delete_recent"
                      @mousedown.prevent="handleDeleteRecentlySearchMenu(item.sortOrder ?? -1)">
                      <span class="ico_search ico_search_delete_recent" style="display: block"></span>
                    </button>
                  </li>
                </ul>
                <div class="recent_delete">
                  <button class="delete_button" @mousedown.prevent="handleDeleteRecentlySearchMenu(0)">
                    <span id="span_deleterecentlysearchmenutitle" class="btn_txt">
                      {{ $t('DeleteAll') }}
                    </span>
                    <span class="ico_search ico_search_delete_recent"></span>
                  </button>
                </div>
              </div>
              <!-- //최근검색어 -->
            </div>
          </div>
        </slide-down>
      </div>

      <div class="result_section">
        <!-- 메뉴검색결과 -->
        <div v-if="isResultVisible" class="menu_searchresult" style="margin-top: 10px">
          <div v-if="searchMenuMasterList.totalCount && searchMenuMasterList.totalCount < 1">
            <div id="div_searchmenuresulttitle" class="searchresult_title">
              {{ $t('MenuSearchResultEmpty') }}
            </div>
          </div>
          <div v-else class="menu_searchresult_header" style="">
            <div id="div_searchmenuresulttitle" class="searchresult_title">
              {{ $t('MenuSearchResult1') }} <b>{{ searchMenuMasterList.totalCount }}</b
              >{{ $t('MenuSearchResult2') }}
            </div>
          </div>
          <div class="menu_searchresult_body" style="min-height: 72px">
            <ul id="ul_searchmenuresult" class="searchresult_list">
              <li v-for="(item, index) in searchMenuMasterList.listData" :key="index" class="searchresult_item">
                <div class="searchresult_menu">
                  <a
                    :href="item.url ?? '#'"
                    target="_blank"
                    class="menu_anchor"
                    v-html="handleStrongKeyword(item.menuName ?? '')">
                  </a>
                  <button
                    :class="item.bookmarkId ? 'btn_favorite active' : 'btn_favorite'"
                    @click.prevent="handleFavoriteClick(item)">
                    <span class="ico_usearch ico_usearch_favorite"></span>
                  </button>
                  <button class="btn_find" style="" @click="handleMoveSearchMenuFind(item)">
                    <span class="ico_usearch_find"></span>
                  </button>
                </div>
                <!-- History -->
                <div class="searchresult_path">
                  <ul class="path_list">
                    <li v-for="historyItem in item.menuHistoryList" :key="historyItem.MenuId" class="path_item">
                      <a
                        :href="historyItem.Url ? historyItem.Url : 'javascript:;'"
                        target="_blank"
                        class="path_anchor"
                        :style="historyItem.Url ? '' : 'cursor:default;'">
                        <span class="path_txt">{{ historyItem.MenuName }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>

          <!-- Paginate -->
          <mik-pagination-ext
            :total-item-count="searchMenuMasterList.totalCount"
            :page-size="searchMenuMasterList.pageSize"
            :current-page="searchMenuMasterList.pageNumber"
            :page-step-level="5"
            @update:current-page="handleChangePage">
          </mik-pagination-ext>
          <!-- //Paginate -->
        </div>
      </div>
    </div>
  </div>
  <div>
    <div>
      <mik-common-popup
        :model-value="isFavoriteVisible"
        :on-cancel="handleFavoriteClose"
        title="Smart Portal"
        class="common-input-popup-modal"
        content-class=""
        size="m">
        <template #body>
          <div>
            <div>
              <table
                style="
                  table-layout: fixed;
                  border-top: 1px solid #e0e0e0;
                  border-bottom: 1px solid #e0e0e0;
                  width: 100%;
                ">
                <colgroup>
                  <col width="145" />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th style="background-color: #f4f4f6">{{ $t('Favorite') }}</th>
                    <td>
                      <mik-input-ext
                        id="input_favorite"
                        v-model="input_favorite"
                        autofocus
                        type="text"
                        style="width: 100%" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="sect_button" style="padding-top: 10px; text-align: center">
              <mik-button-ext
                :click-function="handleFavoriteSave"
                :button-txt="$t('Save')"
                button-type="btn_type_a btn_color_04" />
            </div>
          </div>
        </template>
      </mik-common-popup>
    </div>
  </div>
</template>

<script setup lang="ts">
// import { Cookies } from 'quasar'
import VueCookies from 'vue-cookies'

import { mikCustomPopup } from '@gnb/common/common'
import { installOptions } from '@gnb/modules/menus/main'

import SlideDown from '@gnb/components/animations/SlideDown.vue'
import MikPaginationExt from '../common/MikPaginationExt.vue'
import MikCommonPopup from '../common/MikCommonPopup.vue'
import MikButtonExt from '../common/MikButtonExt.vue'
import MikInputExt from '../common/MikInputExt.vue'

import { RecentlyMenuApi, BookmarkApi } from 'mik-common/src/api-autogen/globalmenu'

import { RecentlySearchMenuModel } from 'mik-common/src/api-autogen/globalmenu'
import { SearchMenuMasterModel, SearchMenuMasterListModel } from 'mik-common/src/api-autogen/globalmenu'

/** Search Input Element */
const input_keyword = ref<string>('')
/** Slide visible */
const isSearchVisible = ref(false)
/** Popup Searchbar active */
const isActiveSearchbar = ref(false)
/** 최근 검색기록 */
const recentlySearchMenuList = ref<RecentlySearchMenuModel[]>([])
/** Result visible */
const isResultVisible = ref(false)
/** 메뉴 검색 결과 */
const searchMenuMasterList = ref<SearchMenuList>({
  totalCount: 0,
  pageNumber: 1,
  pageSize: 4,
  listData: [],
  listMenuID: []
})
const apiBook = new BookmarkApi()
const apiRecently = new RecentlyMenuApi()

const { t } = useI18n()

/** 메뉴 검색 */
const handleEnterSearchMenu = () => {
  getSearchMenu()
}
/** 팝업 검색창이 활성화시 최근 검색기록 조회 */
const handleFocusRecentlySearchMenu = () => {
  if (isActiveSearchbar.value) return
  else {
    isActiveSearchbar.value = true
    //최근 검색기록 가져옴
    apiRecently.v1RecentlyMenuRecentlySearchMenuGet().then((response) => {
      if (response.data.success && response.data.data) {
        recentlySearchMenuList.value = response.data.data
      }
    })
  }
}
//결과값의 다국어를 위해서 쿠키에서 가져온다.
//@ts-ignore
const langCookie = VueCookies.get('GWP_LANGUAGE_CODE') ?? 'ko'

/** 메뉴 검색 */
const getSearchMenu = async (pageNum?: number) => {
  const val = input_keyword.value

  const bucketId = installOptions.bucketId ?? import.meta.env.V_MENU_BUCKETID

  isResultVisible.value = false

  const response = await apiRecently.v1RecentlyMenuSearchMenuMasterKeywordGet(
    val,
    langCookie,
    pageNum,
    searchMenuMasterList.value.pageSize,
    bucketId
  )

  if (response.data.success && response.data.data) {
    // 메뉴 결과창 show
    isResultVisible.value = true
    // 최근 검색기록 hide
    isActiveSearchbar.value = false

    searchMenuMasterList.value = response.data.data as SearchMenuList
    if (searchMenuMasterList.value.listData) {
      searchMenuMasterList.value.listData.forEach((menu) => {
        menu.menuHistoryList = JSON.parse(menu.menuHistory ?? '[]')
      })
    }
    //메뉴에 검색어 전달( siteFullMenuContent에 전달해서 주황색으로 표기 하기 위함)
    handleSearchwordStrong()
  }
}
/** 최근 검색창 닫기 */
const closeRecentlySearchMenu = () => {
  isActiveSearchbar.value = false
}
/** Blur 이벤트 */
const handleBlurRecentlySearchMenu = (e: Event) => {
  closeRecentlySearchMenu()
}
/** 최근 검색 메뉴 클릭 */
const handleRecentlySearchMenu = (keyword: string) => {
  input_keyword.value = keyword
  getSearchMenu()
}

/**
 * 최근 검색 삭제
 * @param sortOrder 0인 경우 전체 삭제
 */
const handleDeleteRecentlySearchMenu = (sortOrder: number) => {
  apiRecently
    .v1RecentlyMenuRecentlySearchMenuDelete(sortOrder)
    .then((response) => {
      if (response.data.success) {
        // 전체삭제
        if (sortOrder == 0) {
          recentlySearchMenuList.value = []
        } else {
          const index = recentlySearchMenuList.value.findIndex((item) => item.sortOrder === sortOrder)
          recentlySearchMenuList.value.splice(index, 1)
        }
        if (recentlySearchMenuList.value.length == 0) closeRecentlySearchMenu()
      }
    })
    .catch((err) => console.log(err))
}
/** 즐겨찾기 아이콘 클릭 */
const handleFavoriteClick = (menu: SearchMenu) => {
  // 이미 즐겨찾기를 한 경우 삭제처리
  if (menu.bookmarkId) {
    mikCustomPopup.confirm(t('DeleteQ'), handleFavoriteDel, '', menu)
  } else {
    isFavoriteVisible.value = true
    selFavorite.value = menu
    input_favorite.value = menu.menuName ?? ''
  }
}
interface menuRequest {
  name: string
  url: string
  menuId: string
}
/** 즐겨 찾기 저장 */
const handleFavoriteSave = () => {
  const menu = selFavorite.value
  if (menu) {
    const menuRequest = ref<menuRequest>({ name: input_favorite.value, url: menu.url ?? '', menuId: menu.menuId ?? '' })

    apiBook
      .v1BookmarkPost(menuRequest.value)
      .then((response) => {
        menu.bookmarkId = response.data.data
        handleFavoriteClose()
      })
      .catch((err) => console.log(err))
  }
}
/** 즐겨 찾기 삭제 */
const handleFavoriteDel = (menu: SearchMenu) => {
  apiBook
    .v1BookmarkIdDelete(menu.bookmarkId ?? '')
    .then((response) => {
      menu.bookmarkId = ''
    })
    .catch((err) => console.log(err))
}
/** pagenation */
const handleChangePage = (pageNum: number) => {
  getSearchMenu(pageNum)
}
const input_favorite = ref('') //favorite 저장 이름
const isFavoriteVisible = ref(false) //즐겨찾기 저장 창
const favoriteTitleText = ref('Favorite')
/** favorite 팝업 끄기 */
const handleFavoriteClose = () => {
  isFavoriteVisible.value = false
}

const selFavorite = ref({} as SearchMenu) //favorite 선택 메뉴이름

/**메뉴이동버튼 클릭 */
const emits = defineEmits<{
  (e: 'on-find-menu-position', menuId: string, menuHistoryList: string[]): void
  (e: 'on-keyword-strong', menuName: string): void
}>()
/** 메뉴 위치찾기 */
const handleMoveSearchMenuFind = (menu: SearchMenu) => {
  emits('on-find-menu-position', menu.menuId ?? '', handlePassMenuHistory(menu.menuId ?? ''))
}
/** 메뉴 키워드 */
const handleSearchwordStrong = () => {
  emits('on-keyword-strong', input_keyword.value)
}

/** 키워드 강조 */
const handleStrongKeyword = (menuName: string) => {
  if (input_keyword.value.indexOf('[') > -1) {
    const target = ref(input_keyword.value)
    if (input_keyword.value.toLowerCase() != input_keyword.value.toUpperCase())
      target.value = input_keyword.value.toLowerCase() + input_keyword.value.toUpperCase()

    const keywordArray = [...new Set(target.value.replace('[', '').replace(']', '').replaceAll(',', '').split(''))]
    const menuNameArray = [...new Set(menuName.split(''))]

    menuNameArray.forEach((x, i) => {
      keywordArray.forEach((y) => {
        if (x == y) menuNameArray[i] = "<span class='emph'>" + x + '</span>'
      })
    })

    return menuNameArray.join('')
  } else {
    return menuName.replace(input_keyword.value, "<span class='emph'>" + input_keyword.value + '</span>')
  }
}

/** 메뉴 히스토리 정보 전달 */
const handlePassMenuHistory = (menuId: string) => {
  if (searchMenuMasterList.value.listData) {
    const index = searchMenuMasterList.value.listData.findIndex((menu) => {
      return menu.menuId == menuId
    })
    const expandedItems: string[] = reactive([])
    searchMenuMasterList.value.listData[index].menuHistoryList?.forEach((menu) => {
      if (menu.MenuId) expandedItems.push(menu.MenuId)
    })
    return expandedItems
  }

  return []
}

watch(isFavoriteVisible, () => {
  if (isFavoriteVisible.value) {
    const i = document.body
    i.classList.add('body-prevent-scroll')
  } else {
    const i = document.body
    i.classList.remove('body-prevent-scroll')
  }
})

interface SearchMenuList extends SearchMenuMasterListModel {
  listData: SearchMenu[]
}
interface SearchMenu extends SearchMenuMasterModel {
  menuHistoryList?: MenuHistory[]
}
interface MenuHistory {
  BucketId?: string
  MenuId?: string
  MenuName?: string
  Url?: string
}
</script>
