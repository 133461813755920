<template>
  <div ref="root" class="gwp_header_shrt_menu brder-left slidedown gwp_header_recentmenu">
    <div class="gwp_button_wrap">
      <button :title="$t('Recent')" class="menu_box_img btn_slidedown" @click.prevent="handleRecentlyUsedMenuClick">
        <span class="menu_last ico_header"></span>
        <span class="menu_txt">{{ $t('Recent') }}</span>
      </button>
    </div>

    <div class="gwp_box_slidedown_wrap">
      <slide-down>
        <div v-if="isRecentlyUsedMenuVisible" class="gwp_recentmenu_wrap box_slidedown" style="display: block">
          <div class="title_area">
            <span class="fav_title">{{ $t('Recent') }}</span>
            <button class="btn_type_e" style="position: absolute; right: 0" @click.prevent="handleDeleteAllClick">
              <span class="btn_txt_trashcan">{{ $t('DeleteAll') }}</span>
              <span class="ico_header ico_header_trashcan"></span>
            </button>
          </div>
          <div class="recentmenu_area">
            <div class="nano has-scrollbar">
              <div class="nano-content">
                <ul id="gwp_recently_list" class="gwp_recentmenu_list">
                  <li v-for="item in recentlyUsedMenuList" :key="item.menuId ?? ''" class="gwp_recentmenu_item">
                    <a
                      class="gwp_recentmenu_anchor"
                      style="cursor: pointer"
                      @click.prevent="handleItemMenuClick(item.url ?? '', item.menuId ?? '', $event)"
                      >{{ item.getMenuName }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </slide-down>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { RecentlyMenuApi } from 'mik-common/src/api-autogen/globalmenu'
import { RecentlyUsedMenuModel } from 'mik-common/src/api-autogen/globalmenu'
import SlideDown from '@gnb/components/animations/SlideDown.vue'
import { mikCustomPopup } from '@gnb/common/common'

const { t } = useI18n()
const apiRecently = new RecentlyMenuApi()
/** root El */
const root = ref(null)
/** 최근 사용 메뉴 DropDown Visible */
const isRecentlyUsedMenuVisible = ref(false)
/** 최근 사용 메뉴 데이터 */
const recentlyUsedMenuList = ref<RecentlyUsedMenuModel[]>([])

const getRecentlyUsedMenuList = () => {
  apiRecently.v1RecentlyMenuRecentlyUsedMenuGet().then((response) => {
    if (response.data.success && response.data.data) {
      recentlyUsedMenuList.value = response.data.data
    }
  })
}

/** LifeCycle Hook */
/** handle document click */
onMounted(() => {
  document.addEventListener('click', handleDocumentClick)
})
onBeforeUnmount(() => {
  document.removeEventListener('click', handleDocumentClick)
})

/** 최근 사용 메뉴 SlideDown 클릭 */
const handleRecentlyUsedMenuClick = () => {
  if (!isRecentlyUsedMenuVisible.value) getRecentlyUsedMenuList()
  isRecentlyUsedMenuVisible.value = !isRecentlyUsedMenuVisible.value
}
/** 메뉴 이동 */
const handleItemMenuClick = (url: string, menuId: string, event: MouseEvent) => {
  if (event.ctrlKey) {
    handleOpenTab(url.split('$')[3])
  } else {
    emits('on-click-menu', url, menuId)
  }
}
/** tab open url */
const handleOpenTab = (url: string) => {
  window.open(url, '_blank', '')
}
/** 전체 삭제 */
const handleDeleteAllClick = () => {
  mikCustomPopup.confirm(t('DeleteQ'), handleDeleteAll, '')
}

const handleDeleteAll = (param: string) => {
  console.log(param)
  recentlyUsedMenuList.value = []
  apiRecently.v1RecentlyMenuRecentlyUsedMenuDelete()
}

const handleDocumentClick = (e: Event) => {
  if (e == undefined || e == null) {
    return
  }
  /**
   * 해당 컴포넌트 외부에서 클릭이 되었을 경우, drop down을 닫기위한 기능 연결
   */
  // @ts-ignore
  if (!root.value.contains(e.target)) {
    isRecentlyUsedMenuVisible.value = false
  }
}

const emits = defineEmits<{ (e: 'on-click-menu', url: string, menuId: string): void }>()
</script>

<style>
.btn_type_e .btn_txt {
  font-size: 14px; /* 20240105 css v1을 덮어쓰기 하니 폰트 사이즈가 너무 커서 추가*/
}
</style>
