<template>
  <div>
    <transition name="fade" @after-leave="handleLeaveAfterFade">
      <div
        v-show="visibleState"
        :class="`gwp_gnb_sub_type${panelType} ${computedNoTransition}`"
        class="gwp_header_left fadeCss"
        style="z-index: 100"
        @mouseenter="handlePanelMouseEnter"
        @mouseleave="handlePanelMouseleave">
        <ul id="gwp_gnb_list_area" class="gwp_gnb_list">
          <li class="gwp_gnb_item">
            <div
              class="gwp_gnb_sub gwp_gnb_sub_type2"
              :style="`${visibleState ? 'display: block;' : ''}`"
              style="overflow: auto">
              <div class="gwp_gnb_sub_wrap nano-content">
                <span class="gwp_gnb_sub_img"></span>
                <ul class="gwp_sub_list depth2" style="">
                  <!-- Tenant start -->
                  <li></li>
                  <!-- Tenant end -->
                  <template v-for="item in items" :key="item.id">
                    <nav-panel-item
                      v-if="item.id === activeItemId || activeItemId === '*'"
                      :depth="2"
                      :item="item"
                      @on-click-menu="handleItemMenuClick" />
                  </template>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { MenuItemModel } from 'mik-common/src/api-autogen/globalmenu'

import NavPanelItem from './NavPanelItem.vue'

interface Props {
  items: MenuItemModel[]
  activeItemId: string
  show: Boolean
  panelType: string
  notransition: boolean
}

const props = withDefaults(defineProps<Props>(), {
  items: undefined,
  activeItemId: '',
  //show: Boolean,
  panelType: '02',
  notransition: false
})

const emit = defineEmits(['on-visible-change', 'on-click-menu'])

const isMouseEnter = ref(false)
const visibleState = ref(false)

const computedVisible = computed(() => {
  if (props.show || isMouseEnter.value) {
    return true
  }
  return false
})

const watchVisible = (current: boolean) => {
  if (visibleState.value !== current) {
    visibleState.value = current
    if (current === true) {
      emit('on-visible-change', true)
    }
  }
}

const handleLeaveAfterFade = () => {
  emit('on-visible-change', false)
}

const handlePanelMouseEnter = () => {
  isMouseEnter.value = true
}

const handlePanelMouseleave = () => {
  isMouseEnter.value = false
}
const handleItemMenuClick = (url: string, menuId: string) => {
  emit('on-click-menu', url, menuId)
  isMouseEnter.value = false
}
/** fadeout을 사용하지 않기로 함 항상 notransition */
const computedNoTransition = computed(() => {
  //return props.panelType === '01' ? 'notransition' : ''
  return 'notransition'
})
watch(computedVisible, (a) => {
  watchVisible(a)
})
</script>

<style scoped>
.fade-enter-active {
  transition: opacity 0.7s ease-in;
}
.fade-leave-active {
  transition: opacity 0.7s ease-out;
}

/* .fade-leave-active below version 2.1.8 */
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
