import axios from 'axios'
import { ref, reactive, onBeforeUnmount, onMounted, readonly, onUnmounted } from 'vue'
import { useNotifyGlobalSettingsStore } from '@gnb/store/modules/notification-center/notifyGlobalSettingsStore' //전역 스토어 개체 (쓰는곳이 많음)
import { useNotifyHelpers } from './notifyHelpers' //함수를 참조해서 사용함
import { GetItemResult, PushMessagePacketForApiEntity } from 'mik-common/src/api-autogen/notificationCenter'
import { MessagesApi } from 'mik-common/src/api-autogen/notificationCenter/api/messages-api'
import { navigateToUrl } from '../../utils/gnb'

import {
  useGlobalObjectsStore,
  usePrivateObjectsStore
} from '@gnb/store/modules/notification-center/notifyPrivateObjStore'
import { NotifyGlobalSettings } from './notifyPrivateObj'
//import { mikCommonPopup } from '@gnb/common/common'
//import { merge } from 'lodash'

// SocketMessageActionTypes에 해당하는 TypeScript 타입
type SocketMessageActionType =
  | 'noaction'
  | 'registration'
  | 'registrationresponse'
  | 'ping'
  | 'pong'
  | 'nop'
  | 'refreshclient'
  | 'executescript'
  | 'shadownotification'
  | 'clearmessage'

// SocketMessageBaseEntity에 해당하는 TypeScript 인터페이스
interface SocketMessageBaseEntity {
  action: SocketMessageActionType
}

// 응답 엔티티
interface SocketMessageReceivedResponseEntity extends SocketMessageBaseEntity {
  success: boolean
  message: string
}

export interface NotificationOptions {
  index?: number
}

export function deepClone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj)) as T
}

export const useNotificationCenterClientManager = () => {
  const notifyGlobalSettingsStore = useNotifyGlobalSettingsStore()
  const privateObjectsStore = usePrivateObjectsStore()
  const globalObjectsStore = useGlobalObjectsStore()
  const messageApi = new MessagesApi()

  const manager = reactive({
    setup(options: NotifyGlobalSettings) {
      //원본 : privateObjects.options = $.extend(true, {}, w.MikNotificationCenter.globalSettings, options || {})  -- DeepCopy 이용함
      //기본값 복사. (DeepCopy - 1레벨 멤버뿐 아니라 중첩된 객체까지 모두 복사함)
      //notifyPrivateObj.options = Object.assign(clonedSettings, options || {}) //options파라메터값 입력 (ShallowCopy)
      privateObjectsStore.options = Object.assign({}, notifyGlobalSettingsStore, options || {}) //options파라메터값 입력 (ShallowCopy)

      // 윈도우 포커스 이벤트 리스너 설정
      const focusOutHandler = () => manager.callback.windowActivatedCallback(false)
      const focusInHandler = () => manager.callback.windowActivatedCallback(true)
      window.addEventListener('focusout', focusOutHandler)
      window.addEventListener('focusin', focusInHandler)

      // 언마운트 시 이벤트 리스너 제거
      onUnmounted(() => {
        window.removeEventListener('focusout', focusOutHandler)
        window.removeEventListener('focusin', focusInHandler)
      })

      // beforeunload 이벤트 리스너 설정
      const handleBeforeUnload = () => manager.api.disconnectNotificationServer(true)
      window.addEventListener('beforeunload', handleBeforeUnload)

      // 컴포넌트 언마운트 시 이벤트 리스너 제거
      onBeforeUnmount(() => {
        window.removeEventListener('beforeunload', handleBeforeUnload)
      })
    },
    api: {
      connectNotificationServer() {
        // 기존 커넥션 정리
        try {
          if (privateObjectsStore.connection.socket) {
            // 기존 소켓이 열려있으면 먼저 닫습니다. -- 지워도 될지 확인 필요....
            privateObjectsStore.connection.socket.close()
          }
        } catch (e) {
          appendMessageLog('WebSocket failed cleaning.')
        }

        // 커넥션 유지 모드 활성화 (disconnect api에서 비활성화 시키기 때문에 여기선 항성 활성화시켜줘야함)
        privateObjectsStore.connection.permanentConnection = true

        // 소켓 접속 내부로직 준비
        const fnConnectInnerLogic = (acceptkey: string | null) => {
          const connectionUrl = notifyGlobalSettingsStore.getSocketUrl

          acceptkey = acceptkey ?? '' // acceptkey가 null 또는 undefined이면 빈 문자열로 대체
          privateObjectsStore.connection.acceptkey = acceptkey
          privateObjectsStore.connection.socket = new WebSocket(connectionUrl)

          privateObjectsStore.connection.socket.onopen = () => {
            appendMessageLog('WebSocket opened.')
            // 클라이언트 정보 전달, 화살표 함수에서는 this가 상위 스코프이므로 원본 소스의 function 함수의 this와 의미가 다름, Vue3에서 this사용 일반적으로 배제함
            sendSocketMessage(privateObjectsStore.connection.socket, {
              action: 'registration',
              identity: privateObjectsStore.connection.identity,
              acceptkey: privateObjectsStore.connection.acceptkey
            })

            privateObjectsStore.options?.eventHandlers?.connected?.()
          }

          privateObjectsStore.connection.socket.onmessage = (e) => {
            const json = convertToJsonFromSocket(e.data) //SocketMessageReceivedResponseEntity 또는 PushMessageResponseEntity

            //json.action 은 SocketMessageReceivedResponseEntity 일때만 유효
            //json.system 은 PushMessageResponseEntity 일때만 유효

            if (json.success && json.action == 'registrationresponse') {
              appendMessageLog('WebSocket registered.')
              keepalive(privateObjectsStore.connection.socket as WebSocket)
            } else if (json.success && json.action == 'refreshclient') {
              standby(privateObjectsStore)
            } else if (json.success && json.action == 'clearmessage') {
              // 메시지 처리
              // 유저 액션 처리
              privateObjectsStore.options?.eventHandlers?.received?.(json)
            } else if (json.success && json.action == 'executescript') {
              try {
                const message = JSON.parse(json.message)
                const script = message.script
                // callFuncText 함수가 필요하다면 여기에 추가
                const exdata = message.exdata
                //window.callFuncText(script)  함수 실행은 type 스크립트에서 보안 위협으로 판단함
                eval(script)
              } catch (e) {
                appendMessageLog('user function failed.')
              }
            } else if (json.success && json.action == 'shadownotification') {
              const msg = JSON.parse(json.message)
              manager.notify(msg)
            } else {
              // 알림 카운트가 유효할 때 알람 소리를 재생
              if (
                !privateObjectsStore.environment.mute &&
                (privateObjectsStore.environment.playone ? privateObjectsStore.environment.windowActivated : true)
              ) {
                playAlramSound()
              }

              // 메시지 처리
              privateObjectsStore.options?.eventHandlers?.received?.(json)
              // 시스템 액션 처리, Push System 값이 존재해야 한다.
              //if (json.system) { //SocketMessageReceivedResponseEntity에는 system 필드가 없음.
              //   manager.notify(json)
              // }
              if (typeof json != 'undefined' && typeof json.system != 'undefined') {
                manager.notify(json)
              } // end fo if - json+json.system check
            }
          }

          privateObjectsStore.connection.socket.onclose = () => {
            appendMessageLog('WebSocket closed.')
            privateObjectsStore.options?.eventHandlers?.disconnected?.()

            // Reconnection logic
            try {
              if (privateObjectsStore.connection.permanentConnection) {
                //const delay = notifyPrivateObj.options?.standby.delaySeconds * 1000
                const delay = (privateObjectsStore.options?.standby?.delaySeconds ?? 5) * 1000
                const randomDelay =
                  random(
                    //notifyPrivateObj.options?.standby.delaySeconds * 3,
                    (privateObjectsStore.options?.standby?.delaySeconds ?? 5) * 3,
                    privateObjectsStore.options?.standby.delaySeconds
                  ) * 1000
                appendMessageLog(`reconnection // delay : ${delay} , randomDelay : ${randomDelay}`)
                setTimeout(() => manager.api.connectNotificationServer(), delay + randomDelay)
              }
            } catch (e) {
              if (e instanceof Error) {
                // e가 Error 객체인지 확인
                appendMessageLog('reconnection failed.', e.message)
              } else {
                // e가 Error 객체가 아닐 경우의 처리
                appendMessageLog('reconnection failed. Unknown error occurred')
              }
            }
          }

          privateObjectsStore.connection.socket.onerror = () => {
            //appendMessageLog('WebSocket encountered an error:', e.error || e)
            appendMessageLog('WebSocket encountered an error')
          }
        }

        standby(privateObjectsStore, fnConnectInnerLogic)
      },

      // ... 기존 connectNotificationServer 함수 ...
      disconnectNotificationServer(blackout: boolean) {
        privateObjectsStore.connection.permanentConnection = !(blackout || false)
        try {
          if (privateObjectsStore.connection.socket) {
            privateObjectsStore.connection.socket.close()
            privateObjectsStore.connection.socket = null
          }
        } catch (e) {
          if (e instanceof Error) {
            // e가 Error 객체인지 확인
            appendMessageLog('reconnection failed.', e.message)
          } else {
            // e가 Error 객체가 아닐 경우의 처리
            appendMessageLog('reconnection failed. Unknown error occurred')
          }
        }
      },
      sendNotificationMessage: async (
        message: PushMessagePacketForApiEntity,
        successCallback: (data: any) => void,
        errorCallback: (error: Error) => void,
        finishCallback: () => void
      ) => {
        // 기본 콜백 함수 정의
        const onSuccess = successCallback || (() => {})
        const onError = errorCallback || (() => {})
        const onFinish = finishCallback || (() => {})

        // API 호출 URI
        //const url = notifyGlobalSettingsStore.getApiUrl('API/PushMessage')
        //const url = notifyGlobalSettingsStore.getApiUrl('API/PushMessage')

        try {
          const url = notifyGlobalSettingsStore.getApiUrl('/api/messages/PostPushMessage')
          //const response = await messageApi.apiMessagesPostPushMessagePost(message)
          const response = await axios.post(url, message)

          if (response.status === 200) {
            onSuccess(response.data)
          } else {
            throw new Error('Network response was not ok.')
          }
        } catch (error) {
          if (error instanceof Error) {
            onError(error)
          } else {
            onError(new Error('An unknown error occurred'))
          }
        } finally {
          onFinish()
        }
      },
      getNotificationMessages: async (
        options: any,
        successCallback: (data: any) => void,
        errorCallback: (error: Error) => void,
        finishCallback: () => void
      ) => {
        // 기본 콜백 함수 정의
        const onSuccess = successCallback || (() => {})
        const onError = errorCallback || (() => {})
        const onFinish = finishCallback || (() => {})

        // API 호출 URI
        const getMessagesApiUrl = notifyGlobalSettingsStore.getApiUrl('/API/History/GetMessages')
        try {
          const response = await axios.post(
            getMessagesApiUrl,
            deepClone({
              acceptkey: privateObjectsStore.connection.acceptkey,
              observed: true,
              page: 1,
              size: 20,
              ...options // 스프레드함수 사용
            })
          )
          if (response.status === 200) {
            onSuccess(response.data)
          } else {
            throw new Error(`HTTP error! status: ${response.status}`)
          }
        } catch (error) {
          if (error instanceof Error) {
            onError(error)
          } else {
            onError(new Error('An unknown error occurred'))
          }
        } finally {
          onFinish()
        }
      },
      moveToMessageUrl: async (message: GetItemResult) => {
        const messageLinkOption = JSON.parse(message.linkoption || '{}')
        const messageExData = JSON.parse(message.extendedData || '{}')
        if (typeof message === 'object' && message !== null && typeof message.id === 'number') {
          const url = notifyGlobalSettingsStore.getApiUrl(`/Message/MoveMessageUrl?messageIndex=${message.id}`)
          //JSON.parse
          let method = 'link'
          if (messageLinkOption.method) {
            method = messageLinkOption.method.toLowerCase()
          }
          switch (method) {
            case 'none':
              alert('none')
              break
            case 'popup':
              {
                const name = messageLinkOption.popup_name || '_blank'
                const spec = messageLinkOption.popup_specs || ''
                window.open(url, name, spec)
              }
              break
            //popup2로 저장되는곳 없음
            case 'popup2':
              {
                const name2 = messageLinkOption.popup2_name || ''
                const width = Number(messageLinkOption.popup2_width || window.screen.width / 2)
                const height = Number(messageLinkOption.popup2_height || window.screen.height / 2)
                const resizable = messageLinkOption.popup2_resizable || 'false'
                manager.linker.openWindowCenter(url, name2, width, height, resizable.toLowerCase() === 'true')
              }
              break
            case 'link':
            default:
              switch ((messageExData.PushSystem || '').toLowerCase()) {
                case 'apv':
                case 'approval':
                  {
                    // Clear Notification Message
                    const msgidx = message.id
                    manager.api.clearNotificationActivated({ index: msgidx })
                    // Window Open
                    const name = '_blank'
                    const spec = ''
                    window.open(url, name, spec)
                  }
                  break
                default:
                  if (message.referenceUrl) {
                    // 읽었다는 내용을 API로 날려줘야 한다.
                    const observedUrl = notifyGlobalSettingsStore.getApiUrl(
                      `/Message/ObservedMessageUrl?messageIndex=${message.id}`
                    )
                    await axios.get(observedUrl)
                    navigateToUrl(message.referenceUrl)
                  } else {
                    window.location.href = url
                  }
                  break
              }
              break
          }
        } else {
          alert(JSON.stringify(message))
        }
      },
      clearNotificationActivated: async (options: NotificationOptions) => {
        try {
          const url = notifyGlobalSettingsStore.getApiUrl('/api/Messages/PostClearMessage')

          //const response = await messageApi.apiMessagesPostClearMessagePost(
          const response = await axios.post(
            url,
            deepClone({
              acceptkey: privateObjectsStore.connection.acceptkey,
              delete: false,
              index: 0,
              ...options
            })
          )

          return response.data // 응답 데이터를 반환
        } catch (error) {
          console.error('Failed to clear notification:', error)
        }
      },
      refreshNotificationClient: async (options: NotificationOptions) => {
        //const url = notifyGlobalSettingsStore.getApiUrl('API/RefreshMessage')
        try {
          const url = notifyGlobalSettingsStore.getApiUrl('/api/messages/PostRefreshMessage')
          //const response = await messageApi.apiMessagesPostRefreshMessagePost(
          const response = await axios.post(
            url,
            deepClone({
              acceptkey: privateObjectsStore.connection.acceptkey,
              delete: false,
              ...options
            })
          )

          return response.data
        } catch (error) {
          console.error('Failed to refresh notification client:', error)
        }
      }
    },
    linker: {
      goToSettings() {
        //page 이동
        //const url = notifyGlobalSettingsStore.getApiUrl('Configuration/Personal')
        const url = notifyGlobalSettingsStore.getApiUrl('/ConfigurePersonalSettings')
        window.location.href = url // Vue3에서는 document.location 대신 window.location.href 사용
      },
      goToList() {
        //page 이동
        //const url = notifyGlobalSettingsStore.getApiUrl('Message/Items')
        const url = notifyGlobalSettingsStore.getApiUrl('/Messages')
        window.location.href = url
      },
      openWindowCenter(
        url: string,
        name: string = '',
        w: number = window.screen.width / 2,
        h: number = window.screen.height / 2,
        resizable: boolean = false
      ) {
        // URL 체크 및 파라미터 추가
        // 필수값 없으면 패스
        if (typeof url === 'string' && url.length > 0) {
          url += (url.includes('?') ? '&' : '?') + 'POPCLSID=Y'
        } else {
          return
        }

        // 스크린 사이즈 계산, 듀얼모니터 무시
        // 중앙 위치 계산
        const left = Math.max(0, (window.screen.width - w) / 2)
        const top = Math.max(0, (window.screen.height - h) / 2)

        // 윈도우 옵션 문자열 생성
        const windowFeatures = `toolbar=no, scrollbars=yes, width=${w}, height=${h}, top=${top}, left=${left}, resizable=${
          resizable ? 'yes' : 'no'
        }`

        // 새 창 열기
        const newWindow = window.open(url, name, windowFeatures)

        // 새 창에 포커스 주기
        newWindow?.focus()
      }
    },
    callback: {
      windowActivatedCallback(act: boolean) {
        privateObjectsStore.environment.windowActivated = act
      },
      registCallback(func: (system: string, exdata: any) => void) {
        if (typeof func === 'function') {
          globalObjectsStore.callbackArrayForNotified.push(func)
        }
      },
      unregistCallbackAll() {
        globalObjectsStore.callbackArrayForNotified = []
      }
    },
    notify(json: PushMessagePacketForApiEntity) {
      if (typeof json === 'object' && typeof json.system === 'string') {
        // 콜백 단일함수 처리
        //MyInfoControl .ascx 적용 방안 필요, window.fnNotificationActionReceived는 MyInfoControl.ascx에 포함됨 -  코드 수정 필요
        // if (typeof window.fnNotificationActionReceived === 'function') {
        //   try {
        //     window.fnNotificationActionReceived(json.system, json.exdata)
        //   } catch (e) {
        //     console.error('failed fnNotificationActionReceived on NotificationCenterClientManager.')
        //   }
        // }
        // 콜백 다중함수 처리   //SmartTalkControl.ascx 에
        if (Array.isArray(globalObjectsStore.callbackArrayForNotified)) {
          globalObjectsStore.callbackArrayForNotified.forEach((callback, i) => {
            try {
              callback(json.system as string, json.exdata)
            } catch (e) {
              console.error(`failed callbackArrayForNotified-${i} on NotificationCenterClientManager.`)
            }
          })
        }
      }
    }
  })

  const { appendMessageLog, convertToJsonFromSocket, sendSocketMessage, keepalive, playAlramSound, standby, random } =
    useNotifyHelpers(manager.api.connectNotificationServer)

  // 컴포넌트가 마운트되면 이벤트 리스너를 등록합니다.
  onMounted(() => {})

  // 여기서 manager 객체를 반환합니다.
  return manager
}
