<template>
  <VueFinalModal 
    class="confirm-modal" 
    content-class="confirm-modal-content" 
    overlay-transition="vfm-fade"
    content-transition="vfm-fade"
    :reserve-scroll-bar-gap="false">
    <div class="pop-container">
      <div class="backdrop"></div>
      <div class="dialog">
        <div class="pop-header">
          <div class="text-subtitle1">
            Smart Portal
          </div>
        </div>
        <div class="pop-body">
          <div class="pop-icon">
            <i class="material-icons">check_circle</i>
          </div>
          <div class="pop-message">
            {{ props.message }}
          </div>
        </div>
        <div class="pop-footer">
          <mik-button-ext 
            button-type="btn_type_a btn_color_04" 
            style="width: 95px" 
            :button-txt="$t('OK')"
            @click.prevent="handleOkClick" />
          <mik-button-ext 
            button-type="btn_type_a btn_color_03" 
            style="width: 95px" 
            :button-txt="$t('Cancel')"
            @click.prevent="handleCancelClick" />
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal'
import MikButtonExt from '../common/MikButtonExt.vue'

export interface Props {
  message?: string
  okCallback?: any | null
  cancelCallback?: any | null
  params?: any | any[]
  onclose: any | null
}

const props = withDefaults(defineProps<Props>(), {
  message: '',
  okCallback: null,
  cancelCallback: null,
  params: null,
  onclose: null
})

const handleOkClick = () => {
  if (props.okCallback && typeof props.okCallback === 'function') {
    if (props.params != null) {
      if (Array.isArray(props.params)) {
        props.okCallback(...props.params)
      } else {
        props.okCallback(props.params)
      }
    } else {
      props.okCallback()
    }
  }
  props.onclose()
}

const handleCancelClick = () => {
  if (props.cancelCallback && typeof props.cancelCallback === 'function') {
    if (props.params != null) {
      if (Array.isArray(props.params)) {
        props.cancelCallback(...props.params)
      } else {
        props.cancelCallback(props.params)
      }
    } else {
      props.cancelCallback()
    }
  }
  props.onclose()
}
</script>

<style>
.confirm-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.confirm-modal .pop-container {
  width: 480px;

  .pop-header {
    background-color: #272727;
    color: #fff;
    font-size: 1rem !important;
    padding: 14px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}

.confirm-modal .pop-body {
  height: 150px;
  width: 480px;
  text-align: center;
  position: relative;

  .pop-icon {
    height: 100px;
    width: 480px;
    position: relative;
    top: 20px;
    margin: 0;
    padding: 0;
  }

  .pop-message {
    font-size: 14px;
    font-weight: 700;
    position: relative;
    top: 35px;
  }
}

.confirm-modal .pop-footer {
  height: 70px;
  text-align: center;
  position: relative;
  top: 15px;
}

.confirm-modal-content {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 0.5rem;
}

.confirm-modal-content>*+* {
  margin: 0.5rem 0;
}

.confirm-modal-content h1 {
  font-size: 1.375rem;
}

.confirm-modal-content button {
  margin: 0.25rem 0 0 auto;
  padding: 0 8px;
  border-radius: 0.5rem;
}

.dark .confirm-modal-content {
  background: #000;
}

.confirm-modal-content .material-icons {
  font-size: 100px;
  color: #2966ed !important
}
</style>