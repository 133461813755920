<template>
  <li
    :key="item.id ?? ''"
    :class="`menu_active_item gwp_sub_item depth${depth} ${computedExpandClassName}`"
    style="display: list-item">
    <a
      href=""
      :class="`gwp_sub_anchor depth${depth} ${item.children && item.children.length > 0 ? 'gwp_sub_has' : ''}`"
      style="display: inline-block"
      @click.prevent="handleMenuClick(item.url ?? '', item.id ?? '')">
      {{ item.name }}
    </a>
    <template v-if="item.children && item.children.length > 0">
      <!-- menu expander -->
      <span v-if="depth > 2" class="cursor_pointer" @click.prevent="handleExpandClick"></span>
      <slide-down>
        <ul v-show="computedSlidedown" class="menu_active_sub_list gwp_sub_list" :class="` depth${depth + 1}`">
          <template v-for="child in item.children" :key="child.id">
            <nav-panel-item :depth="depth + 1" :item="child" @on-click-menu="handleChildMenuClick" />
          </template>
        </ul>
      </slide-down>
    </template>
  </li>
</template>

<script setup lang="ts">
import { MenuItemModel } from 'mik-common/src/api-autogen/globalmenu'
import SlideDown from '@gnb/components/animations/SlideDown.vue'
interface Props {
  item: MenuItemModel
  depth: number
}

const props = withDefaults(defineProps<Props>(), {
  depth: 0
})

const emit = defineEmits(['on-click-menu'])

const isSubItemExpand = ref(false)

const computedExpandClassName = computed(() => {
  if (props.depth <= 2 || (props.item.children && props.item.children.length === 0)) {
    return ''
  }

  if (isSubItemExpand.value === true) {
    return 'active'
  } else {
    return 'deactivate'
  }
})

const handleExpandClick = () => {
  isSubItemExpand.value = !isSubItemExpand.value
}
const handleMenuClick = (url: string, menuId: string) => {
  emit('on-click-menu', url, menuId)
}
const handleChildMenuClick = (url: string, menuId: string) => {
  emit('on-click-menu', url, menuId)
}
//2레벨은 true로 보여준 후 그 뒤 부터는 isSubItemExpand값을 따라감
const computedSlidedown = computed(() => {
  const renderSlidedown = ref(true)
  if (props.depth >= 3) {
    renderSlidedown.value = isSubItemExpand.value
  }

  return renderSlidedown.value
})
</script>
