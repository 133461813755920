<template>
  <div class="gwp_left_nav" :class="computedMenuStyle" :style="{ height: store.navContainerHeight }">
    <span class="gwp_left_nav" style=""></span>
    <!-- <span class="gwp_left_nav nav_con_tit">MENU</span> -->
    <span class="gwp_left_nav nav_con_tit_ho"></span>
    <ul>
      <!-- Tenant start -->
      <li class="gwp_left_nav nav_con" style="cursor: default; pointer-events: none">
        <img class="tenantImg" />
        <p class="line_break_revision">{{ $t('Miksystem') }}</p>
      </li>
      <!-- Tenant end -->
      <!-- LnbItem start -->
      <template v-for="item in items" :key="item.id">
        <left-nav-item
          :item="item"
          :is-name-display="isNameDisplay"
          @on-mouse-enter="handleItemMouseEnter"
          @on-mouse-leave="handleItemMouseLeave"
          @on-click-menu="handleItemMenuClick" />
      </template>
      <!-- LnbItem end -->
    </ul>
  </div>

  <common-dimmed-popup v-model:model-value="refIsShowPopup" :popup-url="refPopupUrl" :opt="popOption" />
</template>

<script setup lang="ts">
import { MenuItemModel } from 'mik-common/src/api-autogen/globalmenu'
import LeftNavItem from './lnb-items/LeftNav.vue'
import { useUserInfoStore } from '@gnb/store/modules/user-info/user-info'
import { useGlobalMenuStore } from '@gnb/store/modules/global-menu/global-menu'
import CommonDimmedPopup, { Option } from 'mik-common/src/components/popup/CommonDimmedPopup.vue'

interface Props {
  items: MenuItemModel[]
}

const props = withDefaults(defineProps<Props>(), {
  items: undefined
})

const store = useGlobalMenuStore()

const emit = defineEmits(['on-item-mouse-enter', 'on-item-mouse-leave', 'on-click-menu'])

const handleItemMouseEnter = (id: string) => {
  emit('on-item-mouse-enter', id)
}
const handleItemMouseLeave = (id: string) => {
  emit('on-item-mouse-leave', id)
}

const handleItemMenuClick = (url: string, menuId: string) => {
  emit('on-click-menu', url, menuId)
}
// UserInfo API를 호출하여 데이터를 가져옵니다
const isNameDisplay = ref(true)
const userStore = useUserInfoStore()
userStore.userInfoInit()

const computedMenuStyle = computed(() => {
  if (!userStore.gnbStyle) {
    return 'gwp_nav_type01'
  }

  const menuStyleId = JSON.parse(userStore.gnbStyle).menuStyleId
  let menuStyleIdClass = ''
  switch (menuStyleId) {
    case 'leftIconText':
      menuStyleIdClass = 'gwp_nav_type02'
      isNameDisplay.value = true
      break
    case 'leftIconOnly':
      menuStyleIdClass = 'gwp_nav_type01'
      isNameDisplay.value = false
      break
    default:
      menuStyleIdClass = 'gwp_nav_type01'
      isNameDisplay.value = false
      break
  }
  return menuStyleIdClass
})

const popOption = ref<Option>({
  title: 'SmartPortal',
  width: 2000,
  height: 1100
})

const refIsShowPopup = ref(false)
const refPopupUrl = ref('')

watch(store, () => {
  refIsShowPopup.value = store.isPopupShow
  refPopupUrl.value = store.popupUrl
})

watch(refIsShowPopup, () => {
  if (!refIsShowPopup.value) {
    store.locIsPopupShow('', false)
  }
})
</script>
