<template>
  <li v-if="item != undefined && item.name != undefined && item.link != undefined" class="gwp_path_item">
    <!-- <a :href="(item.link) ? item.link : 'javascript:;' " :style="(item.link) ? '' : 'cursor:default;' " class="gwp_path_anchor"> -->
    <a
      href="javascript:;"
      :style="item.url ? '' : 'cursor:default;'"
      class="gwp_path_anchor"
      @click.prevent="handleClick">
      <span class="ico_header ico_header_path_arrow"></span>
      <span class="path_txt">{{ item.name }}</span>
    </a>
  </li>
</template>

<script setup lang="ts">
import { NavigationBarModel } from 'mik-common/src/api-autogen/globalmenu'

interface Props {
  item: NavigationBarModel
}

const props = withDefaults(defineProps<Props>(), {})

const handleClick = () => {
  emits('on-menu-click', props.item)
}

const emits = defineEmits<{ (e: 'on-menu-click', value: NavigationBarModel): void }>()
</script>
