<template>
  <div class="menu_all_full_show menu_all_full_type02" style="display: block; left: 0px" :style="calcStyle">
    <!---->
    <div class="menu_all_full_wrap" style="background-color: #fff">
      <div class="menu_all_wrap">
        <div class="menu_all_title_wrap">
          <h1 class="menu_all_logo">
            <a href="#;">
              <!-- <img src="/PortalResources/images/alpha/common/logo.png" alt="" /> -->
            </a>
          </h1>
          <p class="menu_all_title">{{ $t('AlLMenu') }}</p>
        </div>
        <site-full-menu-search
          @on-find-menu-position="handleMoveToMenu"
          @on-keyword-strong="serachKeywordStrong"></site-full-menu-search>
        <site-full-menu-contents
          :items="items"
          @on-click-menu="handleDetailSubMenuItemClick"
          :move-item="moveMenu"
          :menuHistoryList="menuHistoryList"
          :keyword="searchKeyword"></site-full-menu-contents>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SiteFullMenuSearch from './SiteFullMenuSearch.vue'
import SiteFullMenuContents from './SiteFullMenuContents.vue'
import { MenuItemModel } from 'mik-common/src/api-autogen/globalmenu'
import { CSSProperties } from 'vue'
const emit = defineEmits(['on-click-menu'])

const gwpHeaderSystem = inject<Ref<HTMLElement>>('gwpHeaderSystem')
const layHeader = inject<Ref<HTMLElement>>('layHeader')

interface Props {
  items: MenuItemModel[]
}
const props = withDefaults(defineProps<Props>(), {
  items: () => []
})

/** sub nav click */
const handleDetailSubMenuItemClick = (url: string, menuId: string) => {
  emit('on-click-menu', url, menuId)
}

/** move to menu */
const moveMenu = ref(<MenuItemModel>{})

const menuHistoryList: string[] = reactive([])
/** menu scroll이동을 위해 변수 저장 */
const handleMoveToMenu = (menuId: string, menuHistoryArrList: string[]) => {
  moveMenu.value.id = menuId
  Object.assign(menuHistoryList, menuHistoryArrList)
}
const searchKeyword = ref('')
/** 키워드 색 표시*/
const serachKeywordStrong = (keyword: string) => {
  searchKeyword.value = keyword
}

const calcStyle = ref<CSSProperties>({})
/** width 보정 */
const headerWidthCheck = () => {
  if (gwpHeaderSystem?.value) {
    const headerWidth = gwpHeaderSystem?.value.clientWidth ?? 1280
    const headerHeight = gwpHeaderSystem?.value?.clientHeight ?? 80

    if (layHeader?.value) {
      calcStyle.value.width = `${headerWidth}px`

      // 세로 폭 조정
      const windowHeight = window.innerHeight

      calcStyle.value.height = `${windowHeight - headerHeight}px`
    }
  }
}

onMounted(() => {
  headerWidthCheck()
  window.addEventListener('resize', headerWidthCheck)
})
onUnmounted(() => {
  window.removeEventListener('resize', headerWidthCheck)
})
</script>
