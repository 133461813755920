<template>
  <div
    id="SmartPortalContentContainer"
    class="smart_content_container gwp_default"
    data-smartportal-id="SmartPortalContentContainer">
    <div class="sect_contentGnb" style="box-shadow: none">
      <div id="MSP_Gnb_App_Settings_Template_Container">
        <div class="pop_layer_appmenu" style="display: block">
          <div class="pop_body" style="height: calc(100% - 100px)">
            <div class="myapp_setting">
              <div class="myapp_left">
                <div class="myapp_header">
                  <span class="myapp_header_title">{{ $t('MyApp') }}</span>
                </div>
                <div class="myapp_selected">
                  <div class="gridster ready" style="padding-bottom: 50px; margin: 10px;">
                    <!-- <perfect-scrollbar style="height: 450px; overflow: auto"> -->
                    <!-- My App -->
                    <ul ref="sortableEl" class="app_list edit" style="height: 208px; width: 335px; position: relative">
                      <li
                        v-for="myApp in propMyAppList"
                        :id="'myApp_' + myApp.appId"
                        :key="myApp.appId ?? ''"
                        class="app_item gs-w">
                        <a href="javascript:void(null);" class="app_anchor">
                          <span class="app_icon" style="">
                            <span class="app_icon_wrap">
                              <img loading="lazy" class="app_icon_img sizeM" :src="handleImgSrc(myApp.appId ?? '')" />
                            </span>
                          </span>
                          <span class="app_name" style="margin-top: 1px; margin-left: 150x">{{
                            itemName(myApp.appNameJson ?? '')
                          }}</span>
                        </a>
                      </li>
                    </ul>
                    <!-- </perfect-scrollbar> -->
                  </div>
                </div>
              </div>
              <div class="myapp_right">
                <div class="myapp_header">
                  <span class="myapp_header_title">{{ $t('CommonApp') }}</span>
                </div>
                <!-- <perfect-scrollbar style="height: 430px; overflow: auto"> -->
                <div class="nano has-scrollbar" style="height: 410px">
                  <div class="nano-content">
                    <div class="app_category_wrap nano-content" tabindex="0">
                      <!-- Common App -->
                      <div v-for="group in groupList" :key="group.groupId ?? ''" class="app_category">
                        <div class="app_category_title">{{ groupName(group.groupNameJson ?? '') }}</div>
                        <ul class="app_list">
                          <li v-for="item in group.AppList" :key="item.appId ?? ''" class="app_item_right">
                            <input
                              :id="'chk_appitem_' + item.appId"
                              type="checkbox"
                              :checked="initGroupChecked(item.appId ?? '')"
                              @change="handleCommonAppClick(item, $event)" />
                            <label :for="'chk_appitem_' + item.appId">
                              <span class="checkbox"></span>
                              <span class="txt">
                                <a class="app_anchor">
                                  <span class="app_icon">
                                    <span class="app_icon_wrap">
                                      <img
                                        loading="lazy"
                                        class="app_icon_img sizeS"
                                        :src="handleImgSrc(item.appId ?? '')" />
                                    </span>
                                  </span>
                                  <span class="app_name common">{{ itemName(item.appNameJson ?? '') }}</span>
                                </a>
                              </span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <!-- <div class="nano-pane" style="display: block">
                      <div class="nano-slider" style="transform: translate(0px, 0px)"></div>
                    </div> -->
                  </div>
                </div>
                <!-- </perfect-scrollbar> -->
              </div>
            </div>
          </div>
          <div class="pop_footer">
            <div class="myapp_footer">
              <button class="btn_type_a btn_color_04" @click.prevent="handleSaveClick">
                <span class="btn_txt">{{ $t('Save') }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Sortable, { SortableEvent } from 'sortablejs'
import { MyAppModel } from 'mik-common/src/api-autogen/globalmenu'
import { MyAppGroupModel } from 'mik-common/src/api-autogen/globalmenu'
//import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
import { MyAppApi } from 'mik-common/src/api-autogen/globalmenu'
const api = new MyAppApi()
interface Props {
  myAppList?: MyAppModel[]
  imgSrcList?: MyAppModel[]
}

const props = withDefaults(defineProps<Props>(), {
  myAppList: () => [],
  imgSrcList: () => []
})

const sortableEl = ref<HTMLUListElement | null>(null)
// const langCode = ref(instance?.proxy?.$i18n.locale ?? 'ko');
const instance = getCurrentInstance()
const langCode = ref('ko')
/** host */
const host = ref('')

const propMyAppList = ref<MyAppModel[]>(props.myAppList)

/** group list */
const groupList = ref<MyAppGroupExt[]>([])
/** common app list */
const commonAppList = ref<MyAppModel[]>([])

/** get groups */
const groupPromise = api.v1MyappGroupListGet()
/** get common apps */
const commonPromise = api.v1MyappCommonListPost(undefined)
/** get icon image src (middle size) */
const propImgSrcList = ref<MyAppModel[]>(props.imgSrcList)

/** Get group list & common apps */
Promise.all([groupPromise, commonPromise])
  .then((response) => {
    /** group List */
    if (response[0].data.success && response[0].data.data) groupList.value = response[0].data.data
    /** Common App List */
    if (response[1].data.success && response[1].data.data) commonAppList.value = response[1].data.data
  })
  .catch((err) => {
    console.log(err)
  })
  .finally(() => {
    groupList.value.forEach((group) => {
      // 해당 그룹의 appId와 같은 appId를 가진 MyAppInfo를 필터링하여 가져옵니다.
      const groupApps = commonAppList.value.filter((app) => app.groupId === group.groupId)
      // 해당 그룹의 AppList에 필터링한 MyAppInfo를 추가합니다.
      if (!group.AppList) group.AppList = []
      group.AppList.push(...groupApps)
    })
  })

/** LifeCycleHook */
/** onMounted */
onMounted(() => {
  /** landCode */
  langCode.value = instance?.proxy?.$i18n.locale ?? 'ko'
  /** Sortable 적용 */
  if (sortableEl.value) {
    Sortable.create(sortableEl.value, {
      ghostClass: 'ghost-dragging',
      onEnd: (event: SortableEvent) => {
        if (event.oldIndex != undefined && event.newIndex != undefined) {
          const newItemList = [...propMyAppList.value]
          const item = newItemList.splice(event.oldIndex, 1)[0]
          newItemList.splice(event.newIndex, 0, item)
          propMyAppList.value = newItemList
        }
      }
    })
  }
})

/** Checkbox 초기값 */
const initGroupChecked = (appId: string): boolean => {
  return propMyAppList.value.some((item) => item.appId === appId)
}
/** jsonGroupName -> 이름 */
const groupName = (json: string): string => {
  const jsonName = JSON.parse(json) ?? {}
  return jsonName[langCode.value]
}
/** jsonName -> 이름 */
const itemName = (json: string): string => {
  const jsonName = JSON.parse(json) ?? {}
  return jsonName[langCode.value]
}
/** app img src interface */
// interface imgSrc {
//   appId: string
//   imgSrc: string
// }
const prefix = ref('app_')
const handleImgSrc = (appId: string) => {
  const imgSrcTemp = ref<MyAppModel[]>([])
  imgSrcTemp.value = propImgSrcList.value.filter(function (item) {
    return item.appId?.toLocaleLowerCase() === prefix.value + appId.toLocaleLowerCase()
  })

  return imgSrcTemp.value.length > 0 ? imgSrcTemp.value[0].imgSrc ?? '' : ''
}
/** 공통앱 Checkbox 클릭 */
const handleCommonAppClick = (app: MyAppModel, event: Event) => {
  //@ts-ignore
  const isChecked = event.target?.checked
  // false면 기존 데이터 삭제, true면 add
  if (isChecked) propMyAppList.value.push(app)
  else propMyAppList.value = propMyAppList.value.filter((item) => item.appId != app.appId)
}
/** 저장 클릭 */
const handleSaveClick = () => {
  propMyAppList.value.forEach((myApp: MyAppModel, idx: number) => {
    myApp.itemOrder = (idx + 1) * 10
  })
  api
    .v1MyappPost(propMyAppList.value)
    .then((response) => {
      if (response.data.success) {
        emits('on-hide-popup')
      }
    })
    .catch((err) => {
      console.log(`setMyAppList 실패`)
      console.log(err)
    })
}

const emits = defineEmits<{ (e: 'on-hide-popup'): void }>()

interface MyAppGroupExt extends MyAppGroupModel {
  AppList?: MyAppModel[]
}
</script>

<style scoped>
/* myApp 추가*/
/* .gwp_main_logo {
  background: url(/PortalResources/images/main/logo_SmartPortal.png) no-repeat center;
} */

.gwp_header_myapp .app_icon {
  word-wrap: break-all;
}

.ghost-dragging {
  background-color: #f66c51;
  opacity: 0.3;
  border-radius: 4px;
}

.sect_contentGnb {
  padding: 0px;
}

.pop_body {
  padding: 0px;
}
</style>
