<template>
  <div class="gwp_header_member_info brder-left">
    <div class="img_area">
      <div class="img_box">
        <img :src="computedProfileImageUrl" />
      </div>
    </div>
    <div class="info_area">
      <div class="info_name">{{ name }}</div>
      <div class="info_org">{{ company }}</div>
    </div>
    <button :title="$t('LogOut')" class="gwp_btn_logout" @click.prevent="handleLogoutClick">
      <span class="ico_header btn_img"></span>
    </button>
  </div>
</template>

<script setup lang="ts">
import { installOptions } from '@gnb/modules/menus/main'
interface Props {
  name: string
  company: string
  profileImageUrl: string
}

const props = withDefaults(defineProps<Props>(), {
  name: '',
  company: '',
  profileImageUrl: ''
})

const computedProfileImageUrl = computed(() => {
  const host = installOptions.baseUrl ?? ''
  return `${host}${props.profileImageUrl}`
})
const emit = defineEmits(['on-logout-click'])
const handleLogoutClick = () => {
  emit('on-logout-click')
}
</script>
