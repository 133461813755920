import { defineStore } from 'pinia'
import { NotifyGlobalObj, NotifyPrivateObj } from '@gnb/modules/notification-center/notifyPrivateObj'
import { useNotifyGlobalSettingsStore } from './notifyGlobalSettingsStore'

export const usePrivateObjectsStore = defineStore({
  id: 'privateObjects',
  state: (): NotifyPrivateObj => ({
    options: useNotifyGlobalSettingsStore(),
    connection: {
      identity: '',
      acceptkey: '',
      socket: null,
      retryCount: 0,
      keepaliveWorker: null,
      permanentConnection: true
    },
    environment: {
      windowActivated: true,
      mute: false,
      playone: false,
      inactivatedAlarms: []
    }
  }),
  getters: {
    //pinia 스토어 조회 함수
    getAcceptKey: (state) => {
      return state.connection.acceptkey
    }
  },
  actions: {
    //pinia 스토어 변경 함수
    updateAcceptKey(acceptkey: string) {
      this.connection.acceptkey = acceptkey
    }
  }
})

export const useGlobalObjectsStore = defineStore({
  id: 'globalSettings',
  state: (): NotifyGlobalObj => ({
    callbackArrayForNotified: []
  }),
  getters: {
    //pinia 스토어 조회 함수
    getCallbackArrayForNotified: (state) => {
      return state.callbackArrayForNotified
    }
  },
  actions: {
    //pinia 스토어 변경 함수
    updateCallbackArrayForNotified(callback: (system: string, exdata: any) => void) {
      this.callbackArrayForNotified.push(callback)
    }
  }
})
