<template>
  <div ref="root">
    <div id="srchMenu" class="gwp_header_srch brder-left">
      <input
        v-model="input_gnb_keyword"
        type="text"
        class="gnb_inp_srch"
        autocomplete="off"
        :placeholder="$t('SearchMenu')"
        @keypress.enter.prevent="handleSearchClick" />
      <button :title="$t('SearchMenu')" class="gwp_btn_srch" @click.prevent="handleSearchClick">
        <span class="ico_header ico_header_srch cursor_pointer"></span>
      </button>
    </div>

    <slide-down>
      <div v-if="isSearchVisible" id="pop_search_body" class="search_section" style="display: block">
        <div class="contents_body">
          <!-- [D] .search_section 에 .active 클래스 붙었을때 recent_list_layer 활성화됨 -->
          <div :class="isActiveSearchbar ? 'search_section_sub active' : 'search_section_sub'">
            <div class="search_header">
              <div class="search_title">{{ $t('SearchMenu') }}</div>
            </div>
            <div class="search_wrap">
              <input
                id="input_searchmenukeyword"
                v-model="input_keyword"
                type="text"
                class="srch_input"
                :placeholder="$t('SearchMenuDescript')"
                @focus="handleFocusRecentlySearchMenu"
                @blur="handleBlurRecentlySearchMenu"
                @keypress.enter.prevent="handleEnterSearchMenu"
                @keypress.esc.prevent="isSearchVisible = false" />
              <button class="btn_srch" @click.prevent="handleEnterSearchMenu">
                <span class="ico_search ico_search_srch"></span>
              </button>
            </div>
            <!-- 최근검색어 -->
            <div class="recent_list_layer">
              <ul id="ul_recentlysearchmenu" class="recent_list">
                <li v-for="item in recentlySearchMenuList" :key="item.keyword ?? ''" class="recent_item">
                  <a class="recent_anchor" @mousedown.prevent="handleRecentlySearchMenu(item.keyword ?? '')">{{
                    item.keyword
                  }}</a>
                  <button
                    class="delete_recent"
                    @mousedown.prevent="handleDeleteRecentlySearchMenu(item.sortOrder ?? -1)">
                    <span class="ico_search ico_search_delete_recent" style="display: block"></span>
                  </button>
                </li>
              </ul>
              <div class="recent_delete">
                <button class="delete_button" @mousedown.prevent="handleDeleteRecentlySearchMenu(0)">
                  <span id="span_deleterecentlysearchmenutitle" class="btn_txt">
                    {{ $t('DeleteAll') }}
                  </span>
                  <span class="ico_search ico_search_delete_recent"></span>
                </button>
              </div>
            </div>
            <!-- //최근검색어 -->
          </div>
          <div class="result_section">
            <!-- 메뉴검색결과 -->
            <div v-if="isResultVisible" class="menu_searchresult">
              <div v-if="searchMenuMasterList.totalCount && searchMenuMasterList.totalCount < 1">
                <div id="div_searchmenuresulttitle" class="searchresult_title">
                  {{ $t('MenuSearchResultEmpty') }}
                </div>
              </div>
              <div v-else class="menu_searchresult_header">
                <div id="div_searchmenuresulttitle" class="searchresult_title">
                  {{ $t('MenuSearchResult1') }}<b>{{ searchMenuMasterList.totalCount }}</b
                  >{{ $t('MenuSearchResult2') }}
                </div>
              </div>
              <div class="menu_searchresult_body" style="min-height: 72px">
                <ul id="ul_searchmenuresult" class="searchresult_list">
                  <li v-for="(item, index) in searchMenuMasterList.listData" :key="index" class="searchresult_item">
                    <div class="searchresult_menu">
                      <a
                        href="javascript:;"
                        class="menu_anchor"
                        @click.stop.prevent="handleItemMenuClick(item.url ?? '', item.menuId ?? '', $event)"
                        v-html="handleStrongKeyword(item.menuName ?? '', index)"></a>
                      <button
                        :class="item.bookmarkId ? 'btn_favorite active' : 'btn_favorite'"
                        @click.prevent="handleFavoriteClick(item)">
                        <span class="ico_usearch ico_usearch_favorite"></span>
                      </button>
                    </div>
                    <!-- History -->
                    <div class="searchresult_path">
                      <ul class="path_list">
                        <li v-for="historyItem in item.menuHistoryList" :key="historyItem.MenuId" class="path_item">
                          <a
                            href="javascript:;"
                            class="path_anchor"
                            :style="historyItem.Url ? '' : 'cursor:default;'"
                            @click.stop.prevent="handleItemMenuClick(item.url ?? '', item.menuId ?? '', $event)">
                            <span class="path_txt">{{ historyItem.MenuName }}</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
              <!-- Paginate -->
              <mik-pagination-ext
                :total-item-count="searchMenuMasterList.totalCount"
                :page-size="searchMenuMasterList.pageSize"
                :current-page="searchMenuMasterList.pageNumber"
                :page-step-level="5"
                @update:current-page="handleChangePage">
              </mik-pagination-ext>
              <!-- <div class="paginate" id="div_pagination">
              </div> -->
              <!-- //Paginate -->
            </div>
          </div>
        </div>
      </div>
    </slide-down>
  </div>
</template>

<script setup lang="ts">
import { installOptions } from '@gnb/modules/menus/main'
import SlideDown from '@gnb/components/animations/SlideDown.vue'
import { RecentlyMenuApi, BookmarkApi } from 'mik-common/src/api-autogen/globalmenu'
import { RecentlySearchMenuModel } from 'mik-common/src/api-autogen/globalmenu'
import { SearchMenuMasterModel, SearchMenuMasterListModel } from 'mik-common/src/api-autogen/globalmenu'
import MikPaginationExt from '../common/MikPaginationExt.vue'
// import { Cookies } from 'quasar'
import VueCookies from 'vue-cookies'
const apiBook = new BookmarkApi()
const apiRecently = new RecentlyMenuApi()
/** root Element */
const root = ref(null)
/** Search Input Element */
const input_gnb_keyword = ref<string>('')
/** Search Input Element */
const input_keyword = ref<string>('')
/** Slide visible */
const isSearchVisible = ref(false)
/** Popup Searchbar active */
const isActiveSearchbar = ref(false)
/** 최근 검색기록 */
const recentlySearchMenuList = ref<RecentlySearchMenuModel[]>([])
/** Result visible */
const isResultVisible = ref(false)
/** 메뉴 검색 결과 */
const searchMenuMasterList = ref<SearchMenuList>({
  totalCount: 0,
  pageNumber: 1,
  pageSize: 7,
  listData: [],
  listMenuID: []
})

/** LifeCycle Hook */
/** handle document click */
onMounted(() => {
  document.addEventListener('click', handleDocumentClick)
})
onBeforeUnmount(() => {
  document.removeEventListener('click', handleDocumentClick)
})
//결과값의 다국어를 위해서 쿠키에서 가져온다.
// @ts-ignore
const langCookie = VueCookies.get('GWP_LANGUAGE_CODE') ?? 'ko'
/** 메뉴 검색 */
const getSearchMenu = async (pageNum?: number) => {
  const val = input_keyword.value

  const bucketId = installOptions.bucketId ?? import.meta.env.V_MENU_BUCKETID
  // isResultVisible.value = false;
  const response = await apiRecently.v1RecentlyMenuSearchMenuMasterKeywordGet(
    val,
    langCookie,
    pageNum,
    searchMenuMasterList.value.pageSize,
    bucketId
  )
  if (response.data.success && response.data.data) {
    // 메뉴 결과창 show
    isResultVisible.value = true
    // 최근 검색기록 hide
    isActiveSearchbar.value = false
    searchMenuMasterList.value = response.data.data as SearchMenuList
    searchMenuMasterList.value.listData.forEach((menu) => {
      menu.menuHistoryList = JSON.parse(menu.menuHistory ?? '[]')
    })
  }
}
/** 최근 검색창 닫기 */
const closeRecentlySearchMenu = () => {
  isActiveSearchbar.value = false
}

/** Document click */
const handleDocumentClick = (e: Event) => {
  if (e == undefined || e == null) {
    return
  }
  /**
   * 해당 컴포넌트 외부에서 클릭이 되었을 경우, drop down을 닫기위한 기능 연결
   */
  // @ts-ignore
  if (!root.value.contains(e.target)) {
    isSearchVisible.value = false
    searchMenuMasterList.value = {
      totalCount: 0,
      pageNumber: 1,
      pageSize: 7,
      listData: [],
      listMenuID: []
    }
  }
}
/** 검색 버튼 클릭 or Enter키 입력 */
const handleSearchClick = () => {
  isSearchVisible.value = true
  setTimeout(() => {
    if (input_gnb_keyword.value) {
      input_keyword.value = input_gnb_keyword.value
      getSearchMenu()
      closeRecentlySearchMenu()
    }
  }, 10)
}
/** 메뉴 검색 */
const handleEnterSearchMenu = () => {
  getSearchMenu()
}
/** 팝업 검색창이 활성화시 최근 검색기록 조회 */
const handleFocusRecentlySearchMenu = () => {
  if (isActiveSearchbar.value) return
  else {
    isActiveSearchbar.value = true
    // 여기서 최근 검색기록 가져옴
    apiRecently.v1RecentlyMenuRecentlySearchMenuGet().then((response) => {
      if (response.data.success && response.data.data) {
        recentlySearchMenuList.value = response.data.data
      }
    })
  }
}
/** Blur 이벤트 */
const handleBlurRecentlySearchMenu = (e: Event) => {
  closeRecentlySearchMenu()
}
/** 최근 검색 메뉴 클릭 */
const handleRecentlySearchMenu = (keyword: string) => {
  input_keyword.value = keyword
  getSearchMenu()
}
/**
 * 최근 검색 삭제
 * @param sortOrder 0인 경우 전체 삭제
 */
const handleDeleteRecentlySearchMenu = (sortOrder: number) => {
  apiRecently
    .v1RecentlyMenuRecentlySearchMenuDelete(sortOrder)
    .then((response) => {
      if (response.data.success) {
        // 전체삭제
        if (sortOrder == 0) {
          recentlySearchMenuList.value = []
        } else {
          const index = recentlySearchMenuList.value.findIndex((item) => item.sortOrder === sortOrder)
          recentlySearchMenuList.value.splice(index, 1)
        }
        if (recentlySearchMenuList.value.length == 0) closeRecentlySearchMenu()
      }
    })
    .catch((err) => console.log(err))
}
interface menuRequest {
  name: string
  url: string
  menuId: string
}
/** 즐겨찾기 아이콘 클릭 */
const handleFavoriteClick = (menu: SearchMenu) => {
  // 이미 즐겨찾기를 한 경우 삭제처리
  if (menu.bookmarkId) {
    apiBook
      .v1BookmarkIdDelete(menu.bookmarkId)
      .then((response) => {
        //console.log('삭제', menu.bookmarkId)
        menu.bookmarkId = ''
      })
      .catch((err) => console.log(err))
  } else {
    const menuRequest = ref<menuRequest>({ name: menu.menuName ?? '', url: menu.url ?? '', menuId: menu.menuId ?? '' })

    apiBook
      .v1BookmarkPost(menuRequest.value)
      .then((response) => {
        //console.log('추가', response.data.data)
        menu.bookmarkId = response.data.data
      })
      .catch((err) => console.log(err))
  }
}
/** pagenation */
const handleChangePage = (pageNum: number) => {
  getSearchMenu(pageNum)
}
/** 키워드 강조 */
const handleStrongKeyword = (menuName: string, index: number) => {
  if (input_keyword.value.indexOf('[') > -1) {
    const target = ref(input_keyword.value)
    if (input_keyword.value.toLowerCase() != input_keyword.value.toUpperCase())
      target.value = input_keyword.value.toLowerCase() + input_keyword.value.toUpperCase()
    const keywordArray = [...new Set(target.value.replace('[', '').replace(']', '').replaceAll(',', '').split(''))]
    const menuNameArray = [...new Set(menuName.split(''))]
    menuNameArray.forEach((x, i) => {
      keywordArray.forEach((y) => {
        if (x == y) menuNameArray[i] = "<span class='emph'>" + x + '</span>'
      })
    })
    return menuNameArray.join('')
  } else {
    return menuName.replace(input_keyword.value, "<span class='emph'>" + input_keyword.value + '</span>')
  }
}

/** 메뉴 이동 */
const handleItemMenuClick = (url: string, menuId: string, event: MouseEvent) => {
  if (event.ctrlKey) {
    handleOpenTab(url.split('$')[3])
  } else {
    emits('on-click-menu', url, menuId)
  }

  isSearchVisible.value = false
  searchMenuMasterList.value = {
    totalCount: 0,
    pageNumber: 1,
    pageSize: 7,
    listData: [],
    listMenuID: []
  }
}

/** tab open url */
const handleOpenTab = (url: string) => {
  window.open(url, '_blank', '')
}

const emits = defineEmits<{ (e: 'on-click-menu', url: string, menuId: string): void }>()

interface SearchMenuList extends SearchMenuMasterListModel {
  listData: SearchMenu[]
}
interface SearchMenu extends SearchMenuMasterModel {
  menuHistoryList?: MenuHistory[]
}
interface MenuHistory {
  BucketId?: string
  MenuId?: string
  MenuName?: string
  Url?: string
}
</script>
