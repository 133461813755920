import type { App } from 'vue'
import MenuSystem from './MenuSystem.vue'
import LocalMenu from './LocalMenu.vue'
import NavigationBar from './NavigationBar.vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
// import { Cookies, Notify, Quasar, Dialog } from 'quasar' /** Gnb quasar */
import VueCookies from 'vue-cookies'
import { createI18n, I18n } from 'vue-i18n'
import { createVfm } from 'vue-final-modal'

import { useUserInfoStore } from '@gnb/store/modules/user-info/user-info'
import { usePersonalizationStore } from '@gnb/store/modules/user-info/personalization'
import { useMyTenantsStore } from '@gnb/store/modules/user-info/my-tenant'
import { useGlobalMenuStore } from '@gnb/store/modules/global-menu/global-menu'
import { useManagerStore } from '@gnb/store/modules/notification-center/notifyManagerStore'

import { BookmarkModel as BookmarkInfo } from 'mik-common/src/api-autogen/globalmenu'
import { MenuItemModel as GlobalMenuInfo } from 'mik-common/src/api-autogen/globalmenu'
import { LocalMenuModel as LocalMenuInfo } from 'mik-common/src/api-autogen/globalmenu'
import { NavigationBarModel as NavigationInfo } from 'mik-common/src/api-autogen/globalmenu'
import { UserInfoModel as UserProfile } from 'mik-common/src/api-autogen/globalmenu'

import { BASE_CONFIGURATION } from 'mik-common/src/api-autogen/globalmenu/base'
import { BASE_CONFIGURATION as BASE_CONFIGURATION_NOTI } from 'mik-common/src/api-autogen/notificationCenter/base'
import { BASE_CONFIGURATION as BASE_CONFIGURATION_AUTH } from 'mik-common/src/api-autogen/auth/base'

import DropDownOption from '@gnb/models/common/drop-down-option'
import ItemListInfo from '@gnb/models/common/item-list-info'
import pagingItemInfo from '@gnb/models/common/paging-item-info'
import UserInfo from '@gnb/models/user/user-info'
import useGwpGlobalMenu from '@gnb/utils/gnb'

import type { Router } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios'
// import 'mik-common/src/assets/styles/index.sass'
import '@quasar/extras/material-icons/material-icons.css'
// vue-final-modal css
import 'vue-final-modal/style.css'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

//import BASE_CONFIGURATION from 'mik-common/src/api-autogen/notificationcenter/base'

let installOptions = {} as installOption
export default {
  install: (app: App, options?: installOption) => {
    installOptions = options ?? {}

    if (installOptions.baseUrl) {
      BASE_CONFIGURATION.basePath = installOptions.baseUrl + import.meta.env.V_API_GLOBALMENU_PATH
      BASE_CONFIGURATION_NOTI.basePath = installOptions.baseUrl
      BASE_CONFIGURATION_AUTH.basePath = installOptions.baseUrl + import.meta.env.V_API_AUTH_PATH
    }
    axios.defaults.withCredentials = true // 전역설정

    /** Type-define 'ko' as the master schema for the resource */
    //type MultiLangSchema = typeof ko
    // @ts-ignore
    const langCode = VueCookies.get('GWP_LANGUAGE_CODE') ?? 'ko'

    let i18n: I18n
    if (installOptions.i18n) {
      i18n = installOptions.i18n
    } else {
      i18n = createI18n({
        legacy: false,
        globalInjection: true,
        locale: langCode,
        fallbackLocale: langCode
      })
    }

    app.use(i18n)
    app.use(pinia)

    /** vue-cookies */
    app.use(VueCookies)

    window.gwpGlobalMenu = useGwpGlobalMenu()
    //axios.defaults.baseURL = installOptions.baseUrl ?? import.meta.env.V_PORTAL_URL //전역설정 API Call baseUrl(host)

    // Gnb, Lnb
    app.component('MenuSystem', MenuSystem)
    // LocalMenu
    app.component('LocalMenu', LocalMenu)
    // NavigationBar
    app.component('NavigationBar', NavigationBar)

    /** vue-final-modal 등록 */
    const vfm = createVfm()
    app.use(vfm)

    /** Quasar 등록 */
    // @ts-ignore
    // app.use(Quasar, {
    //   plugins: {
    //     Notify,
    //     Dialog,
    //     Cookies
    //   } // import Quasar plugins and add here
    // })
    // 현재 다국어만 호출할 수 있도록 수정
    const loadLanguageAsync = (lang: string) => {
      return import(`../../locales/${lang}.json`).then((json) => {
        i18n.global.mergeLocaleMessage(lang, json.default)
      })
    }

    // router를 전달받은 경우 Router 사용
    if (installOptions.router) {
      app.use(installOptions.router)

      installOptions.router.beforeEach((to, from, next) => {
        loadLanguageAsync(langCode).then(() => next())
      })
    }
    // route가 없는 경우
    // 이 경우는 타 모듈이 vue가 아닐 경우이다.
    // mik 모듈에서 해당 루트로 진입한다면,
    // app.use(mikGnb, installOptions); 시 installOptions.route 변수를 넘겨줄수 있도록 수정할 것
    else {
      const router = createRouter({
        history: createWebHistory('/'),
        routes: []
      })

      router.beforeEach((to, from, next) => {
        loadLanguageAsync(langCode).then(() => next())
      })
    }
  }
}

export {
  MenuSystem,
  LocalMenu,
  NavigationBar,
  installOptions,
  useUserInfoStore,
  useMyTenantsStore,
  usePersonalizationStore,
  useGlobalMenuStore,
  useManagerStore,
  useGwpGlobalMenu
}

export type {
  BookmarkInfo,
  GlobalMenuInfo,
  LocalMenuInfo,
  NavigationInfo,
  UserProfile,
  DropDownOption,
  ItemListInfo,
  pagingItemInfo,
  UserInfo
}

interface installOption {
  baseUrl?: string
  router?: Router
  i18n?: I18n
  bucketId?: string
}
declare global {
  interface Window {
    gwpGlobalMenu: any
  }
}
