/* tslint:disable */
/* eslint-disable */
/**
 * Mik.SmartPortal.Auth.App
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import type { RequestArgs } from '../base';
// @ts-ignore
import type { FormsCookieLoginRequest } from '../models';
// @ts-ignore
import type { FormsCookieLoginResultApiResult } from '../models';
// @ts-ignore
import type { FormsCookieLogoutResultApiResult } from '../models';
/**
 * FormsCookieApiApi - axios parameter creator
 * @export
 */
export const FormsCookieApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FormsCheckGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/forms/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FormsCookieLoginRequest} [formsCookieLoginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FormsLoginPost: async (formsCookieLoginRequest?: FormsCookieLoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/forms/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(formsCookieLoginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FormsLogoutPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/forms/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FormsCookieApiApi - functional programming interface
 * @export
 */
export const FormsCookieApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FormsCookieApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FormsCheckGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormsCookieLoginResultApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FormsCheckGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FormsCookieLoginRequest} [formsCookieLoginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FormsLoginPost(formsCookieLoginRequest?: FormsCookieLoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormsCookieLoginResultApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FormsLoginPost(formsCookieLoginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1FormsLogoutPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormsCookieLogoutResultApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1FormsLogoutPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FormsCookieApiApi - factory interface
 * @export
 */
export const FormsCookieApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FormsCookieApiApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FormsCheckGet(options?: any): AxiosPromise<FormsCookieLoginResultApiResult> {
            return localVarFp.apiV1FormsCheckGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FormsCookieLoginRequest} [formsCookieLoginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FormsLoginPost(formsCookieLoginRequest?: FormsCookieLoginRequest, options?: any): AxiosPromise<FormsCookieLoginResultApiResult> {
            return localVarFp.apiV1FormsLoginPost(formsCookieLoginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1FormsLogoutPost(options?: any): AxiosPromise<FormsCookieLogoutResultApiResult> {
            return localVarFp.apiV1FormsLogoutPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FormsCookieApiApi - object-oriented interface
 * @export
 * @class FormsCookieApiApi
 * @extends {BaseAPI}
 */
export class FormsCookieApiApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsCookieApiApi
     */
    public apiV1FormsCheckGet(options?: AxiosRequestConfig) {
        return FormsCookieApiApiFp(this.configuration).apiV1FormsCheckGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FormsCookieLoginRequest} [formsCookieLoginRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsCookieApiApi
     */
    public apiV1FormsLoginPost(formsCookieLoginRequest?: FormsCookieLoginRequest, options?: AxiosRequestConfig) {
        return FormsCookieApiApiFp(this.configuration).apiV1FormsLoginPost(formsCookieLoginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsCookieApiApi
     */
    public apiV1FormsLogoutPost(options?: AxiosRequestConfig) {
        return FormsCookieApiApiFp(this.configuration).apiV1FormsLogoutPost(options).then((request) => request(this.axios, this.basePath));
    }
}
