<template>
  <div id="gwp_header_system" ref="gwpHeaderSystem" class="gwp_header_system">
    <div class="sep_header_wrap" style="box-sizing: border-box">
      <!-- Left -->
      <div class="gwp_header_left">
        <!-- Quasar에 영향 받지 않도록 style 추가 -->
        <h1 style="font-size: 100%; font-weight: normal; line-height: 50px; letter-spacing: normal">
          <a class="gwp_logo" style="cursor: pointer" @click="goToMain">
            <img class="logo" />
          </a>
        </h1>
        <div
          style="
            position: absolute;
            top: 5px;
            left: 350px;
            padding: 0px;
            margin: 0px;
            height: 20px;
            width: 100px;
          "></div>
      </div>
      <!-- Right -->
      <div v-show="!isSiteFullMenuVisible" class="gwp_header_right" @click="handleRightMenuClick">
        <user-search-item />
        <menu-search-item @on-click-menu="handleItemMenuClick" />
        <user-info-item
          :name="props.name"
          :company="props.company"
          :profile-image-url="props.profileImageUrl"
          @on-logout-click="handleLogoutClick" />
        <notification-item />
        <my-app-item />
        <!-- <skin-select-item /> -->
        <recent-menu-item @on-click-menu="handleItemMenuClick" />
        <favorite-item />
        <lang-select-item :lang="props.lang" @on-language-change="handleLanguageChange" />
        <site-map-item @on-mouse-enter="handleSiteMapMouseEnter" @click="handleSiteFullMenuOpen" />
      </div>
      <div v-if="isSiteFullMenuVisible" class="gwp_header_right">
        <div class="gwp_header_shrt_menu ma">
          <a>
            <div class="menu_box box1">
              <div class="menu_box_img" @click="handleSiteFullMenuClose">
                <span id="closeBtn" class="menu_all ico_header" style=""></span>
              </div>
            </div>
          </a>
        </div>
      </div>
      <site-full-menu
        v-if="isSiteFullMenuVisible"
        :items="props.items"
        @on-site-fullmenu-close-click="handleSiteFullMenuClose"
        @on-click-menu="handleDetailSubMenuItemClick"></site-full-menu>
    </div>
  </div>
  <ModalsContainer />
</template>

<script setup lang="ts">
import UserSearchItem from './gnb-items/UserSearch.vue'
import MenuSearchItem from './gnb-items/MenuSearch.vue'
import UserInfoItem from './gnb-items/UserInfo.vue'
import NotificationItem from './gnb-items/NotificationCenter.vue'
import MyAppItem from './gnb-items/MyApp.vue'
// import SkinSelectItem from './gnb-items/SkinSelect.vue'
import RecentMenuItem from './gnb-items/RecentMenu.vue'
import FavoriteItem from './gnb-items/Favorite.vue'
import LangSelectItem from './gnb-items/LangSelect.vue'
import SiteMapItem from './gnb-items/SiteMap.vue'
// import MyAppSetting from './gnb-items/MyAppSetting.vue'
import { useRouter } from 'vue-router'
import SiteFullMenu from './gnb-items/SiteFullMenu.vue'
import { MenuItemModel } from 'mik-common/src/api-autogen/globalmenu'
import { installOptions } from '@gnb/modules/menus/main'
import { ModalsContainer } from 'vue-final-modal'
import { goToMain } from '@gnb/utils/gnb'

interface Props {
  name: string
  company: string
  profileImageUrl: string
  lang: string
  items: MenuItemModel[]
}

const props = withDefaults(defineProps<Props>(), {
  name: '',
  company: '',
  profileImageUrl: '',
  lang: '',
  items: () => []
})

const isSiteFullMenuVisible = ref(false)

// const router = useRouter()

const gwpHeaderSystem = ref()

provide('gwpHeaderSystem', gwpHeaderSystem)

const emit = defineEmits([
  'on-site-map-mouse-enter',
  'on-site-map-mouse-leave',
  'on-language-change',
  'on-logout-click',
  'on-click-menu',
  'handleSiteFullMenuClose',
  'on-site-map-mouse-leave-notran',
  'on-right-menu-click'
])

const handleSiteMapMouseEnter = () => {
  emit('on-site-map-mouse-enter')
}

const handleSiteMapMouseLeave = () => {
  emit('on-site-map-mouse-leave')
}

const handdleSiteMapMouseLeaveNoTran = () => {
  emit('on-site-map-mouse-leave-notran')
}

const handleLanguageChange = (code: string) => {
  emit('on-language-change', code)
}
const handleLogoutClick = () => {
  emit('on-logout-click')
}
const handleItemMenuClick = (url: string, menuId: string) => {
  emit('on-click-menu', url, menuId)
}
const handleSiteFullMenuOpen = () => {
  isSiteFullMenuVisible.value = true
  handdleSiteMapMouseLeaveNoTran()
}
const handleSiteFullMenuClose = () => {
  isSiteFullMenuVisible.value = false
}
const handleDetailSubMenuItemClick = (url: string, menuId: string) => {
  // 전체 메뉴 - 상세 페이지 이동 시 전체 메뉴를 닫고 이동한다.
  handleSiteFullMenuClose()
  emit('on-click-menu', url, menuId)
}

const handleRightMenuClick = () => {
  emit('on-right-menu-click')
}

const computedFrontUrl = computed(() => {
  const host = installOptions.baseUrl ?? ''
  return `${host}/Front`
})
</script>
<style>
/* Gnb css */
@import url('@gnb/../public/css/gnb.css');
</style>
