import moment, { Moment } from 'moment'
import { useModal } from 'vue-final-modal'
import MikCustomConfirm from '../modules/menus/common/MikConfirm.vue'

export const Guid = {
  v4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  },
  empty() {
    return '00000000-0000-0000-0000-000000000000'
  }
}

/**
 * 날짜 및 시간 포맷
 */
type DateType = string | Moment | Date | null | undefined

export const formatDateTime = (dateTime: DateType) => {
  if (!dateTime) {
    return ''
  }
  return moment(dateTime).format('YYYY-MM-DD HH:mm:ss')
}

export const formatDateTimeShort = (dateTime: DateType) => {
  if (!dateTime) {
    return ''
  }
  return moment(dateTime).format('YY-MM-DD HH:mm')
}

export const formatDateTimeMilliseconds = (dateTime: DateType) => {
  if (!dateTime) {
    return ''
  }
  return moment(dateTime).format('YYYY-MM-DD HH:mm:ss.SSS')
}

export const formatDate = (dateTime: DateType) => {
  if (!dateTime) {
    return ''
  }
  return moment(dateTime).format('YYYY-MM-DD')
}

export const formatTime = (dateTime: DateType) => {
  if (!dateTime) {
    return ''
  }
  return moment(dateTime).format('HH:mm:ss')
}

export const formatTimeHHmm = (dateTime: DateType) => {
  if (!dateTime) {
    return ''
  }
  return moment(dateTime).format('HH:mm')
}

export const formatDateMonth = (dateTime: string | Moment | Date | undefined) => {
  if (!dateTime) {
    return ''
  }
  return moment(dateTime).format('YYYY-MM')
}

/**
 * 알림 팝업 호출
 */
export const mikCustomPopup = {
  alert: (message: string) => {
    
  },
  info: (message: string) => {
    
  },
  confirm: (message: string, okCallback: any | null, cancelCallback: any | null, params?: any | any[] | null | undefined) => {
    /** modal Events */
    const confirmModal = useModal({
      component: MikCustomConfirm,
      attrs: {
        message,
        okCallback,
        cancelCallback,
        params,
        onclose() {
          confirmModal.close()
        }
      }
    })
    
    /** Modal open */
    confirmModal.open()
  }
}