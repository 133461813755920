<template>
  <div class="gwp_header_shrt_menu ma" @mouseenter="handleMouseEnter">
    <a :title="$t('AlLMenu')" @click.prevent="">
      <div class="menu_box box1 brder-left brder-right">
        <div class="menu_box_img">
          <span class="menu_all ico_header"></span>
        </div>
        <div class="menu_txt">{{ $t('AlLMenu') }}</div>
      </div>
    </a>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits(['on-mouse-enter', 'on-mouse-leave'])
const handleMouseEnter = () => {
  emit('on-mouse-enter')
}

const handleMouseLeave = () => {
  emit('on-mouse-leave')
}
</script>
