// Pinia (stores/pageTitleStore.ts)
import { NotifyGlobalSettings } from '@gnb/modules/notification-center/notifyPrivateObj'
import { defineStore } from 'pinia'
import { installOptions } from '@gnb/modules/menus/main'

export const useNotifyGlobalSettingsStore = defineStore({
  id: 'notifyGlobalSettings',
  state: (): NotifyGlobalSettings => ({
    server: {
      isSecure: document.location.protocol.toLowerCase() === 'https:',
      host: installOptions.baseUrl ?? '', //portal 호스트 (http:// 미포함)
      path: import.meta.env.V_API_NOTIFICATIONCENTER_PATH || '/NotificationRest'
    },
    standby: {
      delaySeconds: 5
    },
    keepalive: 0,
    eventHandlers: {
      //객체 함수
      connected: null as (() => void) | null,
      disconnected: null as (() => void) | null,
      received: null as ((data: any | {}) => void) | null,
      pulled: null as ((data: any | {}) => void) | null,
      callbacks: []
    },
    socketUrl: ''
  }),
  getters: {
    getApiUrl: (state) => {
      return (feature: string) => {
        // const currentProtocol = `${state.server.isSecure ? 'https' : 'http'}://`
        let currentHostPathFeature = `${state.server.host || ''}${state.server.path || ''}${feature || ''}`

        while (currentHostPathFeature.includes('//')) {
          currentHostPathFeature = currentHostPathFeature.replace('//', '/')
        }
        return currentHostPathFeature.replace('http:/', 'http://').replace('https:/', 'https://')
      }
    },
    getKeepalive: (state) => {
      return state.keepalive
    },
    getDelaySeconds: (state) => {
      return state.standby.delaySeconds
    },
    getSocketUrl: (state) => {
      return state.socketUrl
    }
  },
  actions: {
    //pinia 스토어 변경 함수
    updateHost(host: string) {
      this.server.host = host
    },
    updatePath(path: string) {
      this.server.path = path
    },
    updateKeepalive(keepalive: number) {
      this.keepalive = keepalive
    },
    updateDelaySeconds(delaySeconds: number) {
      this.standby.delaySeconds = delaySeconds
    },
    updateSocketUrl(socketUrl: string) {
      this.socketUrl = socketUrl
    },
    setConnectedHandler(handler: () => void) {
      //if (typeof handler === 'function') {
      this.eventHandlers.connected = handler
      //}
    },
    setDisConnectedHandler(handler: () => void) {
      //if (typeof handler === 'function') {
      this.eventHandlers.disconnected = handler
      //}
    },
    setReceivedHandler(handler: (data: any) => void) {
      //if (typeof handler === 'function') {
      this.eventHandlers.received = handler
      //}
    },
    setPulledHandler(handler: (data: any) => void) {
      //if (typeof handler === 'function') {
      this.eventHandlers.pulled = handler
      //}
    }
    // 추가적인 행동을 여기에 정의합니다.
  }
})
