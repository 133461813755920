// @ts-nocheck
import { UserApi } from 'mik-common/src/api-autogen/globalmenu/api/user-api'

import MyTenantEntity from '@gnb/models/user/my-tenant-entity'
import { defineStore } from 'pinia'
const apiUser = new UserApi()
export const useMyTenantsStore = defineStore('myTenants', {
  persist: [
    {
      paths: ['myTenants'],
      storage: sessionStorage
    }
  ],
  state: () => {
    return {
      myTenants: [] as MyTenantEntity[]
    }
  },
  getters: {
    getMyTenants: (state) => {
      return state.myTenants
    }
  },
  actions: {
    myTenantsInit() {
      const response = apiUser.v1UserMyTenantsGet()

      response.then(({ data }) => {
        if (data.success && data.data) {
          this.myTenants = data.data
        }
      })
    }
  }
})
